import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

export const dataInjestValidation = () => {
    return yup.object().shape({
        subscriptionTopic: yup.string().required(ERRORS.required),
        kafkaTopic: yup.string().required(ERRORS.required),
        dataSchema: yup.mixed().test('', ERRORS.required, (val) => {
            if (Object.keys(val).length > 0) {
                return true;
            } else {
                return false;
            }
        }),
        targetCollection: yup.string().required(ERRORS.required),
        parserFunction: yup.string().required(ERRORS.required),
        powerConsumptionMonitoring: yup.bool().required(ERRORS.required),
        mqttDataCompression: yup.bool().required(ERRORS.required),
    });
};
