import React, { useState, useEffect } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import classes from '../../../../styles/CreateDevice.module.css';
import Typography from '../../../../components/Typography/Typography';
import { Input, InputFile, ToggleButton } from '../../../../components/Inputs/Input';
// import PhoneNumberComponent from "../../../../components/PhoneNumberComponent/PhoneNumberComponent";
import { AdminService } from '../../../../services/AdminService';
import { useLoader } from '../../../../hooks';
import { toast } from 'react-toastify';
import { createAdmin, updateAdmin } from '../../../../validations/Root/adminValidator';
import PhoneNumberComponent from '../../../../components/Inputs/PhoneNumberComponent';
import FileUpload from '../../../../components/Inputs/FileUpload/FileUpload';
import { UilMinusCircle, UilPlusCircle } from '@iconscout/react-unicons';
import Dropdown from '../../../../components/Inputs/Dropdown';
import { regulationsService } from '../../../../services/regulationsService';

const getInitialValues = (adminData) => {
    if (adminData?._id) {
        return {
            name: adminData.name,
            email: adminData.email,
            password: '',
            countryCode: adminData.countryCode,
            contact: adminData.contact,
            accessBlocked: adminData.accessBlocked,
            address: {
                invoiceInfo: adminData?.address?.invoiceInfo || [{
                    name: '',
                    email: ''
                }],
            },
            EICCode: adminData.EICCode,
            config: {
                menuItems: {
                    edge: adminData?.config?.menuItems?.edge,
                    bidding: adminData?.config?.menuItems?.bidding,
                    systemtool: adminData?.config?.menuItems?.systemtool,
                    eConomics: adminData?.config?.menuItems?.eConomics,
                    documents: adminData?.config?.menuItems?.documents,
                },
                biddingMarkets: adminData?.config?.biddingMarkets || {}
            }
        };
    } else {
        return {
            name: '',
            email: '',
            password: '',
            countryCode: '',
            contact: '',
            accessBlocked: false,
            address: {
                invoiceInfo: [{
                    name: '',
                    email: ''
                }],
            },
            EICCode: '',
            config: {
                menuItems: {
                    edge: {
                        enabled: false,
                        permissions: {
                            edit: false
                        }
                    },
                    bidding: {
                        enabled: false,
                    },
                    systemtool: {
                        enabled: false,
                    },
                    eConomics: {
                        enabled: false,
                    },
                    documents: {
                        enabled: false,
                    },
                },
                biddingMarkets: {}
            }
        };
    }
};

const menuItemOptions = ['edge', 'bidding', 'systemtool', 'eConomics', 'documents',];

const Index = ({ adminData, EICCodes }) => {
    const [startLoader, stopLoader] = useLoader();
    const [profileImage, setProfileImage] = useState([]);
    const [initialValues, setInitialValues] = useState(getInitialValues(adminData));
    const [regualtions, setRegulations] = useState([]);

    useEffect(() => {
        regulationsService.ReadAll(startLoader, handleRegulationSuccess, handleError, stopLoader);
    }, []);

    const handleRegulationSuccess = ({ data }) => {
        const regulationsCur = [];
        const biddingMarkets = {};
        data?.data?.forEach((reg) => {
            regulationsCur.push(reg.name);
            biddingMarkets[reg.name] = {
                enabled: false,
                permissions: {
                    edit: false
                }
            };
        });
        setRegulations(regulationsCur);
        setInitialValues((prev) => ({
            ...prev,
            config: {
                ...biddingMarkets,
                ...prev.config,
            },
        }));
    };

    const handleSubmit = (values, resetForm) => {
        values = {
            ...values,
            address: {
                invoiceInfo: values?.address?.invoiceInfo.length > 0 ?
                    values?.address?.invoiceInfo.map((invoiceInfo) => {
                        return {
                            name: invoiceInfo.name ? invoiceInfo.name : undefined,
                            email: invoiceInfo.email ? invoiceInfo.email : undefined,
                        }
                    }) :
                    undefined
            }
        };
        const formData = new FormData();
        const isProfileImageValid = /(jpg|png|peg|svg)$/.test(profileImage?.name?.slice(-4));
        if (profileImage.length && !isProfileImageValid) {
            toast.error('Please select a valid image format');
            return;
        }
        formData.append('profileImage', profileImage);
        Object.keys(values).forEach((key) => {
            let value = values[key]
            if (key === 'address' || key === 'config') value = JSON.stringify(values[key]);
            formData.append(key, value);
        });

        if (adminData?._id) {
            AdminService.Update(adminData._id, formData, startLoader, handleUpdateSuccess, handleError, stopLoader);
        } else {
            AdminService.Create(formData, startLoader, ({ data }) => handleCreateSuccess(data, resetForm), handleError, stopLoader);
        }
    };

    const handleUpdateSuccess = () => {
        toast.success('Admin updated!');
    };

    const handleCreateSuccess = (data, resetForm) => {
        resetForm();
        toast.success('Admin created!');
    };

    const handleUploadClick = (e) => {
        const file = e.target.files[0];
        setProfileImage(file);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div className={classes.FormContainer}>
            <Typography content={`${adminData?._id ? 'Edit' : 'Create'} Admin`} />
            <div className={classes.FormContent}>
                <Formik initialValues={initialValues} onSubmit={(val, { resetForm }) => handleSubmit(val, resetForm)} validationSchema={adminData?._id ? updateAdmin(regualtions) : createAdmin(regualtions)}>
                    {({ errors, touched, values, isValidating, ...props }) => {
                        return (
                            <Form>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="name">
                                            Name <span className="required">*</span>
                                        </label>
                                        <Input name="name" id="name" />
                                    </div>
                                    <div className={classes.FieldControl}>
                                        <label for="Email">
                                            Email <span className="required">*</span>
                                        </label>
                                        <Input name="email" id="Email" />
                                    </div>
                                </div>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="password">
                                            Password <span className="required">*</span>
                                        </label>
                                        <Input name="password" id="password" type="password" />
                                    </div>
                                    <div className={classes.FieldControl}>
                                        <label for="contact">
                                            Contact <span className="required">*</span>
                                        </label>
                                        <div>
                                            <PhoneNumberComponent
                                                inputName="contact"
                                                countryCodeName="countryCode"
                                                inputValues={values}
                                                defaultCountryCode={{ value: values.countryCode }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <FieldArray name="address.invoiceInfo">
                                    {({ push, remove, form }) => {
                                        const invoiceInfo = form.values.address?.invoiceInfo;
                                        return (
                                            <div>
                                                <div>
                                                    <div className={classes.InputContainer}>
                                                        <div className={classes.FieldControl2}>
                                                            <div className={classes.Title}>Invoice</div>
                                                            {invoiceInfo.map((data, index) => (
                                                                <div className={classes.InputContainer}>
                                                                    <div className={classes.FieldControl}>
                                                                        <label for="name">Name</label>
                                                                        <Input name={`address.invoiceInfo[${index}].name`} id="name" type="text" />
                                                                    </div>
                                                                    <div className={classes.FieldControl}>
                                                                        <label for="email">Email </label>
                                                                        <Input
                                                                            name={`address.invoiceInfo[${index}].email`}
                                                                            id="email" type="text" />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={classes.addInputActionWrapper}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row-reverse',
                                                        gap: '0.5vw',
                                                        paddingTop: '1%',
                                                    }}
                                                >
                                                    <span
                                                        onClick={() => {
                                                            push('');
                                                        }}
                                                    >
                                                        <UilPlusCircle
                                                            size={'1.5vw'}
                                                            style={{
                                                                color: 'var(--color-primary)',
                                                                cursor: 'pointer',
                                                                zIndex: '1',
                                                            }}
                                                        />
                                                    </span>
                                                    {invoiceInfo.length > 1 && (
                                                        <span onClick={() => remove(invoiceInfo.length - 1)}>
                                                            <UilMinusCircle
                                                                size={'1.5vw'}
                                                                style={{
                                                                    color: 'var(--color-primary)',
                                                                    cursor: 'pointer',
                                                                    zIndex: '1',
                                                                }}
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    }}
                                </FieldArray>

                                <div className={classes.Title}>Menu Items</div>
                                <div className={classes.InputContainer}>
                                    {menuItemOptions.map((menu, key) => (
                                        <div className={classes.FieldControl} key={key}>
                                            <ToggleButton values={values} label={menu} name={`config.menuItems.${menu}.enabled`} />
                                        </div>
                                    ))}
                                </div>

                                <div>
                                    <div className={classes.Title}>Edge Sudo Permission</div>
                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl}>
                                            <ToggleButton values={values} name={`config.menuItems.edge.permissions.edit`} />
                                        </div>
                                    </div>
                                </div>

                                <div className={classes.Title}>Bidding Markets</div>
                                <div className={classes.InputContainer}>
                                    {regualtions.map((regulation, key) => (
                                        <div key={key} >
                                            <div className={classes.FieldControl} >
                                                <ToggleButton values={values} label={regulation} name={`config.biddingMarkets.${regulation}.enabled`} />
                                            </div>

                                        </div>
                                    ))}
                                </div>

                                <div className={classes.Title}>Bid Edit Permission</div>
                                <div className={classes.InputContainer}>
                                    {regualtions.map((regulation, key) => (
                                        <div key={key} >
                                            <div className={classes.FieldControl}>
                                                <ToggleButton values={values} label={regulation} name={`config.biddingMarkets.${regulation}.permissions.edit`} />
                                            </div>
                                        </div>
                                    ))}
                                </div>



                                <div style={{ marginBottom: '1vw' }}>
                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl2}>
                                            <div className={classes.Title}>aFRR Config</div>
                                            <div className={classes.InputContainer}>
                                                <div className={classes.FieldControl}>
                                                    <label for="EICCode">EIC Code</label>
                                                    <Dropdown
                                                        name="EICCode"
                                                        options={EICCodes}
                                                        defaultValue={{ value: values.EICCode }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="profileImage">Profile Image</label>
                                        <FileUpload
                                            name="profileImage"
                                            id="profileImage"
                                            accept="image/jpg, image/jpeg, image/png, image/svg+xml"
                                            onChange={handleUploadClick}
                                        />
                                    </div>
                                    <div className={classes.FieldControl}></div>
                                </div>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <ToggleButton values={values} label={'Block Access'} name={`accessBlocked`} />
                                    </div>
                                </div>
                                <div className={classes.ButtonContainer}>
                                    <div>
                                        <button type="submit" className="btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default Index;
