// Internal module imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    getApprovedCapacity: '/root/instance/afrr-approved-capacity',
};

const GetInstanceApprovedCapacity = (start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getApprovedCapacity}`).then(callback).catch(error).finally(next);
};

export const RootInstanceService = {
    GetInstanceApprovedCapacity,
};
