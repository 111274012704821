// Standard library imports
import React, { useState, useEffect } from 'react';
// External library imports
import { toast } from 'react-toastify';
import { UilTrash } from '@iconscout/react-unicons';
// Internal module imports
import Table from '../../../components/Table/Table';
import { useLoader } from '../../../hooks';
import { EdgeService } from '../../../services/EdgeService';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
// CSS Imports
import classes from '../../../styles/Bids.module.css';

const preMarketOptions = {
    'FCR_V1': 'FCR Schedule Test (V1)',
    'FCR_V2': 'FCR Schedule Test (V2)',
    'FRR_V1': 'aFRR Signal Test',
    'FRR_V2': 'aFRR Schedule Test'
};

function TestLogs({ market, setScheduleModal }) {
    const [tableData, setTableData] = useState([]);
    const [startLoader, stopLoader] = useLoader();
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        id: '',
    });

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        const params = {
            market: 'all',
        };
        EdgeService.GetPredefinedTest(params, startLoader, handleGetPredefinedTestSuccess, handleError, stopLoader);
    };

    const handleGetPredefinedTestSuccess = ({ data }) => {
        const processedData = data.data.map((item, index) => ({
            's.no': index + 1,
            name: item.name,
            testType: preMarketOptions[item.market],
            action: (
                <div key={index}>
                    <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setDeleteModal({ status: true, id: item._id })} />
                </div>
            ),
        }));

        setTableData(processedData);
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };
    const handleDelete = () => {
        if (deleteModal.id) {
            EdgeService.DeletePredefinedTest(deleteModal.id, startLoader, handleDeleteSuccess, handleError, stopLoader);
        }
    };

    const handleDeleteSuccess = () => {
        toast.success('Profle deleted successfully');
        getData();
    };
    return (
        <div>
            <DeleteModal deletefunction={handleDelete} opendeleteModal={deleteModal.status} setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })} />

            <div style={{ minWidth: '45vw', overflow: 'auto', maxHeight: '70vh' }}>
                <div>
                    <div>
                        <Table head={['S.no', 'Name', 'Test Type',
                            'Action']} keys={['s.no', 'name', 'testType',
                                'action']} data={tableData} />
                    </div>
                    <div className={classes.ButtonContainer}>
                        <div>
                            <button type="submit" className="btn-secondary" onClick={() => setScheduleModal({ status: false, data: '' })}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TestLogs;
