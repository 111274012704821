// Standard library imports
import React, { useState, useEffect } from 'react';

// External module imports
import { toast } from 'react-toastify';

// Internal module imports
import classes from '../../../../styles/AllDevices.module.css';
import Typography from '../../../../components/Typography/Typography';
import Table from '../../../../components/Table/Table';
import { useLoader } from '../../../../hooks';
import { RootSummaryService } from '../../../../services/RootSummaryService';
import { momentTimeFormater } from '../../../../utils/timeHelper';
import activationSummaryClasses from './ActivationProfiler.module.css';
import { UilBolt } from '@iconscout/react-unicons';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import { AFRR_MARKET_TYPES } from '../../../../constants';

const LIMIT = 15;
const Summary = ({ date, edgeId, onSubmit, updateQueryParams, setSelectedIndex, setStartTime, setEndTime, market }) => {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [tableHeaderAndKeys, setTableHeaderAndKeys] = useState({
        header: [],
        keys: [],
    });

    useEffect(() => {
        getData();
    }, [skip, onSubmit, edgeId]);

    useEffect(() => {
        if (market) {
            if (AFRR_MARKET_TYPES.includes(market)) {
                setTableHeaderAndKeys({
                    header: ['S.No', 'Profile Name', 'Start Time', 'Duration', 'Status', 'Max Setpoint', 'Max Power', 'Target Power', 'Seconds to Reach Non Zero Power', 'Action'],
                    keys: ['s.no', 'profileName', 'startTime', 'duration', 'status', 'maxSetPoint', 'maxPower', 'targetPower', 'secToReachNonZeroPower', 'action'],
                });
            } else {
                setTableHeaderAndKeys({
                    header: ['S.No', 'Profile Name', 'Start Time', 'Duration', 'Status', 'Max Frequency', 'Max Power', 'Target Power', 'Seconds to Reach Non Zero Power', 'Action'],
                    keys: ['s.no', 'profileName', 'startTime', 'duration', 'status', 'maxFrequency', 'maxPower', 'targetPower', 'secToReachNonZeroPower', 'action'],
                });
            }
        }
    }, [market]);

    const getData = () => {
        RootSummaryService.GetActivationSummaryByProfile(
            {
                edgeId,
                date: date,
                limit: LIMIT,
                skip: skip,
            },
            startLoader,
            handleSuccess,
            handleError,
            stopLoader
        );
    };

    const handleSuccess = ({ data }) => {
        const proccessedData = data?.data?.data?.map((item, index) => ({
            's.no': index + 1,
            profileName: item.profileName,
            startTime: item.startTime ? momentTimeFormater(item.startTime).format('YYYY-MM-DD HH:mm') : '--',
            duration: item.duration || '--',
            status: item.status,
            maxFrequency: item?.decreased ? item.minFrequency : item.maxFrequency,
            maxPower: (
                <div className={activationSummaryClasses.AmountContainer}>
                    {item.status === 'Under Delivered' || item.status === 'Over Delivered' ? (
                        <span className={activationSummaryClasses.BoldFont + ' ' + activationSummaryClasses.RedColor}>{item.maxPower}</span>
                    ) : item.status === 'Activation Successful' ? (
                        <span className={activationSummaryClasses.BoldFont + ' ' + activationSummaryClasses.GreenColor}>{item.maxPower}</span>
                    ) : (
                        <span>{item.maxPower || item.maxPower == 0 ? item.maxPower : '--'}</span>
                    )}
                </div>
            ),
            maxSetPoint: item?.maxSetPoint || '--',
            targetPower: item.targetPower || '--',
            secToReachNonZeroPower: item.secToReachNonZeroPower || '--',
            action: (
                <div>
                    <CustomTooltip content={'Power Tab'}>
                        <UilBolt
                            size={'1.2vw'}
                            style={{ color: 'var(--color-primary)' }}
                            onClick={() => {
                                updateQueryParams('startTime', momentTimeFormater(item.startTime).format('HH:mm:ss'));
                                updateQueryParams('endTime', momentTimeFormater(item.endTime).format('HH:mm:ss'));
                                updateQueryParams('subTab', 0);
                                setStartTime(momentTimeFormater(item.startTime).format('HH:mm:ss'));
                                setEndTime(momentTimeFormater(item.endTime).format('HH:mm:ss'));
                                setSelectedIndex(0);
                            }}
                        />
                    </CustomTooltip>
                </div>
            ),
        }));
        setData(proccessedData);
        setTotalCount(data.data.count);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    return (
        <div>
            <div className={classes.AllDevices}>
                <div className={classes.Header}>
                    <div>
                        <Typography content="Activation Summary" />
                        <div className={classes.TableCount}>
                            Total Count :
                            <span>
                                <Typography size="14" content={totalCount || 0} />
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <Table
                            head={tableHeaderAndKeys.header}
                            keys={tableHeaderAndKeys.keys}
                            data={data}
                            page={page}
                            Pagination={true}
                            limit={LIMIT}
                            handlePageChange={handlePageChange}
                            totalCount={totalCount}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Summary;
