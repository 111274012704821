// Standard library imports
import React, { useState, useEffect } from 'react';
// Internal module imports
import TabsComponent from '../../../../components/Tabs/Tabs';
import EicSignalLogs from './EicSignalLogs';

const EICSignalIndex = ({ queryParamsData, updateQueryParams, eicCodeOptions }) => {
    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.nestedTab ? queryParamsData.nestedTab : 0);

    let tabs = [
        {
            name: 'DK1 Signal Logs',
            component: (
                <>
                    <EicSignalLogs queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} eicCodeOptions={eicCodeOptions} area={'DK1'} />
                </>
            ),
            tabId: 1,
        },
        {
            name: 'DK2 Signal Logs',
            component: (
                <>
                    <EicSignalLogs queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} eicCodeOptions={eicCodeOptions} area={'DK2'} />
                </>
            ),
            tabId: 1,
        },
    ];

    return (
        <div className=" ventilation">
            <TabsComponent
                selectedIndex={selectedIndex}
                tabs={tabs}
                onChangeTab={(index) => {
                    setSelectedIndex(index);
                    updateQueryParams('nestedTab', index);
                }}
            />
        </div>
    );
};

export default EICSignalIndex;
