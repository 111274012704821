import React, { useEffect, useState } from 'react';
// External library imports
import { toast } from 'react-toastify';
import moment from 'moment';
import _ from 'lodash';
import classes from '../../../../styles/Bids.module.css';
import { useLoader } from '../../../../hooks';
import Table from '../../../../components/Table/Table';
import Typography from '../../../../components/Typography/Typography';
import mainBiddingClasses from '../index.module.css';
import DateRangePicker from '../../../../components/Inputs/DateRangePicker/DateRangePicker';
import { momentTimeFormater } from '../../../../utils/dateHelper';
import { RootInstanceService } from '../../../../services/RootInstanceService';
import Checkbox from '../../../../components/Inputs/Checkbox/Checkbox';
import { BiddingService } from '../../../../services/BiddingService';
import { findDiffInDays } from '../../../../utils/timeHelper';

export const PostAvailabilityPlan = ({ setOpen }) => {
    const [startLoader, stopLoader] = useLoader();
    const [dateRange, setDateRange] = useState({
        startDate: new Date(moment().add(1, 'days')),
        endDate: new Date(moment().add(10, 'days')),
    });

    const [instances, setInstances] = useState([]);

    useEffect(() => {
        getInstances();
    }, []);

    const getInstances = () => {
        RootInstanceService.GetInstanceApprovedCapacity(startLoader, handleSuccess, handleError, stopLoader);
    };

    const getCheckedInstances = (e, ins) => {
        setInstances((prev) =>
            prev.map((item) =>
                item.id === ins.id
                    ? {
                          ...item,
                          checked: e,
                          select: (
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <Checkbox onChange={(e) => getCheckedInstances(e, { ...item, checked: e })} checked={e} />
                              </div>
                          ),
                      }
                    : item
            )
        );
    };

    const handleSuccess = ({ data }) => {
        const temp = [];
        data?.data?.map((el) => {
            temp.push({
                select: (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Checkbox onChange={(e) => getCheckedInstances(e, el)} checked={el.checked} />
                    </div>
                ),
                ...el,
            });
        });
        setInstances(temp || []);
    };

    const handleSubmit = () => {
        const diff = findDiffInDays(dateRange.startDate, dateRange.endDate);
        if (diff === 9) {
            const currentCETTime = momentTimeFormater(momentTimeFormater().format('YYYY-MM-DD HH:mm'));
            const startDate = momentTimeFormater(
                moment(dateRange.startDate).subtract(1, 'days').set({ hour: 14, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DD HH:mm')
            );
            if (!currentCETTime.isAfter(startDate)) {
                const temp = [];
                instances.map((ins) => {
                    if (ins.checked) {
                        delete ins.select;
                        temp.push(ins);
                    }
                });
                const payload = {
                    instanceData: temp,
                    startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
                    endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
                };
                BiddingService.PostCapacity(payload, startLoader, handlePostSuccess, handleError, stopLoader);
            } else {
                toast.error('Can not post availability plan for tomorrow after 2PM (CET)');
            }
        } else {
            toast.error('Period should be 10 days');
        }
    };

    const handlePostSuccess = () => {
        toast.success('Capacity send to energinet successfully');
        setOpen({ status: false });
    };

    const handleError = (err) => {
        if (err && !!err?.response?.data?.message) {
            toast.error(err.response.data.message);
        }
    };

    return (
        <div>
            <div className={mainBiddingClasses.MainModalContainer}>
                <div className={mainBiddingClasses.DateContainer}>
                    <Typography content={`Post Availability Plan`} />
                    <div style={{ marginBottom: '0.5vh' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className={classes.DateLabels} style={{ marginBottom: '0.2vw' }}>
                                Start Date
                            </div>
                            <div className={classes.DateLabels} style={{ marginRight: '2.604vw', marginBottom: '0.2vw' }}>
                                End Date
                            </div>
                        </div>
                        <DateRangePicker
                            startDate={dateRange.startDate}
                            endDate={dateRange.endDate}
                            onChange={(date) =>
                                setDateRange({
                                    startDate: new Date(date.startDate),
                                    endDate: new Date(date.endDate),
                                })
                            }
                            isDisableDate={true}
                        />
                    </div>
                </div>

                <div className={classes.Bids}>
                    <Table
                        head={['Select', 'Customer Name', 'Instance', 'Market', 'Approved Capacity']}
                        keys={['select', 'customer', 'instance', 'market', 'approvedCapacity']}
                        data={instances}
                    />
                </div>

                <div>
                    <div className={classes.ButtonContainer}>
                        <div onClick={() => setOpen({ status: false })}>
                            <button type="button" className="btn-secondary">
                                Cancel
                            </button>
                        </div>
                        <div onClick={handleSubmit}>
                            <button type="submit" className="btn-primary">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
