// Standard library imports
import React, { useEffect, useState } from 'react';
// External library imports
import moment from 'moment';
import { toast } from 'react-toastify';
import { useLocation, useHistory } from 'react-router-dom';
import { flatten } from 'flat';
import { Menu, MenuItem } from '@szhsin/react-menu';

// Internal module imports
import ChartComponent from '../../../components/ApexCharts/Chart';
import classes from '../FCRFrequency/Frequency.module.css';
import SignalViewerClasses from '../index.module.css';
import { useLoader } from '../../../hooks';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import { CamelCaseToTextCase, Capitalize } from '../../../utils/stringHelper';
import downloadIcon from '../../../assets/download.svg';
import { signalsService } from '../../../services/signalsService';
import { timeOnlyWithms } from '../../../utils/dateHelper';
import { usToEuCurrencyFormat } from '../../../utils/currencyHelper';
import Typography from '../../../components/Typography/Typography';
import { DropdownComponent, ToggleButtonWithState } from '../../../components/Inputs/Input';
import DatePicker from '../../../components/Inputs/DatePicker/DatePicker';
import CustomTimePicker from '../../../components/Inputs/TimePicker/TimePicker';
import { truncateNumber } from '../../../utils/numberHelper';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import { CustomerService } from '../../../services/CustomerService';
import SignalReportTable from '../ModalComponent/SignalReportTable';
import { UilExclamationOctagon, UilImport } from '@iconscout/react-unicons';
import SignalError from '../SignalError/SignalError';

const SignalViewer = ({ queryParamsData, updateQueryParams }) => {
    const curCetDateTime = moment().tz('Europe/Berlin');
    const [startLoader, stopLoader] = useLoader();
    const [graphData, setGraphData] = useState([]);
    const [powerData, setPowerData] = useState([]);
    const [setPointData, setSetPointData] = useState([]);
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : new Date(curCetDateTime.format()));
    const [signalType, setSignalType] = useState(queryParamsData.signalType ? JSON.parse(queryParamsData.signalType) : { label: 'Frequency', value: 'Frequency' });
    const [signalTypeOption, setSignalTypeOption] = useState([
        { label: 'Frequency', value: 'Frequency' },
        { label: 'Power Cost', value: 'Power Cost' },
        { label: 'Gas Price', value: 'Gas Price' },
        { label: 'Carbon', value: 'Carbon' },
        { label: 'Average Settlement', value: 'Average Settlement' },
        { label: 'Solar Forecast', value: 'Solar Forecast' },
    ]);
    const [signal, setSignal] = useState(queryParamsData.signal ? JSON.parse(queryParamsData.signal) : {});
    const [signalOptions, setSignalOptions] = useState([]);
    const [signalTypeMapping, setSignalTypeMapping] = useState({});
    const [counterInterval, setCounterInterval] = useState(null);
    const [isAutoRefresh, setIsAutorefresh] = useState(queryParamsData.autoRefresh ? JSON.parse(queryParamsData.autoRefresh) : false);
    const [lastStatus, setLastStatus] = useState('--');
    const [seconds, setSeconds] = useState(30);
    const [endTime, setEndTime] = useState(queryParamsData.endTime ? queryParamsData.endTime : curCetDateTime.format('HH:mm:ss'));
    const [startTime, setStartTime] = useState(queryParamsData.startTime ? queryParamsData.startTime : curCetDateTime.subtract(15, 'minutes').format('HH:mm:ss'));
    const [market, setMarket] = useState(queryParamsData.market ? JSON.parse(queryParamsData.market) : { label: 'None', value: 'none' });
    const [frequencyAnnotations, setFrequencyAnnotations] = useState({});
    const [yaxisVisible, setYaxisVisible] = useState({});
    const [reportOpen, setReportOpen] = useState(false);
    const [isParamsData, setIsParamsData] = useState(queryParamsData.signal ? true : false);
    const frequencyMarketProperties = {
        fcr: {
            lineMin: 49.98,
            lineMax: 50.02,
            lowVisible: 49.8,
            highVisible: 50.2,
        },
        fcrD: {
            lineMin: 50.1,
            lineMax: 50.14,
            lowVisible: 49.8,
            highVisible: 50.7,
        },
    };
    const marketOption = [
        {
            label: 'None',
            value: 'none',
        },
        {
            label: 'FCR',
            value: 'fcr',
        },
        {
            label: 'FCR-D-INC',
            value: 'fcrD',
        },
    ];
    const [customers, setCustomers] = useState([
        {
            label: 'Global',
            value: 'global',
        },
    ]);

    const [selectedCustomer, setSelectedCustomer] = useState({});
    const readSignal = (params, successHandler) => {
        signalsService.ReadSignalDataFromSource(
            params,
            () => startLoader('read-data'),
            successHandler,
            handleError,
            () => stopLoader('read-data')
        );
    };

    useEffect(() => {
        if (signalOptions?.length) {
            if (isParamsData) {
                const querySignal = JSON.parse(queryParamsData.signal);
                const [filteredSignal] = signalOptions?.filter((e) => e.value === querySignal.value);
                setSignal(filteredSignal);
                setIsParamsData(false);
            } else {
                setSignal(signalOptions[0]);
            }
        } else {
            setSignal({});
        }
    }, [JSON.stringify(signalOptions)]);

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);

    useEffect(() => {
        const filteredOptions = signalTypeMapping?.[signalType?.value]?.map((item) => ({
            label: item?.name,
            value: item?._id,
            dataSource: item?.dataSource,
            type: item?.type,
        }));
        setSignalOptions(filteredOptions ? filteredOptions : []);
    }, [signalType]);

    useEffect(() => {
        getCustomers();
    }, []);

    useEffect(() => {
        if (selectedCustomer?.value) {
            getSignals();
        }
    }, [selectedCustomer]);

    const getCustomers = () => {
        CustomerService.ReadAll({ isActive: true }, startLoader, handleGetCustomersSuccess, handleError, stopLoader);
    };

    const handleGetCustomersSuccess = ({ data }) => {
        let temp = [
            {
                label: 'Global',
                value: 'global',
            },
        ];
        data?.data?.map((item) => {
            temp.push({
                label: item.name,
                value: item._id,
            });
        });
        setCustomers(temp);
        setSelectedCustomer(queryParamsData.customer ? JSON.parse(queryParamsData.customer) : temp[0]);
    };

    const getSignals = () => {
        signalsService.ReadAll(
            { id: selectedCustomer?.value },
            () => startLoader('getSignals'),
            handleSignalSucess,
            handleError,
            () => stopLoader('getSignals')
        );
    };

    const getLastReportedData = (signalId) => {
        if (signalId) {
            signalsService.getLastReportedData({ signalId: signalId }, startLoader, handleLastReportedSuccess, handleError, stopLoader);
        }
    };

    const handleLastReportedSuccess = ({ data }) => {
        if (data.data) {
            setLastStatus(moment(data?.data).tz('Europe/Berlin').format('YYYY-MM-DD HH:mm:ss'));
        } else {
            setLastStatus('--');
        }
    };

    const readSignalData = () => {
        let params = {
            ...(signalType?.label === 'Frequency' && {
                start: startTime,
                end: endTime,
            }),
            date: moment(date).format('YYYY-MM-DD'),
            signalId: signal.value,
            prefillData: false,
            dataDecimation: true,
        };
        readSignal(params, handleSignalDataSucess);
        getLastReportedData(signal.value);
    };

    const handleSignalDataSucess = ({ data }) => {
        setGraphData(data.data?.data);
        setPowerData(data.data?.power);
        setSetPointData(data.data?.setPoint || []);
    };

    const handleSignalSucess = ({ data }) => {
        const list = data.data.reduce((acc, x) => {
            if (!acc[x.type]) acc[x.type] = [x];
            else acc[x.type] = [...acc[x.type], x];
            return acc;
        }, {});

        if (Object.keys(list).length) {
            setSignalTypeMapping(list);

            const signalTypeOptions = Object.keys(list).map((item) => ({
                label: item,
                value: item,
            }));

            const signalOption = list[Object.keys(signalType).length ? signalType.value : signalTypeOptions[0].value].map((item) => ({
                label: item.name,
                value: item._id,
                dataSource: item.dataSource,
                type: item.type,
            }));
            setSignalOptions(signalOption);
        } else {
            setSignalOptions([]);
            setSignal({});
        }
    };

    useEffect(() => {
        if (signal?.value) {
            let params = {
                ...(signalType?.value === 'Frequency' && {
                    start: startTime,
                    end: endTime,
                }),
                date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
                signalId: signal?.value,
                prefillData: false,
                dataDecimation: true,
            };
            readSignal(params, handleSignalDataSucess);
            getLastReportedData(signal?.value);
        }
    }, [signal]);

    const handleAdvanceDownloadExcel = (rawData = []) => {
        const downloadData = rawData?.downloadable;
        let columnIndex = 0;
        let columnLength = 0;
        const flattenedData = downloadData
            .map((item) => flatten(item, { delimiter: '_' }))
            .map((item, index) => {
                const timestampCET = item.timestampCET;
                delete item.timestampCET;
                const objectLength = Object.keys(item).length;
                if (objectLength > columnLength) {
                    columnLength = objectLength;
                    columnIndex = index;
                }
                return {
                    timestampCET,
                    ...item,
                };
            });

        const columnNames = ['TIMESTAMP (CET)'];
        for (let key in flattenedData[columnIndex]) {
            if (key !== 'timestampCET') {
                columnNames.push(key.toUpperCase());
            }
        }

        flattenedData?.length > 0 && DownloadAsExcel(flattenedData, `${Capitalize(signal?.dataSource)}-${Capitalize(signal?.label)}`, columnNames, columnIndex);

        setOpen(false);
    };

    const downLoadRawData = (prefillData = true) => {
        let params = {
            start: startTime,
            end: endTime,
            date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
            signalId: signal?.value,
            prefillData: prefillData,
            dataDecimation: false,
        };
        readSignal(params, handleRawSignalDataSucess);
    };

    const handleRawSignalDataSucess = ({ data }) => {
        handleAdvanceDownloadExcel(data?.data);
    };

    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    const formatYAxisValue = (value) => {
        return usToEuCurrencyFormat(value, 3);
    };

    const autoRefreshLastReportedData = (signalId) => {
        if (signalId) {
            signalsService.autoRefreshLastReportedData({ signalId: signalId }, handleLastReportedSuccess, handleError);
        }
    };

    const autoRefeshData = () => {
        autoRefreshLastReportedData(signal?.value);
        setEndTime(moment().tz('Europe/Berlin').add(1, 'minutes').format('HH:mm:ss'));
        const params = {
            ...(signalType?.label === 'Frequency' && {
                start: startTime,
                end: moment().tz('Europe/Berlin').add(1, 'minutes').format('HH:mm:ss'),
            }),
            date: moment(date).format('YYYY-MM-DD'),
            signalId: signal?.value,
            prefillData: false,
            dataDecimation: true,
        };
        signalsService.AutoRefreshData(params, handleSignalDataSucess, handleError);
    };

    useEffect(() => {
        if (isAutoRefresh) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else {
                    autoRefeshData();
                    setSeconds(30);
                }
            }, 1000);
            setCounterInterval(interval);
            return () => clearInterval(interval);
        }
    }, [isAutoRefresh, seconds]);

    useEffect(() => {
        if (isAutoRefresh) {
            const currentTime = moment().tz('Europe/Berlin').format('HH:mm:ss');
            const curCetDate = moment().tz('Europe/Berlin');
            setDate(new Date(curCetDate.format()));
            setEndTime(currentTime);
        }
    }, [isAutoRefresh]);

    const handleMarketChange = (value) => {
        if (value.value === 'fcr') {
            setYaxisVisible({
                lowVisible: frequencyMarketProperties.fcr.lowVisible,
                highVisible: frequencyMarketProperties.fcr.highVisible,
            });
            setFrequencyAnnotations({
                yaxis: [
                    {
                        y: frequencyMarketProperties.fcr.lineMin,
                        borderColor: '#219e4d',
                        label: {
                            borderColor: '#219e4d',
                            style: {
                                color: '#fff',
                                background: '#219e4d',
                                fontSize: '0.8vw',
                            },
                            text: String(frequencyMarketProperties.fcr.lineMin),
                        },
                    },
                    {
                        y: frequencyMarketProperties.fcr.lineMax,
                        borderColor: '#775DD0',
                        label: {
                            borderColor: '#775DD0',
                            style: {
                                color: '#fff',
                                background: '#775DD0',
                                fontSize: '0.8vw',
                            },
                            text: String(frequencyMarketProperties.fcr.lineMax),
                        },
                    },
                ],
            });
        } else if (value.value === 'fcrD') {
            setYaxisVisible({
                lowVisible: frequencyMarketProperties.fcrD.lowVisible,
                highVisible: frequencyMarketProperties.fcrD.highVisible,
            });
            setFrequencyAnnotations({
                yaxis: [
                    {
                        y: frequencyMarketProperties.fcrD.lineMin,
                        borderColor: '#219e4d',
                        label: {
                            borderColor: '#219e4d',
                            style: {
                                color: '#fff',
                                background: '#219e4d',
                                fontSize: '0.8vw',
                            },
                            text: String(frequencyMarketProperties.fcrD.lineMin),
                        },
                    },
                    {
                        y: frequencyMarketProperties.fcrD.lineMax,
                        borderColor: '#775DD0',
                        label: {
                            borderColor: '#775DD0',
                            style: {
                                color: '#fff',
                                background: '#775DD0',
                                fontSize: '0.8vw',
                            },
                            text: String(frequencyMarketProperties.fcrD.lineMax),
                        },
                    },
                ],
            });
        } else {
            setYaxisVisible({});
            setFrequencyAnnotations({
                yaxis: [{}],
            });
        }
    };

    return (
        <>
            <div className={SignalViewerClasses.ParentWrapper}>
                <ModalComponent isOpen={reportOpen} setOpen={setReportOpen}>
                    <SignalReportTable date={date} startTime={startTime} endTime={endTime} signal={signal} />
                </ModalComponent>
                <div style={{ backgroundColor: '#f4f7fc' }}>
                    <div className={SignalViewerClasses.HeaderContainer}>
                        <div className={classes.ComponentHeader}>
                            <div style={{ display: 'flex', gap: '0.5vw', alignItems: 'center' }}>
                                {/* <Typography content="Signal Viewer" /> */}
                                <div className={classes.HeaderLeft}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label className={SignalViewerClasses.DatePickerLabel}>Signal Type</label>
                                        {signalTypeOption.length > 0 && (
                                            <DropdownComponent
                                                defaultValue={signalType}
                                                options={signalTypeOption}
                                                onChange={(e) => {
                                                    setSignalType(e);
                                                    updateQueryParams('signalType', JSON.stringify(e));
                                                }}
                                                width={'9vw'}
                                            />
                                        )}
                                    </div>
                                </div>

                                {signalTypeOption.length > 0 && (
                                    <div className={classes.HeaderLeft}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <label className={SignalViewerClasses.DatePickerLabel}>Customer</label>
                                            <DropdownComponent
                                                defaultValue={selectedCustomer}
                                                options={customers}
                                                onChange={(e) => {
                                                    if (e.value != selectedCustomer.value) {
                                                        setSelectedCustomer(e);
                                                    }
                                                    updateQueryParams('customer', JSON.stringify(e));
                                                }}
                                                width={'9vw'}
                                            />
                                        </div>
                                    </div>
                                )}

                                {signalTypeOption.length > 0 && (
                                    <div className={classes.HeaderLeft}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <label className={SignalViewerClasses.DatePickerLabel}>Signal</label>
                                            <DropdownComponent
                                                defaultValue={signal}
                                                options={signalOptions}
                                                onChange={(e) => {
                                                    if (e.value !== signal?.value) {
                                                        setSignal(e);
                                                    }
                                                    updateQueryParams('signal', JSON.stringify(e));
                                                }}
                                                disabled={signalOptions?.length === 0}
                                                width={'8vw'}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'end',
                                    justifyContent: 'center',
                                    gap: '0.5vw',
                                }}
                            >
                                {signalType?.value !== 'Frequency' ? (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label className={SignalViewerClasses.DatePickerLabel}>Date</label>
                                        <DatePicker date={date} setDate={setDate} showArrow={true} />
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: '0.5vw',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <label className={SignalViewerClasses.DatePickerLabel}>Date</label>
                                            <DatePicker date={date} setDate={setDate} showArrow={true} />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <label className={SignalViewerClasses.DatePickerLabel}>Start Time</label>
                                            <CustomTimePicker
                                                startTime={startTime}
                                                setStartTime={(e) => {
                                                    setStartTime(e);
                                                    updateQueryParams('startTime', e);
                                                }}
                                                type="start"
                                                maxDetail={'second'}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <label className={SignalViewerClasses.DatePickerLabel}>End Time</label>
                                            <CustomTimePicker
                                                endTime={endTime}
                                                setEndTime={(e) => {
                                                    setEndTime(e);
                                                    updateQueryParams('endTime', e);
                                                }}
                                                type="end"
                                                disabled={isAutoRefresh}
                                                maxDetail={'second'}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div style={{ display: 'flex' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5vw',
                                        }}
                                    >
                                        <button
                                            type="submit"
                                            className="btn-primary"
                                            style={{
                                                marginTop: '0',
                                                width: 'auto',
                                            }}
                                            onClick={readSignalData}
                                        >
                                            Submit
                                        </button>
                                        <UilExclamationOctagon
                                            size="1.5vw"
                                            style={{
                                                color: 'var(--color-primary)',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => setReportOpen(true)}
                                        />
                                        <Menu
                                            menuButton={
                                                <div>
                                                    <UilImport
                                                        size="1.5vw"
                                                        style={{
                                                            cursor: 'pointer',
                                                            marginTop: '0.4vw',
                                                            color: 'var(--color-primary)',
                                                        }}
                                                    />
                                                </div>
                                            }
                                            direction={'bottom'}
                                            arrow={true}
                                            menuClassName={SignalViewerClasses.Menu}
                                        >
                                            <MenuItem onClick={() => downLoadRawData(false)} className={SignalViewerClasses.MenuOptions}>
                                                <div>Raw Download</div>
                                            </MenuItem>
                                            <MenuItem onClick={() => downLoadRawData(true)} className={SignalViewerClasses.MenuOptions}>
                                                <div>Advanced Download</div>
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.SignalViewerClasses}>
                        <div style={{ marginBottom: '1.5vh' }}>
                            <div
                                style={
                                    (signalType?.value === 'Frequency' && market?.value == 'none') || signalType?.value !== 'Frequency' ? { display: 'block' } : { display: 'none' }
                                }
                            >
                                <ChartComponent
                                    series={graphData}
                                    title={signalType?.label && signal?.label ? `${signalType?.label} - ${signal?.label}` : 'Select Signal'}
                                    type="line"
                                    curve={signalType?.value === 'Average Settlement' ? 'stepline' : 'smooth'}
                                    xFormatter={timeOnlyWithms}
                                    lastReported={lastStatus}
                                    yFormatter={formatYAxisValue}
                                    chartProps={{ group: 'signalLog' }}
                                    rightSideElement={
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5vw',
                                            }}
                                        >
                                            <ToggleButtonWithState
                                                label={'Auto Refresh'}
                                                value={isAutoRefresh}
                                                style={{ marginTop: '0' }}
                                                onChange={(e) => {
                                                    setIsAutorefresh(e.target.checked);
                                                    clearInterval(counterInterval);
                                                    setSeconds(30);
                                                }}
                                            />
                                            {isAutoRefresh && (
                                                <label
                                                    style={{
                                                        fontSize: '0.72vw',
                                                        fontWeight: '500',
                                                        marginLeft: '-4px',
                                                    }}
                                                >
                                                    in {seconds} seconds
                                                </label>
                                            )}
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                        <div style={signalType?.value === 'Frequency' && setPointData.length ? { display: 'block', marginBottom: '1.5vh' } : { display: 'none' }}>
                            <div>
                                <ChartComponent
                                    series={setPointData}
                                    title={'Set Point'}
                                    type="line"
                                    xFormatter={timeOnlyWithms}
                                    lastReported={lastStatus}
                                    yFormatter={formatYAxisValue}
                                    chartProps={{
                                        group: 'signalLog',
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ marginBottom: '1.5vh' }}>
                            <div
                                style={
                                    (signalType?.value === 'Frequency' && market?.value == 'none') || signalType?.value !== 'Frequency' ? { display: 'block' } : { display: 'none' }
                                }
                            >
                                <div style={signalType?.value === 'Frequency' ? { display: 'block' } : { display: 'none' }}>
                                    <ChartComponent
                                        series={powerData}
                                        title={'Power'}
                                        type="line"
                                        xFormatter={timeOnlyWithms}
                                        lastReported={lastStatus}
                                        yFormatter={formatYAxisValue}
                                        chartProps={{ group: 'signalLog' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{ marginBottom: '1.5vh' }}>
                            <div style={signalType?.value === 'Frequency' && market?.value !== 'none' ? { display: 'block', height: '100%' } : { display: 'none' }}>
                                <ChartComponent
                                    yProps={{
                                        min: yaxisVisible.lowVisible && yaxisVisible.lowVisible,
                                        max: yaxisVisible.highVisible && yaxisVisible.highVisible,
                                        tickAmount: 4,
                                    }}
                                    annotations={frequencyAnnotations}
                                    series={graphData}
                                    title={signalType?.label && signal?.label ? `${signalType?.label} - ${signal?.label}` : 'Select Signal'}
                                    type="line"
                                    xFormatter={timeOnlyWithms}
                                    lastReported={lastStatus}
                                    yFormatter={formatYAxisValue}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignalViewer;
