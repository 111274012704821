import React from 'react';

import { useField } from 'formik';

import TimePicker from 'react-time-picker';
import classes from './TimePicker.module.css';
function TimePickerWithFormik({ disableClock = true, time, disabled, style = {}, maxDetail = 'second', ...props }) {
    const [, meta, helpers] = useField(props);

    const handleSelect = (updatedTime) => {
        helpers.setValue && helpers.setValue(updatedTime);
    };

    return (
        <div>
            <div className={classes.TimePicker} style={style}>
                <div className={classes.Time} style={disabled ? { opacity: '0.5' } : {}}>
                    <TimePicker
                        clearIcon={null}
                        disableClock={disableClock}
                        onChange={handleSelect}
                        value={time}
                        disabled={disabled}
                        maxDetail={maxDetail}
                        format="mm:ss"

                    />
                </div>
            </div>
            {meta.touched && meta.error ? <div className="error-msg">{meta.error}</div> : null}
        </div>
    );
}

export default TimePickerWithFormik;
