// Standard library imports
import React, { useEffect, useState } from 'react';
// External library imports
import { Form, Formik } from 'formik';
import XLSX from 'xlsx';
import { toast } from 'react-toastify';
import ExcelJS from 'exceljs';
import { UilImport, UilExclamationTriangle, UilBoltAlt } from '@iconscout/react-unicons';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
// Css imports
import Classes from '../../../../styles/AllDevices.module.css';
import AuditClasses from './index.module.css';
// Intenal module imports
import MultipleFilesUpload from '../../../../components/Inputs/FileUpload/MultipleFileUpload';
import { useLoader } from '../../../../hooks';
import { DropdownComponent } from '../../../../components/Inputs/Input';
import { regulationsService } from '../../../../services/regulationsService';
import { momentTimeFormater } from '../../../../utils/timeHelper';
import { DownloadAsExcel } from '../../../../utils/downloadAsExcel';
import { truncateNumber } from '../../../../utils/numberHelper';
import DownloadButton from '../../../../components/Buttons/DownloadButton';
import { setCellData } from '../../../../utils/exceljsHelper';
import { DownloadFileFromBuffer } from '../../../../utils/downloadHelper';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import Table from '../../../../components/Table/Table';
import moment from 'moment';
// import { AFRR_MARKET_TYPES } from '../../../../constants';
import _ from 'lodash';
import { RootAuditPQToolService } from '../../../../services/RootAuditPQToolsService';

const AFRR_MARKET_TYPES = ['aFRR'];

const TEST_TYPE = [
    {
        label: 'Static Ramp Test',
        value: 'staticRampTest',
    },
    {
        label: 'Static Linearity Test',
        value: 'staticLinearityTest',
    },
    {
        label: 'Functional Test',
        value: 'functionalTest',
    },
];

const RAMP_TEST_FREQUENCY = {
    inc: {
        t1: 50.1,
        t2: 50.5,
        t3: 50.1,
    },
    dec: {
        t1: 49.9,
        t2: 49.5,
        t3: 49.9,
    },
};

const LINEARLITY_TEST_FREQUENCY = {
    inc: {
        t1: 50.1,
        t2: 50.2,
        t3: 50.3,
        t4: 50.4,
        t5: 50.5,
        t6: 50.4,
        t7: 50.3,
        t8: 50.2,
        t9: 50.1,
    },
    dec: {
        t1: 49.9,
        t2: 49.8,
        t3: 49.7,
        t4: 49.6,
        t5: 49.5,
        t6: 49.6,
        t7: 49.7,
        t8: 49.8,
        t9: 49.9,
    },
};

const MARKET_OPTION = [
    {
        label: 'FCR-D-INC',
        value: 'FCR-D-INC',
    },
    {
        label: 'FCR-D-DEC',
        value: 'FCR-D-DEC',
    },
    {
        label: 'aFRR',
        value: 'aFRR',
    },
];

const fileNamePrefix = 'PQ Analyse';

const PQAnalyser = ({ queryParamsData, updateQueryParams }) => {
    const [startLoader, stopLoader] = useLoader();
    const [marketOptions, setMarketOption] = useState([]);
    const [selectedMarket, setSelectedMarket] = useState(MARKET_OPTION[0]);
    const [selectedType, setSelectedType] = useState({});
    const [proccessedFile, setProccessedFiles] = useState([]);
    const [valueKey, setValueKey] = useState('VALUE_FREQUENCY');
    const [frequencyOrSetPoint, setFrequencyOrSetPoint] = useState('Frequency');
    const [energinetPQTemplate, setEnerginetPQTemplate] = useState('');

    useEffect(() => {
        getEnerginetTemplate();
        if (AFRR_MARKET_TYPES.includes(selectedMarket?.value)) {
            setValueKey('VALUE_SETPOINT');
            setFrequencyOrSetPoint('Set Point');
        } else {
            setValueKey('VALUE_FREQUENCY');
            setFrequencyOrSetPoint('Frequency');
        }
    }, [selectedMarket]);

    // useEffect(() => {
    //     getMarkets();
    // }, []);

    // const getMarkets = () => {
    //     regulationsService.ReadAll(startLoader, handleSuccess, handleError, stopLoader);
    // };

    // const handleSuccess = ({ data }) => {
    //     const temp = data.data.map((item) => ({
    //         label: item.name,
    //         value: item.name,
    //     }));
    //     setMarketOption(temp);
    //     setSelectedMarket(queryParamsData.market ? JSON.parse(queryParamsData.market) : temp[0]);
    // };

    const handleUploadClick = async (e) => {
        try {
            startLoader();
            if (selectedMarket?.value === 'FCR-D-INC' || selectedMarket?.value === 'FCR-D-DEC' || AFRR_MARKET_TYPES.includes(selectedMarket?.value)) {
                const files = Array.from(e.target.files);
                const temp = [];
                const promises = files.map((el) => {
                    const file = el;
                    const energinetFileName = file.name.split('.').join(' Energinet.xlsx.');
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = async (event) => {
                            try {
                                const arrayBuffer = event.target.result;
                                const binaryString = new Uint8Array(arrayBuffer).reduce((data, byte) => {
                                    return data + String.fromCharCode(byte);
                                }, '');
                                const workbook = XLSX.read(binaryString, { type: 'binary' });
                                const sheetName = workbook.SheetNames[0];
                                const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                                const column = Object.keys(excelData[0]);
                                if ((column.includes('VALUE_FREQUENCY') || column.includes('VALUE_SETPOINT')) && column.includes('VALUE_POWER') && column.includes('TIMESTAMP')) {
                                    let result = {};
                                    if (selectedType?.value === 'functionalTest') {
                                        result = await functionalTest(excelData, energinetFileName);
                                    } else if (selectedType?.value === 'staticRampTest') {
                                        result = await rampTest(excelData, energinetFileName);
                                    } else {
                                        result = await linearityTest(excelData, energinetFileName);
                                    }
                                    temp.push({
                                        fileName: file.name,
                                        energinetFileName: energinetFileName,
                                        standard: result?.standard || [],
                                        energinet: result?.energinetDataWorkbook || [],
                                        isVerified: result.status ? true : false,
                                        action: result.status ? (
                                            <div className={AuditClasses.ActionButton}>
                                                <div>
                                                    <CustomTooltip content={'Standard Format'}>
                                                        <div onClick={() => handleDownloadLinearity(result.standard, 'standard', file.name)}>
                                                            <UilImport
                                                                size="1.2vw"
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    color: 'var(--color-primary)',
                                                                }}
                                                            />
                                                        </div>
                                                    </CustomTooltip>
                                                </div>
                                                <div>
                                                    <CustomTooltip content={'Energinet Format'}>
                                                        <div onClick={() => handleDownloadLinearity(result.energinetDataWorkbook, 'energinet', energinetFileName)}>
                                                            <UilBoltAlt
                                                                size="1.2vw"
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    color: 'var(--color-primary)',
                                                                }}
                                                            />
                                                        </div>
                                                    </CustomTooltip>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <CustomTooltip content={result.error}>
                                                    <div>
                                                        <UilExclamationTriangle
                                                            size="1.2vw"
                                                            style={{
                                                                cursor: 'pointer',
                                                                color: 'var(--color-primary)',
                                                            }}
                                                        />
                                                    </div>
                                                </CustomTooltip>
                                            </div>
                                        ),
                                    });
                                } else {
                                    const error = [];
                                    if (!column.includes('TIMESTAMP')) {
                                        error.push('TIMESTAMP');
                                    }
                                    if (!column.includes('VALUE_POWER')) {
                                        error.push('VALUE_POWER');
                                    }
                                    if (AFRR_MARKET_TYPES.includes(selectedMarket?.value)) {
                                        if (!column.includes('VALUE_SETPOINT')) {
                                            error.push('VALUE_SETPOINT');
                                        }
                                    } else {
                                        if (!column.includes('VALUE_FREQUENCY')) {
                                            error.push('VALUE_FREQUENCY');
                                        }
                                    }

                                    temp.push({
                                        fileName: file.name,
                                        energinetFileName: energinetFileName,
                                        standard: [],
                                        energinet: [],
                                        isVerified: false,
                                        action: (
                                            <div>
                                                <CustomTooltip content={`${error.join(', ')} Column missing`}>
                                                    <div>
                                                        <UilExclamationTriangle
                                                            size="1.2vw"
                                                            style={{
                                                                cursor: 'pointer',
                                                                color: 'var(--color-primary)',
                                                            }}
                                                        />
                                                    </div>
                                                </CustomTooltip>
                                            </div>
                                        ),
                                    });
                                }
                                resolve();
                            } catch (error) {
                                reject(error);
                            }
                        };
                        reader.readAsArrayBuffer(file);
                    });
                });
                await Promise.all(promises);
                setProccessedFiles(temp);
            } else {
                toast.warn('Tool is in progress.');
                setProccessedFiles([]);
            }
            stopLoader();
        } catch (err) {
            stopLoader();
            toast.error(`Error while processing file`);
        }
    };

    const checkDuplicateTimestamp = (data) => {
        const temp = [];
        const duplicateTimestamp = [];
        let count = 0;
        data.map((i) => {
            if (temp.includes(i.TIMESTAMP)) {
                count += 1;
                if (!duplicateTimestamp.includes(i.TIMESTAMP)) {
                    duplicateTimestamp.push(i.TIMESTAMP);
                }
            } else {
                temp.push(i.TIMESTAMP);
            }
        });
        return { count, duplicateTimestamp };
    };

    const rampTest = async (data = [], fileName) => {
        let freq = {};
        if (selectedMarket.value === 'FCR-D-INC') {
            freq = RAMP_TEST_FREQUENCY.inc;
        } else if (selectedMarket.value === 'FCR-D-DEC') {
            freq = RAMP_TEST_FREQUENCY.dec;
        }
        if (data.length === 2280) {
            const duplicate = checkDuplicateTimestamp(data);
            if (duplicate.count === 0) {
                const firstTest = data.slice(0, 180).every((obj) => obj.VALUE_FREQUENCY == freq.t1);
                const secondTest = data.slice(180, 1080).every((obj) => obj.VALUE_FREQUENCY == freq.t2);
                const thirdTest = data.slice(1080, 2280).every((obj) => obj.VALUE_FREQUENCY == freq.t3);
                if (!firstTest || !secondTest || !thirdTest) {
                    if (!firstTest) {
                        return {
                            status: false,
                            error: `0-180 range ${freq.t1} frequency not correct`,
                        };
                    } else if (!secondTest) {
                        return {
                            status: false,
                            error: `180-1080 range ${freq.t2} frequency not correct`,
                        };
                    } else if (!thirdTest) {
                        return {
                            status: false,
                            error: `1080-2280 range ${freq.t3} frequency not correct`,
                        };
                    }
                } else {
                    const energinetDataWorkbook = await constructEnerginetFormat(data, fileName);
                    return {
                        standard: data,
                        energinetDataWorkbook: energinetDataWorkbook,
                        status: true,
                    };
                }
            } else {
                return {
                    status: false,
                    error: `${duplicate.count} duplicate timestamps (${duplicate.duplicateTimestamp.join(', ')})`,
                };
            }
        } else {
            if (data.length > 2280) {
                const diff = data.length - 2280;
                return {
                    status: false,
                    error: `${diff} extra timestamps`,
                };
            } else {
                const diff = 2280 - data.length;
                return {
                    status: false,
                    error: `${diff} timestamps missing`,
                };
            }
        }
    };

    const getEnerginetTemplate = () => {
        if (selectedMarket?.value) RootAuditPQToolService.GetEnerginetTemplate(selectedMarket?.value, startLoader, handleTemplateSuccess, handleError, stopLoader);
    };

    const handleTemplateSuccess = ({ data }) => {
        setEnerginetPQTemplate(data.data.data);
    };

    const constructEnerginetFormat = async (data, fileName) => {
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(energinetPQTemplate);
        const sheet = workbook.worksheets[0];
        const startRow = 16;
        const cellStyleA = sheet.getCell('A12').style;
        const cellStyleB = sheet.getCell('B12').style;
        const cellStyleC = sheet.getCell('C12').style;
        const cellStyleD = sheet.getCell('D12').style;
        const cellStyleE = sheet.getCell('E12').style;

        data.forEach((row, idx) => {
            const date = new Date(row.TIMESTAMP);
            setCellData(sheet, `A${startRow + idx}`, date, cellStyleA);
            setCellData(sheet, `B${startRow + idx}`, _.get(row, [valueKey]), cellStyleB);
            setCellData(sheet, `C${startRow + idx}`, row.VALUE_POWER / 1000, cellStyleC);
            setCellData(sheet, `D${startRow + idx}`, '', cellStyleD);
            setCellData(sheet, `E${startRow + idx}`, '', cellStyleE);
        });
        const buffer = await workbook.xlsx.writeBuffer();
        return buffer;
    };

    const linearityTest = async (data = [], fileName) => {
        let freq = {};
        if (selectedMarket.value === 'FCR-D-INC') {
            freq = LINEARLITY_TEST_FREQUENCY.inc;
        } else if (selectedMarket.value === 'FCR-D-DEC') {
            freq = LINEARLITY_TEST_FREQUENCY.dec;
        }
        if (data.length === 1080) {
            const duplicate = checkDuplicateTimestamp(data);
            if (duplicate.count === 0) {
                const test1 = data.slice(0, 120).every((obj) => obj.VALUE_FREQUENCY == freq.t1);
                const test2 = data.slice(120, 240).every((obj) => obj.VALUE_FREQUENCY == freq.t2);
                const test3 = data.slice(240, 360).every((obj) => obj.VALUE_FREQUENCY == freq.t3);
                const test4 = data.slice(360, 480).every((obj) => obj.VALUE_FREQUENCY == freq.t4);
                const test5 = data.slice(480, 600).every((obj) => obj.VALUE_FREQUENCY == freq.t5);
                const test6 = data.slice(600, 720).every((obj) => obj.VALUE_FREQUENCY == freq.t6);
                const test7 = data.slice(720, 840).every((obj) => obj.VALUE_FREQUENCY == freq.t7);
                const test8 = data.slice(840, 960).every((obj) => obj.VALUE_FREQUENCY == freq.t8);
                const test9 = data.slice(960, 1080).every((obj) => obj.VALUE_FREQUENCY == freq.t9);

                if (!test1 || !test2 || !test3 || !test4 || !test5 || !test6 || !test7 || !test8 || !test9) {
                    if (!test1) {
                        return {
                            status: false,
                            error: `0-120 range ${freq.t1} frequency not correct`,
                        };
                    } else if (!test2) {
                        return {
                            status: false,
                            error: `120-240 range ${freq.t2} frequency not correct`,
                        };
                    } else if (!test3) {
                        return {
                            status: false,
                            error: `240-360 range ${freq.t3} frequency not correct`,
                        };
                    } else if (!test4) {
                        return {
                            status: false,
                            error: `360-480 range ${freq.t4} frequency not correct`,
                        };
                    } else if (!test5) {
                        return {
                            status: false,
                            error: `480-600 range ${freq.t5} frequency not correct`,
                        };
                    } else if (!test6) {
                        return {
                            status: false,
                            error: `600-720 range ${freq.t6} frequency not correct`,
                        };
                    } else if (!test7) {
                        return {
                            status: false,
                            error: `720-840 range ${freq.t7} frequency not correct`,
                        };
                    } else if (!test8) {
                        return {
                            status: false,
                            error: `840-960 range ${freq.t8} frequency not correct`,
                        };
                    } else if (!test9) {
                        return {
                            status: false,
                            error: `960-1080 range ${freq.t9} frequency not correct`,
                        };
                    }
                } else {
                    const energinetDataWorkbook = await constructEnerginetFormat(data, fileName);
                    return {
                        standard: data,
                        energinetDataWorkbook: energinetDataWorkbook,
                        status: true,
                    };
                }
            } else {
                return {
                    status: false,
                    error: `${duplicate.count} duplicate timestamps (${duplicate.duplicateTimestamp.join(', ')})`,
                };
            }
        } else {
            if (data.length > 1080) {
                const diff = data.length - 1080;
                return {
                    status: false,
                    error: `${diff} extra timestamps`,
                };
            } else {
                const diff = 1080 - data.length;
                return {
                    status: false,
                    error: `${diff} timestamps missing`,
                };
            }
        }
    };

    const functionalTest = async (data = [], fileName) => {
        if (AFRR_MARKET_TYPES.includes(selectedMarket?.value)) {
            if (data.length === 3300) {
                const duplicate = checkDuplicateTimestamp(data);
                if (duplicate.count === 0) {
                    const firstTest = data.slice(0, 300).every((obj) => obj.VALUE_SETPOINT <= 100);
                    const secondTest = data.slice(300, 900).every((obj) => obj.VALUE_SETPOINT == 100);
                    const thirdTest = data.slice(900, 1200).every((obj) => obj.VALUE_SETPOINT >= 0);
                    const fourTest = data.slice(1200, 1800).every((obj) => obj.VALUE_SETPOINT == 0);
                    const fifthTest = data.slice(1800, 2100).every((obj) => obj.VALUE_SETPOINT <= 100);
                    const sixTest = data.slice(2100, 2400).every((obj) => obj.VALUE_SETPOINT >= 0);
                    const sevenTest = data.slice(2400, 2700).every((obj) => obj.VALUE_SETPOINT <= 50 && obj.VALUE_SETPOINT >= 0);
                    const eightTest = data.slice(2700, 3000).every((obj) => obj.VALUE_SETPOINT == 50);
                    const nineTest = data.slice(3000, 3300).every((obj) => obj.VALUE_SETPOINT <= 50 && obj.VALUE_SETPOINT >= 0);

                    if (!firstTest) {
                        return {
                            status: false,
                            error: `0-300 range set point not correct`,
                        };
                    } else if (!secondTest) {
                        return {
                            status: false,
                            error: `300-900 range set point not correct`,
                        };
                    } else if (!thirdTest) {
                        return {
                            status: false,
                            error: `900-1200 range set point not correct`,
                        };
                    } else if (!fourTest) {
                        return {
                            status: false,
                            error: `1200-1800 range set point not correct`,
                        };
                    } else if (!fifthTest) {
                        return {
                            status: false,
                            error: `1800-2100 range set point not correct`,
                        };
                    } else if (!sixTest) {
                        return {
                            status: false,
                            error: `2100-2400 range set point not correct`,
                        };
                    } else if (!sevenTest) {
                        return {
                            status: false,
                            error: `2400-2700 range set point not correct`,
                        };
                    } else if (!eightTest) {
                        return {
                            status: false,
                            error: `2700-3000 range set point not correct`,
                        };
                    } else if (!nineTest) {
                        return {
                            status: false,
                            error: `3000-3300 range set point not correct`,
                        };
                    } else {
                        const energinetDataWorkbook = await constructEnerginetFormat(data, fileName);
                        return {
                            standard: data,
                            energinetDataWorkbook: energinetDataWorkbook,
                            status: true,
                        };
                    }
                } else {
                    return {
                        status: false,
                        error: `${duplicate.count} duplicate timestamps (${duplicate.duplicateTimestamp.join(', ')})`,
                    };
                }
            } else {
                if (data.length > 3300) {
                    const diff = data.length - 3300;
                    return {
                        status: false,
                        error: `${diff} extra timestamps`,
                    };
                } else {
                    const diff = 3300 - data.length;
                    return {
                        status: false,
                        error: `${diff} timestamps missing`,
                    };
                }
            }
        } else {
            toast.error('Please select aFRR market.');
        }
    };
    const handleDownloadLinearity = (data, type = 'standard', fileName) => {
        if (type === 'energinet') {
            DownloadFileFromBuffer(data, `${fileNamePrefix} ${fileName}`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        } else {
            downloadFile(data, fileName);
        }
    };

    const downloadZipFile = async (type) => {
        startLoader();
        const zip = new JSZip();
        proccessedFile.map((item) => {
            if (item.isVerified) {
                if (type == 'standard') {
                    const headers = ['Date (CET)', 'Time (CET)', frequencyOrSetPoint, 'Power'];
                    const worksheetData = [
                        headers,
                        ...item.standard.map((i) => [
                            momentTimeFormater(i.TIMESTAMP).format('YYYY-MM-DD'),
                            momentTimeFormater(i.TIMESTAMP).format('HH:mm:ss'),
                            truncateNumber(_.get(i, [valueKey]), 3),
                            truncateNumber(i.VALUE_POWER, 3),
                        ]),
                    ];
                    const ws = XLSX.utils.aoa_to_sheet(worksheetData);
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
                    const buffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                    const excelBlob = new Blob([new Uint8Array(buffer)], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    });
                    zip.file(item.fileName, excelBlob);
                } else {
                    const excelBlob = new Blob([item.energinet], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    });
                    zip.file(item.energinetFileName, excelBlob);
                }
            }
        });
        const zipBlob = await zip.generateAsync({ type: 'blob' });
        saveAs(zipBlob, `${fileNamePrefix} ${type} format.zip`);
        stopLoader();
    };

    const downloadFile = (data, fileName) => {
        const formatedData = data.map((i) => {
            return {
                date: momentTimeFormater(i.TIMESTAMP).format('YYYY-MM-DD'),
                time: momentTimeFormater(i.TIMESTAMP).format('HH:mm:ss'),
                frequency: truncateNumber(_.get(i, [valueKey]), 3),
                power: truncateNumber(i.VALUE_POWER, 3),
            };
        });
        const headers = ['Date (CET)', 'Time (CET)', frequencyOrSetPoint, 'Power'];
        DownloadAsExcel(formatedData, `${fileNamePrefix} ${fileName}`, headers);
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    return (
        <div>
            <div className={AuditClasses.Filter}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className={AuditClasses.FilterContainer}>
                        <div></div>
                        <div>
                            <div className={Classes.FieldControl}>
                                <label>Select Market</label>
                                <DropdownComponent
                                    name="market"
                                    options={MARKET_OPTION}
                                    defaultValue={selectedMarket}
                                    onChange={(e) => {
                                        if (e.value !== selectedMarket?.value) {
                                            setSelectedMarket(e);
                                            setProccessedFiles([]);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <div className={Classes.FieldControl}>
                                <label>Select Type</label>
                                <DropdownComponent
                                    name="type"
                                    options={TEST_TYPE}
                                    defaultValue={TEST_TYPE[0]}
                                    onChange={(e) => {
                                        if (e.value !== selectedType?.value) {
                                            setSelectedType(e);
                                            setProccessedFiles([]);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={AuditClasses.FilterContainer}></div>
                </div>
            </div>
            <div className={Classes.AllDevices}>
                <div className={AuditClasses.UploadFileContainer}>
                    <Formik initialValues={{}} enableReinitialize>
                        {({ errors, touched, values, isValidating, ...props }) => {
                            return (
                                <Form>
                                    <div className={AuditClasses.FileContainer}>
                                        <div className={Classes.InputContainer}>
                                            <div className={AuditClasses.DatePickerLabel} style={{ width: '35vw' }}>
                                                <label>Upload file</label>
                                                <MultipleFilesUpload
                                                    name="file"
                                                    id="file"
                                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                    onChange={handleUploadClick}
                                                    multiple
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                    {proccessedFile?.length ? (
                        <div>
                            <div className={`${AuditClasses.FileContainer} ${AuditClasses.DownloadButtonControl}`}>
                                <DownloadButton size={'medium'} text={'All files - Standard Format'} onClick={() => downloadZipFile('standard')} />
                                <DownloadButton size={'medium'} text={'All files - Energinet Format'} onClick={() => downloadZipFile('energinet')} />
                            </div>
                            <div style={{ minWidth: '35vw' }}>
                                <Table head={['File', 'Action']} keys={['fileName', 'action']} data={proccessedFile} />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
};

export default PQAnalyser;
