// Standard module imports
import React, { useState, useEffect } from 'react';

// External library imports
import { meanBy as LodashMean, minBy as LodashMin, maxBy as LodashMax } from 'lodash';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { UilPostcard, UilComparison, UilInfoCircle, UilCommentAltCheck } from '@iconscout/react-unicons';
import { flatten } from 'flat';
import { toast } from 'react-toastify';
// Internal module imports
import Table from '../../../components/Table/Table';
import { truncateNumber } from '../../../utils/numberHelper';
import { Capitalize } from '../../../utils/stringHelper';
import { getCETDate } from '../../../utils/dateHelper.js';
import { momentTimeFormater } from '../../../utils/timeHelper.js';
import DownloadButton from '../../../components/Buttons/DownloadButton';
import { Input } from '../../../components/Inputs/Input';
import { AFRR_MARKET_TYPES, FREQUENCY_DIRECTION, PERCENTAGE_DROPDOWN } from '../../../constants.js';
import { activationProfileService } from '../../../services/ActivationProfileService';
import { useLoader } from '../../../hooks';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import DataLogModel from './ModalComponent/DataLogModel.js';
import Typography from '../../../components/Typography/Typography';
import DropDown from '../../../components/Inputs/Dropdown';

// Css imports
import classes from './ActivationProfiler.module.css';

const Events = ({ profile, date, onSubmit, updateQueryParams, queryParamsData }) => {
    const [startLoader, stopLoader] = useLoader();

    const [events, setEvents] = useState([]);
    const [tableHeaderAndKeys, setTableHeaderAndKeys] = useState({
        header: [],
        keys: [],
    });

    const [durationData, setDurationData] = useState({
        min: '',
        max: '',
        avg: 0,
    });

    let [filter, setFilter] = useState({
        deltaLoad: queryParamsData.deltaload ? Number(JSON.parse(queryParamsData.deltaload).value) : 0,
        duration: 25,
        direction: queryParamsData.direction ? JSON.parse(queryParamsData.direction).value : '',
    });
    const [dataLogModal, setDataLogModal] = useState({
        status: false,
        meta: {},
        id: null,
    });
    const [downloadable, setDownloadable] = useState([]);

    let minutes = Math.floor(durationData.avg / 60);
    let seconds = durationData.avg - minutes * 60;

    useEffect(() => {
        if (profile.market && profile.instanceId && date) {
            fetchEvents();
        }
    }, [profile.market, profile.instanceId, onSubmit]);

    useEffect(() => {
        if (profile.market) {
            if (AFRR_MARKET_TYPES.includes(profile.market)) {
                setTableHeaderAndKeys({
                    header: ['Direction', 'Start Time (CET)', 'Max Set Point', 'Duration', 'Consumption (kWh)', 'Action'],
                    keys: ['direction', 'startTime', 'maxSetPoint', 'duration', 'fconsumption', 'action'],
                });
            } else {
                setTableHeaderAndKeys({
                    header: ['Direction', 'Start Time (CET)', 'Delta Load (%)', 'Max Frequency (Hz)', 'Duration', 'Consumption (kWh)', 'Action'],
                    keys: ['direction', 'startTime', 'processedDeltaLoad', 'fmaxFrequency', 'duration', 'fconsumption', 'action'],
                });
            }
        }
    }, [profile?.market]);

    const closeDataLogModel = () => {
        setDataLogModal({ status: false, meta: {}, id: null });
    };

    const handleError = (err) => {
        console.log(err);
    };

    const fetchEvents = () => {
        const params = {
            type: profile.market,
            group: profile.instanceId,
            startDate: moment(date).format('YYYY-MM-DD'),
            endDate: moment(date).format('YYYY-MM-DD'),
            ...filter,
        };
        activationProfileService.getAllEvents(
            params,
            () => startLoader('getAllEvents'),
            handleEventsSuccess,
            handleError,
            () => stopLoader('getAllEvents')
        );
    };

    const calculateConsumption = (dataLog = [], duration = 0) => {
        let totalPower = 0;
        let count = dataLog.length;
        let consumption = '--';
        dataLog.map((item) => {
            totalPower += item.power || 0;
        });
        const avgPower = totalPower / count;
        if (avgPower > 0) {
            consumption = avgPower * (duration / 3600);
        }

        return isNaN(consumption) ? (
            consumption
        ) : (
            <span className={classes.ConsumptionInfo}>
                <span style={{ marginBottom: '0.4vh' }}>{truncateNumber(consumption, 3).toLocaleString('da-DK')}</span>
                <CustomTooltip content={'Estimated Consumption'}>
                    <UilInfoCircle
                        size="1.2vw"
                        style={{
                            cursor: 'pointer',
                        }}
                    />
                </CustomTooltip>
            </span>
        );
    };

    const handleEventsSuccess = (res) => {
        let eventsData = res.data.data;
        const downloadableData = [];
        let processedEvents = eventsData.map((eventdata) => {
            // data for download
            const data = { ...eventdata };
            delete data.dataLog;
            delete data.commandLog;
            downloadableData.push(data);

            // data for table
            return {
                ...eventdata,
                deltaLoad: eventdata.deltaLoad,
                fmaxFrequency: truncateNumber(eventdata.maxFrequency, 3).toLocaleString('da-DK'),
                fconsumption: isNaN(eventdata.consumption)
                    ? calculateConsumption(eventdata.dataLog, eventdata.difference)
                    : truncateNumber(eventdata.consumption, 3).toLocaleString('da-DK'),
                direction: Capitalize(eventdata.direction),
                processedDeltaLoad:
                    eventdata.direction === 'increased' ? (
                        <div style={{ color: 'darkgreen' }}>{eventdata.deltaLoad.toLocaleString('da-DK')}</div>
                    ) : (
                        <div style={{ color: 'red' }}> {eventdata.deltaLoad.toLocaleString('da-DK')}</div>
                    ),
                action: (
                    <span style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <CustomTooltip content={'Command Log'}>
                            <UilPostcard
                                size="1.2vw"
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleCommandLogDataExport(eventdata?.commandLog, eventdata.startTime, 'Command Log')}
                            />
                        </CustomTooltip>
                        <CustomTooltip content={'Data Log'}>
                            <UilComparison
                                size="1.2vw"
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() =>
                                    setDataLogModal({
                                        status: true,
                                        id: eventdata._id,
                                        meta: {
                                            groupName: profile?.name,
                                            startTime: eventdata.startTime,
                                        },
                                    })
                                }
                            />
                        </CustomTooltip>
                        <CustomTooltip content={'Control Log'}>
                            <UilCommentAltCheck
                                size="1.2vw"
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleCommandLogDataExport(eventdata?.controlLog || [], eventdata.startTime, 'Control Log')}
                            />
                        </CustomTooltip>
                    </span>
                ),
            };
        });
        setEvents(processedEvents);
        setDownloadable(downloadableData);
        setDurationData({
            max: LodashMax(eventsData, 'difference'),
            min: LodashMin(eventsData, 'difference'),
            avg: LodashMean(eventsData, 'difference'),
        });
    };

    const handleCommandLogDataExport = (downloadData = [], startTime, type = 'Command Log') => {
        if (downloadData.length) {
            let columnIndex = 0;
            let columnLength = 0;
            const flattenedData = downloadData
                .map((item) => flatten(item, { delimiter: '_' }))
                .map((item, index) => {
                    const timestampCET = item.timestamp ? getCETDate(momentTimeFormater(item.timestamp)) : getCETDate(momentTimeFormater(item.timeStamp?.replace(' ', 'T') + 'Z'));
                    const objectLength = Object.keys(item).length;
                    if (objectLength > columnLength) {
                        columnLength = objectLength;
                        columnIndex = index;
                    }
                    return {
                        timestampCET,
                        ...item,
                    };
                });

            if (flattenedData.length) {
                const columnNames = ['TIMESTAMP (CET)'];
                for (let key in flattenedData[columnIndex]) {
                    if (key !== 'timestampCET') {
                        columnNames.push(key.toUpperCase());
                    }
                }
                DownloadAsExcel(flattenedData, `${profile?.name} CommandLog - ${startTime}`, columnNames, columnIndex);
            }
        } else {
            toast.error('No Data');
        }
    };

    const handleExportAllEvents = () => {
        let columnIndex = 0;
        let columnLength = 0;
        const flattenedData = downloadable
            .map((item) => flatten(item, { delimiter: '_' }))
            .map((item, index) => {
                const objectLength = Object.keys(item).length;
                if (objectLength > columnLength) {
                    columnLength = objectLength;
                    columnIndex = index;
                }
                return item;
            });

        const columnNames = [];
        for (let key in flattenedData[columnIndex]) {
            columnNames.push(key.toUpperCase());
        }
        if (flattenedData.length) {
            DownloadAsExcel(flattenedData, `AllEvents_${profile?.name}`, columnNames, columnIndex);
        }
    };

    const handleSubmit = (val) => {
        let values = val.direction === 'All' ? { ...val, direction: '' } : { ...val };
        setFilter(values);
        const params = {
            type: profile.market,
            group: profile.instanceId,
            startDate: moment(date).format('YYYY-MM-DD'),
            endDate: moment(date).format('YYYY-MM-DD'),
            ...values,
        };

        activationProfileService.getAllEvents(
            params,
            () => startLoader('getAllEvents'),
            handleEventsSuccess,
            handleError,
            () => stopLoader('getAllEvents')
        );
    };

    return (
        <div className={classes.Events} style={{ borderRadius: '0.7vw', flexGrow: 1 }}>
            <Formik initialValues={filter} onSubmit={handleSubmit}>
                {({ errors, touched, values, isValidating, ...props }) => (
                    <Form>
                        <div className={classes.InputContainer} style={{ justifyContent: 'flex-end' }}>
                            <div>
                                <div className={classes.FieldControl2}>
                                    <label for="direction">Direction</label>
                                    <DropDown
                                        options={FREQUENCY_DIRECTION}
                                        defaultValue={queryParamsData.direction ? JSON.parse(queryParamsData.direction) : FREQUENCY_DIRECTION[0]}
                                        name="direction"
                                        label="direction"
                                        onChange={(e) => {
                                            updateQueryParams('direction', JSON.stringify(e));
                                        }}
                                    />
                                </div>
                            </div>

                            <div>
                                <div className={classes.FieldControl2}>
                                    <label for="deltaLoad">Percentage</label>
                                    <DropDown
                                        name="deltaLoad"
                                        label="deltaLoad"
                                        options={PERCENTAGE_DROPDOWN}
                                        defaultValue={queryParamsData.deltaload ? JSON.parse(queryParamsData.deltaload) : PERCENTAGE_DROPDOWN[0]}
                                        onChange={(e) => {
                                            updateQueryParams('deltaload', JSON.stringify(e));
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className={classes.FieldControl2}>
                                    <label for="duration">Duration</label>
                                    <Input name="duration" id="duration" />
                                </div>
                            </div>
                            <div className={classes.InputContainer}>
                                <div className={classes.SubmitButton}>
                                    <button className="btn-primary">Submit</button>
                                </div>
                                <div className={classes.SubmitButton} style={{ marginTop: '1vw' }}>
                                    <DownloadButton size="medium" onClick={() => handleExportAllEvents()} />
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <div className={classes.Duration} style={{ width: 'auto' }}>
                <div className={classes.DurationContent}>
                    <div>
                        Min Duration: <span className="black">{durationData.min !== undefined ? durationData.min.duration : '--'}</span>
                    </div>
                    <div>
                        Average Duration: <span className="black">{durationData.avg ? `${minutes}M : ${seconds.toFixed(0)}S` : '--'}</span>
                    </div>
                    <div>
                        Max Duration: <span className="black"> {durationData.max !== undefined ? durationData.max.duration : '--'}</span>
                    </div>
                </div>
            </div>
            <div>
                <div className={classes.TabelHeading}>
                    <Typography size={14} content={`Activation Events (${profile?.name})`} />
                </div>
                <Table head={tableHeaderAndKeys.header} data={events} keys={tableHeaderAndKeys.keys} />
            </div>
            <ModalComponent isOpen={dataLogModal.status} setOpen={closeDataLogModel}>
                <DataLogModel setOpen={closeDataLogModel} dataLogModal={dataLogModal} profile={profile} />
            </ModalComponent>
        </div>
    );
};

export default Events;
