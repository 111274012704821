// Standard library imports
import { useContext } from 'react';
// External library imports
import { Form, Formik } from 'formik';
import { UilPen } from '@iconscout/react-unicons';
import { UilTrash, UilServerAlt } from '@iconscout/react-unicons';
import { toast } from 'react-toastify';

// Internal module imports
import classes from '../../../../styles/AllDevices.module.css';
import Typography from '../../../../components/Typography/Typography';
import { dataInjestValidation } from '../../../../validations/Root/dataInjestValidation';
import { DropdownComponent, Input, ToggleButton } from '../../../../components/Inputs/Input';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import { useEffect, useState } from 'react';
import Table from '../../../../components/Table/Table';
import { dataInjestService } from '../../../../services/dataInjestService';
import { START_LOADER, STOP_LOADER } from '../../../../constants';
import { LoaderContext } from '../../../../context/LoaderContext';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import CodeEditor from '../../../../components/CodeEditor/CodeEditor';
import NewJSONEditor from '../../../../components/JSONEditor/NewJSONEditor';
import { CamelCaseToTextCase, Capitalize } from '../../../../utils/stringHelper';
import InjestClasses from './index.module.css';
import signalClasses from '../index.module.css';
import MQTTCredModal from './modalComponent/MQTTCredModal';
import { AuthContext } from '../../../../context/AuthContext';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import { momentTimeFormater } from '../../../../utils/timeHelper';

const getTypesLabel = (type) => {
    const label = Capitalize(CamelCaseToTextCase(type));
    return label;
};

const DataInjestForm = () => {
    const initialValues = {
        subscriptionTopic: '',
        kafkaTopic: '',
        parserFunction: '',
        targetCollection: '',
        dataSchema: {},
        powerConsumptionMonitoring: false,
        mqttDataCompression: false,
    };
    const { state } = useContext(AuthContext);

    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const [addFormModal, setAddFormModal] = useState(false);
    const [formData, setFormData] = useState(initialValues);
    const [formTableData, setFormTableData] = useState([]);
    const [editDataInjestModal, setEditDataInjestModal] = useState({
        status: false,
        dataInjestId: '',
    });
    const [deleteDataInjestModal, setDeleteDataInjestModal] = useState({
        status: false,
        dataInjestId: '',
    });
    const [modelTypes, setModelTypes] = useState({});
    const [modelTypeOptions, setModelTypeOptions] = useState([]);
    const [MQTTModal, setMQTTModal] = useState(false);
    const [handleApplyChangesModal, setHandleApplyChangesModal] = useState(false);
    const [updateDefaultValueEditor, setUpdateDefaultValueEditor] = useState(false);

    useEffect(() => {
        fetchDataInjest();
        fetchModelTypes();
    }, []);

    const fetchModelTypes = () => {
        dataInjestService.ReadAllModelTyes(
            () => startLoader('getDataInjestModels'),
            handleModelSuccess,
            handleError,
            () => stopLoader('getDataInjestModels')
        );
    };

    const handleModelSuccess = ({ data }) => {
        const types = data?.data?.types;
        if (types) {
            setModelTypes(data?.data?.types);
            const options = Object.keys(types).map((t) => ({
                label: getTypesLabel(t),
                value: t,
            }));
            console.log({ options });
            setModelTypeOptions(options);
        }
    };

    const fetchDataInjest = () => {
        dataInjestService.ReadAll(
            () => startLoader('getDataInjest'),
            handleSuccess,
            handleError,
            () => stopLoader('getDataInjest')
        );
    };

    const handleSuccess = ({ data }) => {
        const processedData = data?.data.map((key, i) => ({
            's.no': i + 1,
            subscriptionTopic: key.subscriptionTopic,
            kafkaTopic: key.kafkaTopic,
            parserFunction: key.parserFunction,
            targetCollection: key.targetCollection,
            lastReported: key.lastReported ? momentTimeFormater(key.lastReported).format('YYYY-MM-DD HH:mm:ss') : '--',
            mqttDataCompressionR: (
                <div className={signalClasses.DataMonitoringContainer}>
                    {!!key.mqttDataCompression ? <div className={signalClasses.AccessNotBlocked}></div> : <div className={signalClasses.AccessBlocked}></div>}
                </div>
            ),
            action: (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CustomTooltip content={'Edit'}>
                        <UilPen
                            size={'1.2vw'}
                            style={{ color: 'var(--color-primary)', marginRight: '0.5vw' }}
                            onClick={() => {
                                setFormData({
                                    subscriptionTopic: key.subscriptionTopic,
                                    kafkaTopic: key.kafkaTopic,
                                    parserFunction: key.parserFunction,
                                    targetCollection: key.targetCollection,
                                    dataSchema: key.dataSchema,
                                    powerConsumptionMonitoring: key.powerConsumptionMonitoring,
                                    mqttDataCompression: key.mqttDataCompression,
                                });
                                setEditDataInjestModal({
                                    status: true,
                                    dataInjestId: key._id,
                                });
                            }}
                        />
                    </CustomTooltip>
                    <CustomTooltip content={'Delete'}>
                        <UilTrash
                            size={'1.2vw'}
                            style={{ color: 'var(--color-primary)' }}
                            onClick={() =>
                                setDeleteDataInjestModal({
                                    status: true,
                                    dataInjestId: key._id,
                                })
                            }
                        />
                    </CustomTooltip>
                </div>
            ),
        }));
        setFormTableData(processedData);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handleSubmit = (values) => {
        const payload = { ...values };
        if (editDataInjestModal.status) {
            dataInjestService.updateDataInjest(
                editDataInjestModal.dataInjestId,
                payload,
                () => startLoader('updateDataInjest'),
                handleEditSuccess,
                handleError,
                () => stopLoader('updateDataInjest')
            );
            return;
        }
        let params = {};
        dataInjestService.createDataInjest(
            payload,
            params,
            () => startLoader('deleteDataInjest'),
            handleCreateSuccess,
            handleError,
            () => stopLoader('deleteDataInjest')
        );
    };

    const handleCreateSuccess = (res) => {
        fetchDataInjest();
        toast.success('data created sucessfully');
        setAddFormModal(false);
        setFormData(initialValues);
    };

    const handleEditSuccess = (res) => {
        fetchDataInjest();
        toast.success('data injest updated sucessfully');
        setEditDataInjestModal({ status: false, dataInjestId: '' });
        setFormData(initialValues);
    };

    const handleDelete = () => {
        if (deleteDataInjestModal.dataInjestId) {
            dataInjestService.deleteDataInjest(
                deleteDataInjestModal.dataInjestId,
                startLoader,
                () => {
                    fetchDataInjest();
                    toast.success('data injest deleted sucessfully');
                },
                handleError,
                stopLoader
            );
        }
    };

    const handleModelTypesChange = (e, setFieldValue) => {
        setFieldValue('dataSchema', modelTypes[e.value]);
    };

    const handleApplyChanges = () => {
        dataInjestService.ApplyChanges(startLoader, handleApplySuccess, handleError, stopLoader);
    };

    const handleApplySuccess = ({ data }) => {
        if (data?.data?.producer?.status) {
            toast.success(data.data.producer.message);
        } else {
            toast.error(data.data.producer.message);
        }

        if (data?.data?.consumer?.status) {
            toast.success(data.data.consumer.message);
        } else {
            toast.error(data.data.consumer.message);
        }
    };

    return (
        <div className={classes.AllDevices}>
            <DeleteModal
                deletefunction={handleApplyChanges}
                opendeleteModal={handleApplyChangesModal}
                setOpenDeleteModal={setHandleApplyChangesModal}
                title={<div style={{ textAlign: 'center' }}>Do you want to apply change to Producer and Consumer?</div>}
                text={'Applying changes might cause discrepancy with the Producer and Consumer service for few seconds.'}
            />
            <ModalComponent isOpen={MQTTModal} setOpen={(status) => setMQTTModal(status)} style={{ overflow: 'initial' }}>
                <MQTTCredModal />
            </ModalComponent>
            <ModalComponent isOpen={addFormModal || editDataInjestModal.status} setOpen={(status) => setAddFormModal(status)} style={{ overflow: 'initial' }}>
                <div className={InjestClasses.ModalWrapper}>
                    <Typography content={addFormModal ? 'Add Data Injest' : 'Edit Data Injest'} />
                    <div className={classes.FormContent}>
                        <Formik initialValues={formData} enableReinitialize validationSchema={dataInjestValidation} onSubmit={(val, { resetForm }) => handleSubmit(val, resetForm)}>
                            {({ errors, touched, values, isValidating, ...props }) => {
                                return (
                                    <Form>
                                        <div>
                                            <div className={classes.InputContainer}>
                                                <div className={classes.FieldControl2}>
                                                    <label for="subscriptionTopic">
                                                        Subscription Topic <span className="required">*</span>
                                                    </label>
                                                    <Input
                                                        name="subscriptionTopic"
                                                        id="subscriptionTopic"
                                                        setFieldValue={props.setFieldValue}
                                                        setFieldTouched={props.setFieldTouched}
                                                        getFieldMeta={props.getFieldMeta}
                                                        style={{ marginTop: '0' }}
                                                    />
                                                </div>
                                                <div className={classes.FieldControl2}>
                                                    <label for="kafkaTopic">
                                                        Kafka Topic <span className="required">*</span>
                                                    </label>
                                                    <Input
                                                        name="kafkaTopic"
                                                        id="kafkaTopic"
                                                        setFieldValue={props.setFieldValue}
                                                        setFieldTouched={props.setFieldTouched}
                                                        getFieldMeta={props.getFieldMeta}
                                                        style={{ marginTop: '0' }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={classes.InputContainer}>
                                                <div className={classes.FieldControl2}>
                                                    <label for="targetCollection">
                                                        Target Collection <span className="required">*</span>
                                                    </label>
                                                    <Input
                                                        name="targetCollection"
                                                        id="targetCollection"
                                                        setFieldValue={props.setFieldValue}
                                                        setFieldTouched={props.setFieldTouched}
                                                        getFieldMeta={props.getFieldMeta}
                                                        style={{ marginTop: '0' }}
                                                    />
                                                </div>
                                                <div className={classes.FieldControl2}>
                                                    <label for="predefinedSchema">Predefined Schema</label>
                                                    <DropdownComponent
                                                        name="predefinedSchema"
                                                        label="Predefined Schema"
                                                        options={modelTypeOptions}
                                                        onChange={(e) => {
                                                            setUpdateDefaultValueEditor(true);
                                                            handleModelTypesChange(e, props.setFieldValue);
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className={classes.FieldControl2}>
                                                <ToggleButton label="Power Consumption Monitoring" name="powerConsumptionMonitoring" values={values} />
                                            </div>

                                            <div className={classes.FieldControl2}>
                                                <ToggleButton label="MQTT Data Compression" name="mqttDataCompression" values={values} />
                                            </div>

                                            <div className={classes.FieldControl2}>
                                                <label for="parserFunction">
                                                    Parser Function <span className="required">*</span>
                                                </label>
                                                <CodeEditor
                                                    defaultValue={formData?.parserFunction}
                                                    value={values.parserFunction}
                                                    setFieldValue={props.setFieldValue}
                                                    getFieldMeta={props.getFieldMeta}
                                                    name="parserFunction"
                                                />
                                            </div>

                                            <div className={classes.FieldControl2}>
                                                <label for="dataSchema">
                                                    Data Schema <span className="required">*</span>
                                                </label>
                                                <NewJSONEditor
                                                    name="dataSchema"
                                                    defaultValue={values.dataSchema}
                                                    height="10rem"
                                                    manualDefaultValueUpdate={updateDefaultValueEditor}
                                                    setManualDefaultValueUpdate={setUpdateDefaultValueEditor}
                                                />
                                            </div>
                                            <div className={classes.ButtonContainer}>
                                                <div>
                                                    <button
                                                        className="btn-secondary"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            addFormModal
                                                                ? setAddFormModal(false)
                                                                : setEditDataInjestModal({
                                                                      status: false,
                                                                      dataInjestId: '',
                                                                  });
                                                            setFormData(initialValues);
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                                <div>
                                                    <button className="btn-primary" type="submit">
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </ModalComponent>
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteDataInjestModal.status}
                setOpenDeleteModal={(status) => setDeleteDataInjestModal({ ...deleteDataInjestModal, status })}
            />
            <div className={classes.Header}>
                <div>
                    <Typography content="Data Injest" />
                    <div className={classes.TableCount}>
                        Total Count :<span>{formTableData.length}</span>
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="btn-primary"
                            style={{ marginTop: '0' }}
                            onClick={() => {
                                setAddFormModal(true);
                                setEditDataInjestModal({ status: false, dataInjestId: '' });
                            }}
                        >
                            Add
                        </button>
                    </div>
                </div>
                <div className={classes.FlexAlignCenter} style={{ gap: '0.5vw', alignItems: 'center' }}>
                    <div>
                        <button type="submit" className="btn-primary" style={{ marginTop: '0' }} onClick={() => setHandleApplyChangesModal(true)}>
                            Apply Changes
                        </button>
                    </div>
                    {state?.user?.userRole == 'root' && (
                        <UilServerAlt
                            size={'1.2vw'}
                            style={{ color: 'var(--color-primary)' }}
                            onClick={() => {
                                setMQTTModal(true);
                            }}
                        />
                    )}
                </div>
            </div>
            <div>
                <Table
                    head={['S.No', 'Subscription Topic', 'MQTT Data Compression', 'Kafka Topic', 'Target Collection', 'Last Reported (CET)', 'Action']}
                    keys={['s.no', 'subscriptionTopic', 'mqttDataCompressionR', 'kafkaTopic', 'targetCollection', 'lastReported', 'action']}
                    data={formTableData}
                />
            </div>
        </div>
    );
};

export default DataInjestForm;
