// External library imports
import * as yup from 'yup';
import _ from 'lodash';
// Internal library imports
import { ERRORS } from '../../utils/validationHelper';
import { AFRR_MARKET_TYPES } from '../../constants';

const approvedValidityShape = (market = {}) => {
    const shape = {};
    for (let m in market) {
        shape[m] = yup.object().shape({
            startDate: yup.string().test('start date', 'Start date should be less than end date', (value) => {
                const endDate = _.get(market, [m, 'endDate']);
                if (endDate && new Date(endDate) < new Date(value)) {
                    return false;
                } else {
                    return true;
                }
            }),
            endDate: yup.string().test('end date', 'End date should be greater than start date', (value) => {
                const startDate = _.get(market, [m, 'startDate']);
                if (startDate && new Date(startDate) > new Date(value)) {
                    return false;
                } else {
                    return true;
                }
            }),
        });
    }

    return yup.object().shape(shape);
};

export const instanceFormValidation = (defaultMetaKeys) => {
    const metaValidationObject = {};
    _.keys(defaultMetaKeys).forEach((item) => {
        metaValidationObject[item] = yup.string().required();
    });

    return yup.object().shape({
        name: yup.string().required(ERRORS.required),
        description: yup.string().optional(),
        licenseId: yup.string().required(ERRORS.required),
        market: yup.array().min(1, "This field can't be blank.").required("This field can't be blank."),
        metaErrorKeys: yup.object().shape(metaValidationObject),
        meta: yup.mixed(),
        inMarket: yup.boolean().required(),
        inLedger: yup.boolean().required(),
        approvedCapacity: yup.mixed().optional(),
        webshopUrl: yup.string().optional(),
        jiraLink: yup.string().optional(),
        address: yup.object().shape({
            floor: yup.string().optional().nullable(),
            streetName: yup.string().optional().nullable(),
            city: yup.string().optional().nullable(),
            pincode: yup.number().optional().nullable(),
            country: yup.string().optional().nullable(),
        }),
        approvalValidity: yup.lazy((obj) => {
            return approvedValidityShape(obj);
        }),
        inventoryValue: yup.mixed().optional(),
        geoTag: yup.string().when('market', {
            is: (market) => {
                let isRequired = false;
                market.map((m) => {
                    if (AFRR_MARKET_TYPES.includes(m.value)) {
                        isRequired = true;
                    }
                });
                return isRequired;
            },
            then: yup.string().required(),
            otherwise: yup.string().optional(),
        }),
    });
};
