// Standard library imports
import React, { useState, useEffect } from 'react';
// Internal module imports
import TabsComponent from '../../../../components/Tabs/Tabs';
import Dashboard from './Dashboard';
import CommandLogs from './CommandLogs';
import EicDataLogs from './EicDataLogs';
import { RootAfrrService } from '../../../../services/RootAfrrService';
import { useLoader } from '../../../../hooks';
import { toast } from 'react-toastify';
import EICSignalIndex from './EicSignalIndex';

const Afrr = ({ queryParamsData, updateQueryParams }) => {
    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.subTab ? queryParamsData.subTab : 0);
    const [startLoader, stopLoader] = useLoader();
    const [eicCodeOptions, setEicCodeOption] = useState([]);

    useEffect(() => {
        getEicCodes();
    }, []);

    const getEicCodes = () => {
        RootAfrrService.GetEicCodes(startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = ({ data }) => {
        const temp = data.data.map((item) => ({
            label: item.name,
            value: item.EICCode,
        }));
        setEicCodeOption(temp);
        // setSelectedEicCode(queryParamsData.eicCode ? { value: queryParamsData.eicCode } : temp[0]);
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    let tabs = [
        {
            name: 'Signal Logs',
            component: (
                <>
                    <EICSignalIndex queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} eicCodeOptions={eicCodeOptions} />
                </>
            ),
            tabId: 1,
        },
        {
            name: 'Data Logs',
            component: (
                <>
                    <EicDataLogs queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} eicCodeOptions={eicCodeOptions} />
                </>
            ),
            tabId: 1,
        },
        {
            name: 'Redis Viewer',
            component: (
                <>
                    <Dashboard queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} eicCodeOptions={eicCodeOptions} />
                </>
            ),
            tabId: 2,
        },
        {
            name: 'Command Logs',
            component: (
                <>
                    <CommandLogs queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} eicCodeOptions={eicCodeOptions} />
                </>
            ),
            tabId: 3,
        },
    ];

    return (
        <div className=" ventilation">
            <TabsComponent
                selectedIndex={selectedIndex}
                tabs={tabs}
                onChangeTab={(index) => {
                    setSelectedIndex(index);
                    updateQueryParams('subTab', index);
                }}
            />
        </div>
    );
};

export default Afrr;
