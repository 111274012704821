
// External library imports
import _ from 'lodash';

// Internal module imports
import { jwtDecoder } from './jwtHelper';

export const checkPartnerBidAccess = (state, market) => {
    const userRole = _.get(state, 'user.userRole');
    const biddingEnabled = _.get(state, 'user.config.menuItems.bidding');
    const userBiddingMarket = _.get(state, `user.config.biddingMarkets.${market}`, { enabled: false, permissions: { edit: false } });
    const tokenData = jwtDecoder();
    const guest = tokenData?.guest === false;

    if ((userRole === 'admin' || userRole === 'sub-admin') && guest) {
        if (biddingEnabled && userBiddingMarket.enabled && userBiddingMarket.permissions?.edit) {
            return true;
        }
    } else {
        return true;
    }
    return false;
}
export const checkPartnerEditAccess = (state, menu = 'edge') => {
    const userRole = _.get(state, 'user.userRole');
    const userConfigMenu = _.get(state, `user.config.menuItems.${menu}`, { enabled: false, permissions: { edit: false } });
    const tokenData = jwtDecoder();
    const guest = tokenData?.guest === false;

    if ((userRole === 'admin' || userRole === 'sub-admin') && guest) {
        if (userConfigMenu.enabled && userConfigMenu.permissions?.edit) {
            return true;
        }
    } else {
        return true;
    }
    return false;
}