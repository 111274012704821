import classes from './Sidebar.module.css';
import React, { useContext, useState, useRef, useEffect } from 'react';
import { PROTECTED_ROUTES, PROTECTED_ROUTES_ENABLER, PROTECTED_ROUTES_ROOT } from '../../routes/Routes';
import { AuthContext } from '../../context/AuthContext';
import _, { get as lodashGet } from 'lodash';
import SCLogo from '../../assets/new-sc-logo-no-background.svg';
import { UilAngleRight } from '@iconscout/react-unicons';
import { ControlledMenu, MenuItem, useClick } from '@szhsin/react-menu';
import { useHistory } from 'react-router-dom';
import { REMOVE_USER } from '../../constants';
import { useLoader } from '../../hooks';
import { UserService } from '../../services/UserService';
import { toast } from 'react-toastify';
import LogoutIcon from '../../assets/logout1.svg';
import { removeUser, saveRecentApps } from '../../utils/localStorage';
import { jwtDecoder } from '../../utils/jwtHelper';

const Sidebar = ({ active, setActive }) => {
    const { state, dispatch: authDispatch } = useContext(AuthContext);
    const [startLoader, stopLoader] = useLoader();
    const userRole = lodashGet(state, 'user.userRole');
    const accesableRoutes = lodashGet(state, 'user.allowed');
    const logo = lodashGet(state, 'user.properties.logo');
    const userId = lodashGet(state, 'user._id');
    const userConfigMenu = lodashGet(state, 'user.config.menuItems');
    const userBiddingMarkets = lodashGet(state, 'user.config.biddingMarkets');

    const [mouseHover, setMouseHover] = useState(false);
    const [timer, setTimer] = useState(null);
    const history = useHistory();
    const rootUserRoutes = () => {
        return PROTECTED_ROUTES_ROOT;
        // if (userRole === "root") {
        //   return PROTECTED_ROUTES_ROOT;
        // } else if (userRole == "root-admin" || userRole == "root-developer") {
        //   const routes = [];
        //   accesableRoutes?.properties?.[userRole]?.map((item) => {
        //     PROTECTED_ROUTES_ROOT.map((route) => {
        //       if (route.id === item.componentId) {
        //         routes.push(route);
        //       }
        //     });
        //   });
        //   return routes;
        // } else {
        //   return [];
        // }
    };

    const adminUserRoutes = () => {
        const allowedUsers = ['66dad255fd5c02ec8cd9f70d', '66dad2d6fd5c02ec8cd9f7dd', '66dad2fdfd5c02ec8cd9f818'];
        const tokenData = jwtDecoder();
        const guest = tokenData?.guest === false;
        const routes = [];
        if (allowedUsers.includes(userId)) {
            routes.push(...PROTECTED_ROUTES);
        } else if ((userRole === 'admin' || userRole === 'sub-admin') && guest) {
            const allowedRouteId = ['customer'];
            for (const menu in userConfigMenu) {
                if (_.get(userConfigMenu, `${menu}.enabled`)) allowedRouteId.push(menu);
            }
            PROTECTED_ROUTES.forEach((route) => {
                if (allowedRouteId.includes(route.id)) {
                    if (route.id === 'bidding') {
                        const allowedBiddingChildRoute = {};
                        const filteredChildRoutes = [];
                        for (const market in userBiddingMarkets) {
                            if (_.get(userBiddingMarkets, `${market}.enabled`)) {
                                const processedMarket = market === 'DC-PLANNER' ? 'dcplanner' : market.toLowerCase();
                                _.set(allowedBiddingChildRoute, processedMarket, true);
                            }
                        }

                        route.childRoutes.forEach((childRoute) => {
                            if (childRoute.id === 'approvalOverview') {
                                filteredChildRoutes.push(childRoute);
                            } else if (_.has(allowedBiddingChildRoute, childRoute.id.toLowerCase())) {
                                filteredChildRoutes.push(childRoute);
                            }
                        })
                        route.childRoutes = filteredChildRoutes;
                        routes.push(route);
                    } else {
                        routes.push(route);
                    }
                }
            });
        } else {
            routes.push(...PROTECTED_ROUTES);
        }
        return routes;
    };

    let processedFilterRoutes =
        userRole === 'root' || userRole === 'root-admin' || userRole === 'root-developer'
            ? rootUserRoutes()
            : userRole === 'admin' || userRole === 'sub-admin'
                ? adminUserRoutes()
                : userRole === 'enabler'
                    ? PROTECTED_ROUTES_ENABLER
                    : [];

    const handleMouseOver = () => {
        if (!mouseHover && !timer) {
            setTimer(
                setTimeout(() => {
                    setMouseHover(true);
                }, 500)
            );
        }
    };

    const handleMouseOut = () => {
        if (timer) clearTimeout(timer);
        setTimer(null);
        setMouseHover(false);
    };
    const handleClick = (route) => {
        setActive(route.name);
        history.push(route.url);
        route.url !== '/' && saveRecentApps({ ...route, tabIndex: 0 }, state);
    };
    const stopPropagation = (e) => e.stopPropagation();

    const handleLogout = () => {
        removeUser(userId, userRole);
        UserService.logout(
            () => startLoader('logout'),
            handleSuccess,
            handleError,
            () => stopLoader('logout')
        );
    };

    const handleSuccess = ({ data }) => {
        toast.success('Logout Success!');
        authDispatch({ type: REMOVE_USER });
        history.push('/auth/login');
    };

    const handleError = (e) => {
        authDispatch({ type: REMOVE_USER });
        // localStorage.clear();
        history.push('/auth/login');
    };

    const BottomRoutes = [
        {
            name: 'Logout',
            id: 'logout',
            handleClick: handleLogout,
            icon: LogoutIcon,
        },
    ];
    const renderLogo = () => {
        return (
            <div className={classes.ImageWarpper}>
                {logo ? (
                    <>
                        <img src={logo} className={classes.LogoImage} />
                        <p className={classes.PoweredBy}>
                            Powered by <strong>Truegreen</strong>
                        </p>
                    </>
                ) : (
                    <>
                        <img src={SCLogo} className={classes.LogoImage} />
                    </>
                )}
            </div>
        );
    };

    return (
        <div className={classes.Sidebar}>
            <div className={classes.Container} onMouseOver={handleMouseOver} onMouseLeave={handleMouseOut}>
                <div className={classes.Content}>
                    <div onMouseMove={stopPropagation} className={classes.LogoContainer}>
                        {mouseHover ? <> {renderLogo()}</> : <div className={classes.Title}>TG</div>}
                    </div>
                    <div className={mouseHover ? classes.RoutesContainerHovered : classes.RoutesContainer}>
                        <div className={classes.AllRoutes}>
                            {processedFilterRoutes.map((route, key) => (
                                <>
                                    {route.childRoutes ? (
                                        <ControlledMenuForRoutes route={route} active={active} mouseHover={mouseHover} key={key} setActive={setActive} />
                                    ) : (
                                        <RenderableButton route={route} active={active} mouseHover={mouseHover} handleClick={handleClick} key={key} />
                                    )}
                                </>
                            ))}
                        </div>
                        <div className={classes.AllRoutes}>
                            {BottomRoutes.map((route, key) => (
                                <RenderableButton route={route} active={active} mouseHover={mouseHover} handleClick={route.handleClick} key={key} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;

const RenderableButton = ({ route, active, mouseHover, handleClick, key, reference, ...props }) => {
    const ref = useRef();
    return (
        <div key={key} onClick={() => handleClick && handleClick(route)} ref={mouseHover ? reference : ref} {...props} className={classes.RouteParent}>
            <div className={mouseHover ? (active?.includes(route.name) ? classes.RouteWrapperActive : classes.RouteWrapperHovered) : classes.RouteWrapper}>
                {mouseHover && <div></div>}
                <div className={classes.RouteImageWrapper}>
                    <img src={route.icon} alt={route.name} className={classes.RouteIcon} />
                </div>
                {mouseHover && <div>{route.name}</div>}
            </div>
            {route.childRoutes && mouseHover && <UilAngleRight className={classes.ArrowIcon} />}
        </div>
    );
};

const ControlledMenuForRoutes = ({ route, active, mouseHover, key, setActive }) => {
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const anchorProps = useClick(isOpen, setOpen);
    const history = useHistory();
    const { state } = useContext(AuthContext);

    useEffect(() => {
        if (!mouseHover) setOpen(false);
    }, [mouseHover]);

    const handleClick = (subRoute) => {
        setActive(`${route.name} > ${subRoute.name}`);
        history.push(route.url + subRoute.url);
        saveRecentApps(
            {
                ...subRoute,
                url: `${route.url}${subRoute.url}`,
                childRouteName: `${route.name} > ${subRoute.name}`,
                tabIndex: 0,
            },
            state
        );
    };

    return (
        <>
            <RenderableButton key={key} route={route} active={active} mouseHover={mouseHover} reference={ref} {...anchorProps} />
            <ControlledMenu
                state={isOpen ? 'open' : 'closed'}
                anchorRef={ref}
                onClose={() => setOpen(false)}
                direction="right"
                arrow="true"
                position="auto"
                offsetX={15}
                menuClassName={classes.MenuWrapper}
                arrowClassName={classes.MenuWrapperArrow}
            >
                {route.childRoutes.map((subRoute) => {
                    return (
                        <MenuItem onClick={() => handleClick(subRoute)} className={classes.MenuItemClassName}>
                            <img src={subRoute.icon} className={classes.ChildRouteIcon} />
                            <div>{subRoute.name}</div>
                        </MenuItem>
                    );
                })}
            </ControlledMenu>
        </>
    );
};
