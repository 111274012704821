import React, { useEffect, useState } from 'react';
import Typography from '../../../components/Typography/Typography';
import Table from '../../../components/Table/Table';
import { CustomerService } from '../../../services/CustomerService';
import classes from '../../../styles/AllDevices.module.css';
import { useLoader } from '../../../hooks';
import { CONFIG } from '../../../config';
import { toast } from 'react-toastify';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import Users from '../Users';
import E3DashboardConfig from './Config/e3DashboardConfig';
import TabsComponent from '../../../components/Tabs/Tabs';
import SystemConfig from './Config/systemConfig';
import customerClasses from './index.module.css';
import { DropdownComponent } from '../../../components/Inputs/Input';
import SignalTable from './Signals/SignalTable';
import SignalFormModal from './Signals/SignalFormModal';
import {
    UilBill,
    UilEllipsisV,
    UilCreditCardSearch,
    UilApps,
    UilUsersAlt,
    UilTrash,
    UilPen,
    UilSignout,
    UilAnalysis,
    UilExternalLinkAlt,
    UilBellSlash,
    UilServers,
    UilInfoCircle,
    UilDocumentInfo,
} from '@iconscout/react-unicons';
import { MenuItem, Menu, MenuDivider, SubMenu } from '@szhsin/react-menu';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import moment from 'moment';
import { momentTimeFormater } from '../../../utils/timeHelper';
import { SNOOZE_MINUTES } from '../../../constants';
import { SnoozeService } from '../../../services/snoozeService';
import Info from './ModalComponent/Info';
import DocumentEditor from './ModalComponent/DocumentEditor';

function Customer({
    setCustomer,
    setRefresh = null,
    setUser,
    customersData,
    setShowCustomerForm,
    setShowUserForm,
    setShowRevenueSplit,
    setSelectedTab,
    setShowExternalApi,
    setShowInstances,
    setShowLicenses,
    updateQueryParams,
    queryParamsData = {},
}) {
    const [startLoader, stopLoader] = useLoader();
    const [customers, setCustomers] = useState([]);
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        customerId: '',
    });
    const [userModal, setUserModal] = useState({
        status: false,
        customerId: '',
    });
    const [dashboardConfigModal, setDashboardConfigModal] = useState({
        status: false,
        customer: {},
    });
    const [signalModal, setSignalModal] = useState({
        status: false,
        customerId: '',
        customerCode: '',
    });

    const [signalFormModal, setSignalFormModal] = useState({
        status: false,
        type: 'create',
        data: {},
    });
    const [selectedIndex, setSelectedIndex] = useState(0);

    const [infoModal, setInfoModal] = useState({
        status: false,
        customer: {},
    });
    const [openDocumentModal, setOpenDocumentModal] = useState({
        status: false,
        data: {},
    });

    let tabs = [
        {
            name: 'System Config',
            component: (
                <SystemConfig customer={dashboardConfigModal.customer} setRefresh={setRefresh} setOpen={(status) => setDashboardConfigModal({ ...dashboardConfigModal, status })} />
            ),
        },
        ...(dashboardConfigModal.customer && dashboardConfigModal.customer?.trueGreen?.enabled
            ? [
                  {
                      name: 'E3 Dashboard Config',
                      component: (
                          <>
                              <E3DashboardConfig
                                  customer={dashboardConfigModal.customer}
                                  startLoader={startLoader}
                                  stopLoader={stopLoader}
                                  setRefresh={setRefresh}
                                  setOpen={(status) => setDashboardConfigModal({ ...dashboardConfigModal, status })}
                              />
                          </>
                      ),
                  },
              ]
            : []),
    ];

    const loginAsCustomer = (id) => {
        CustomerService.loginAsCustomer(id, startLoader, handleLoginAsCustomerSuccess, handleError, stopLoader);
    };
    const handleLoginAsCustomerSuccess = (res) => {
        const token = res.data.data;
        window.open(`${CONFIG.CLIENT_DASHBOARD_BASE_URI}/login/${token}`, '_blank');
    };

    const handleDelete = () => {
        CustomerService.Delete(deleteModal.customerId, startLoader, handleDeleteSuccess, handleError, stopLoader);
    };
    const handleDeleteSuccess = (res) => {
        if (res) {
            toast.success('Customer deleted');
            setRefresh(Math.random() * 3);
            setDeleteModal({ status: false, customerId: '' });
        }
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    useEffect(() => {
        if (queryParamsData?.customerType && queryParamsData?.customerType.value === true) {
            setCustomersData(customersData);
        } else if (!queryParamsData?.customerType) {
            setCustomersData(customersData);
        }
    }, [JSON.stringify(customersData)]);

    const isSnooze = (data = [], type) => {
        const typeSnoozeStat = data.find((item) => item.type === type);
        if (typeSnoozeStat) {
            const snoozedTime = moment(typeSnoozeStat.timestamp).add(typeSnoozeStat.snoozeForMinutes, 'minutes');
            const isSnoozed = snoozedTime.isAfter(moment().utc());
            if (isSnoozed) {
                return `Snoozed Till (CET): ${momentTimeFormater(snoozedTime).format('YYYY-MM-DD-HH:mm:ss')}`;
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    const handleSnoozeSuccess = (value) => {
        if (value === 'Cancel Snooze') {
            toast.success(`Snooze Canceled.`);
        } else {
            toast.success(`Snooze for ${value}.`);
        }
        setRefresh(Math.random() * 3);
    };

    const snooze = (id, value, type, resource, resourceName) => {
        const payload = {
            resourceId: id,
            type: type,
            snoozeForMinutes: value.value,
            resource,
            resourceName,
        };
        SnoozeService.Create(payload, startLoader, () => handleSnoozeSuccess(value.label), handleError, stopLoader);
    };

    const setCustomersData = (customersDataParam = []) => {
        let newData = customersDataParam?.map((item, index) => ({
            ...item,
            's.no': index + 1,
            name: item?.name,
            contact: item?.address?.contact ? `${item?.address?.countryCode ? item?.address?.countryCode : ''} ${item?.address?.contact}` : '--',
            address: item?.address?.city || '--',
            dataMonitoring: (
                <div className={customerClasses.DataMonitoringContainer}>
                    {item.dataMonitoring ? <div className={customerClasses.AccessNotBlocked}></div> : <div className={customerClasses.AccessBlocked}></div>}
                </div>
            ),
            action: (
                <div
                    style={{
                        display: 'flex',
                        gridColumnGap: '1vw',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div>
                        <CustomTooltip content={'Login As'}>
                            <UilSignout size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => loginAsCustomer(item._id)} />
                        </CustomTooltip>
                    </div>
                    <div>
                        <CustomTooltip content={'Info'}>
                            <UilInfoCircle size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setInfoModal({ status: true, customer: item })} />
                        </CustomTooltip>
                    </div>
                    <div>
                        <CustomTooltip content={'Instances'}>
                            <UilServers
                                size={'1.2vw'}
                                style={{ color: 'var(--color-primary)' }}
                                onClick={() => {
                                    setShowInstances({ status: true, customer: item });
                                    setShowLicenses({ status: false, data: {} });
                                    setShowRevenueSplit({ status: false, data: {} });
                                    setShowUserForm({ status: false, customerId: '' });
                                    setSelectedTab(4);
                                }}
                            />
                        </CustomTooltip>
                    </div>

                    <div>
                        <CustomTooltip content={'Signals'}>
                            <UilAnalysis
                                size={'1.2vw'}
                                style={{ color: 'var(--color-primary)' }}
                                onClick={() =>
                                    setSignalModal({
                                        status: true,
                                        customerId: item._id,
                                        customerCode: item.customerCode,
                                    })
                                }
                            />
                        </CustomTooltip>
                    </div>

                    <Menu
                        menuButton={
                            <div>
                                <CustomTooltip content={'Menu'}>
                                    <UilEllipsisV size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </CustomTooltip>
                            </div>
                        }
                        direction={'left'}
                        arrow={true}
                        menuClassName={customerClasses.Menu}
                    >
                        <MenuItem
                            className={customerClasses.MenuItem}
                            onClick={() => {
                                setCustomer(item);
                                setShowInstances({ status: false, customer: {} });
                                setShowRevenueSplit({ status: false, data: {} });
                                setShowUserForm({ status: false, customerId: '' });
                                setSelectedTab(4);
                            }}
                        >
                            <div className={customerClasses.MenuOptions}>
                                <UilPen size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                <span>Edit</span>
                            </div>
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem className={customerClasses.MenuItem} onClick={() => setUserModal({ status: true, customerId: item._id })}>
                            <div className={customerClasses.MenuOptions}>
                                <UilUsersAlt size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                <span>Users</span>
                            </div>
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem className={customerClasses.MenuItem}>
                            <div className={customerClasses.MenuOptions} onClick={() => setDashboardConfigModal({ status: true, customer: item })}>
                                <UilApps size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                <span>Dashboard Config</span>
                            </div>
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem
                            className={customerClasses.MenuItem}
                            onClick={() => {
                                setShowLicenses({ status: true, data: item });
                                setShowInstances({ status: false, customer: {} });
                                setShowRevenueSplit({ status: false, data: {} });
                                setShowUserForm({ status: false, customerId: '' });
                                setSelectedTab(4);
                            }}
                        >
                            <div className={customerClasses.MenuOptions}>
                                <UilCreditCardSearch size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                <span>Licenses</span>
                            </div>
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem
                            className={customerClasses.MenuItem}
                            onClick={() => {
                                setShowRevenueSplit({ status: true, data: item });
                                setSelectedTab(4);
                            }}
                        >
                            <div className={customerClasses.MenuOptions}>
                                <UilBill size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                <span>Revenue Split</span>
                            </div>
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem
                            className={customerClasses.MenuItem}
                            onClick={() => {
                                setShowExternalApi({ status: true, customerId: item._id });
                                setShowRevenueSplit({ status: false, data: {} });
                                setSelectedTab(4);
                            }}
                        >
                            <div className={customerClasses.MenuOptions}>
                                <div>
                                    <UilExternalLinkAlt size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </div>
                                <span>External API</span>
                            </div>
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem className={customerClasses.MenuItem} onClick={() => setDeleteModal({ status: true, customerId: item._id })}>
                            <div className={customerClasses.MenuOptions}>
                                <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                <span>Delete</span>
                            </div>
                        </MenuItem>
                        <MenuDivider />
                        <li className={customerClasses.SnoozeMenuItem}>
                            <Menu
                                menuButton={
                                    <div className={customerClasses.MenuOptions}>
                                        <UilBellSlash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                        <span>Snooze</span>
                                    </div>
                                }
                                direction={'left'}
                                arrow={true}
                            >
                                <MenuItem>
                                    <div className={customerClasses.SnoozeHeader}>Snooze</div>
                                </MenuItem>
                                <MenuDivider />
                                <SubMenu
                                    label={
                                        <div style={{ textAlign: 'left' }}>
                                            <span style={{ fontSize: '0.8vw', fontWeight: 500 }}>Data Monitoring</span>
                                            <br />
                                            <span style={{ color: '#faa500' }}>
                                                {isSnooze(item?.snoozeData, 'dataMonitoring') ? isSnooze(item?.snoozeData, 'dataMonitoring') : ''}
                                            </span>
                                        </div>
                                    }
                                >
                                    {SNOOZE_MINUTES.map((option, index) => (
                                        <div>
                                            <MenuItem onClick={() => snooze(item._id, option, 'dataMonitoring', 'customer', item.name)}>
                                                <div>{option.label}</div>
                                            </MenuItem>
                                            {index < SNOOZE_MINUTES.length - 1 && <MenuDivider />}
                                        </div>
                                    ))}
                                </SubMenu>
                            </Menu>
                        </li>
                        <MenuDivider />
                        <MenuItem
                            className={customerClasses.MenuItem}
                            onClick={() => {
                                setOpenDocumentModal({ status: true, data: item });
                            }}
                        >
                            <div className={customerClasses.MenuOptions}>
                                <UilDocumentInfo size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                <span>Document</span>
                            </div>
                        </MenuItem>
                    </Menu>
                </div>
            ),
        }));
        setCustomers(newData);
    };

    const getInactiveCustomers = () => {
        CustomerService.ReadAll({ isActive: false }, startLoader, handleFetchSucess, handleError, stopLoader);
    };

    const handleFetchSucess = ({ data }) => {
        setCustomersData(data.data);
    };

    const handleChangeCustomerType = ({ value }) => {
        if (value) {
            setCustomersData(customersData);
        } else {
            getInactiveCustomers();
        }
    };

    const modalTabs = [
        {
            name: 'Signals',
            component: (
                <>
                    <SignalTable customerId={signalModal?.customerId} setSignalFormModal={setSignalFormModal} setSelectedIndex={setSelectedIndex} />
                </>
            ),
        },
        ...(signalFormModal.status
            ? [
                  {
                      name: signalFormModal.type === 'create' ? 'Add Signal' : 'Edit Signal',
                      component: (
                          <>
                              <SignalFormModal
                                  customerId={signalModal?.customerId}
                                  customerCode={signalModal?.customerCode}
                                  setSignalModal={setSignalModal}
                                  signalData={signalFormModal}
                                  setSignalFormModal={setSignalFormModal}
                                  setSelectedIndex={setSelectedIndex}
                              />
                          </>
                      ),
                  },
              ]
            : []),
    ];

    const handelModalTabChange = (index) => {
        if (index === 0 && signalFormModal.status) {
            setSelectedIndex(0);
            setSignalFormModal({
                status: false,
                type: 'create',
                data: {},
            });
        }
    };

    return (
        <div className={classes.AllDevices}>
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>
            <ModalComponent isOpen={infoModal.status} setOpen={(status) => setInfoModal({ customer: {}, status })}>
                <div>
                    <Info infoModal={infoModal.customer} setInfoModal={setInfoModal} />
                </div>
            </ModalComponent>
            <ModalComponent isOpen={userModal.status} setOpen={(status) => setUserModal({ ...userModal, status })}>
                <div style={{ width: '40vw' }}>
                    <Users customerId={userModal?.customerId} setUser={setUser} setUserModal={setUserModal} setShowUserForm={setShowUserForm} setSelectedTab={setSelectedTab} />
                </div>
            </ModalComponent>
            <ModalComponent isOpen={dashboardConfigModal.status} setOpen={(status) => setDashboardConfigModal({ ...dashboardConfigModal, status })} style={{ overflow: 'initial' }}>
                <TabsComponent tabs={tabs} />
            </ModalComponent>
            <ModalComponent
                isOpen={signalModal.status}
                setOpen={(e) => {
                    setSelectedIndex(0);
                    setSignalModal({
                        status: e,
                        customerId: signalModal?.customerId ? signalModal?.customerId : '',
                        customerCode: signalModal?.customerCode ? signalModal?.customerCode : '',
                    });
                    setSignalFormModal({
                        status: e,
                        type: 'create',
                        data: {},
                    });
                }}
                style={{ overflow: 'initial' }}
            >
                <div style={{ width: '80vw' }}>
                    <TabsComponent selectedIndex={selectedIndex} tabs={modalTabs} onChangeTab={handelModalTabChange} />
                </div>
            </ModalComponent>
            <ModalComponent isOpen={openDocumentModal.status} setOpen={() => {}}>
                <div style={{ overflow: 'auto' }}>
                    <DocumentEditor customerData={openDocumentModal.data} setOpen={setOpenDocumentModal} />
                </div>
            </ModalComponent>

            <div className={classes.Header}>
                <div>
                    <Typography content="All Customers" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={customers?.length} />
                        </span>
                    </div>
                </div>
                <div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <DropdownComponent
                            defaultValue={queryParamsData.customerType ? queryParamsData.customerType : { value: true, label: 'Active' }}
                            options={[
                                { value: true, label: 'Active' },
                                { value: false, label: 'Inactive' },
                            ]}
                            onChange={(e) => {
                                handleChangeCustomerType(e);
                                updateQueryParams('customerType', JSON.stringify(e));
                            }}
                        />
                        <div className={classes.Button} style={{ paddingBottom: '2.6vh', paddingLeft: '1vw' }}>
                            <button
                                className="btn-primary"
                                style={{
                                    minHeight: '5.4vh',
                                }}
                                onClick={() => {
                                    setShowCustomerForm(true);
                                    setSelectedTab(4);
                                }}
                            >
                                Create Customer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Table
                    head={['S.No', 'Name', 'Contact', 'Address', 'Data Monitoring', 'Action']}
                    keys={['s.no', 'name', 'contact', 'address', 'dataMonitoring', 'action']}
                    data={customers}
                />
            </div>
        </div>
    );
}

export default Customer;
