// Standard Library imports
import React, { useState, useEffect, useContext } from 'react';

// External library imports
import { UilTrash, UilEye } from '@iconscout/react-unicons';
import { toast } from 'react-toastify';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Form, Formik } from 'formik';

// Internal module imports
import classes from '../../../styles/AllDevices.module.css';
import Typography from '../../../components/Typography/Typography';
import Table from '../../../components/Table/Table';
import { useLoader } from '../../../hooks';
import CreateUserModal from './ModalComponent';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import { AccessManagerService } from '../../../services/AccessManagerService';
import ShowSecretKeyModal from './ModalComponent/ShowSecretKeyModal';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import { CustomerService } from '../../../services/CustomerService';
import Dropdown from '../../../components/Inputs/Dropdown';
import { AuthContext } from '../../../context/AuthContext';
import { checkPartnerEditAccess } from '../../../utils/permissionHelper';

const LIMIT = 15;
const AWSAccessManager = ({ updateQueryParams, queryParamsData = {} }) => {
    const { state } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [startLoader, stopLoader] = useLoader();
    const [isOpen, setOpen] = useState(queryParamsData.awsModal ? queryParamsData.awsModal : false);
    const [refresh, setRefresh] = useState(false);
    const [deleteModal, setDeleteModal] = useState({ status: false, id: '' });
    const [showSecretKey, setShowSecretKey] = useState({
        status: false,
        secretKey: '',
    });
    const [selectCustomer, setSelectCustomer] = useState(queryParamsData?.awsCustomer ? queryParamsData.awsCustomer : {});
    const [customers, setCustomers] = useState([]);

    // partner with edit permission 
    const [editAccess, setEditAccess] = useState(true);
    useEffect(() => {
        if (state) {
            setEditAccess(checkPartnerEditAccess(state));
        }
    }, [state]);

    useEffect(() => {
        updateQueryParams('awsModal', isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (selectCustomer?.value) {
            getData();
        }
    }, [skip, selectCustomer]);

    useEffect(() => {
        if (refresh && selectCustomer?.value) {
            getData();
        }
    }, [refresh]);

    useEffect(() => {
        fetchCustomers();
    }, []);

    const fetchCustomers = () => {
        CustomerService.ReadAll({ isActive: true }, startLoader, customerSuccessHandler, handleError, stopLoader);
    };

    const matchQueryParams = (data = [], match = {}) => {
        for (let item of data) {
            if (item.value === match?.value) {
                return true;
            }
        }
        return false;
    };

    const customerSuccessHandler = ({ data }) => {
        let customers = data.data;
        let temp = [];
        customers.forEach((element) => {
            temp.push({
                value: element._id,
                label: element.name,
            });
        });
        setCustomers(temp);
        if (temp.length) {
            const queryMatched = matchQueryParams(temp, queryParamsData.awsCustomer);
            setSelectCustomer(queryMatched ? queryParamsData.awsCustomer : temp[0]);
            !queryMatched && updateQueryParams('awsCustomer', JSON.stringify(temp[0]));
        } else {
            setSelectCustomer({});
        }
    };

    const getData = () => {
        let params = {
            limit: LIMIT,
            skip,
        };
        AccessManagerService.GetCustomerCredentials(selectCustomer?.value, params, startLoader, handleGetDataSuccess, handleError, stopLoader);
    };

    const handleGetDataSuccess = ({ data }) => {
        const processedData = data.data.data.map((item, index) => ({
            's.no': index + 1,
            ...item,
            awsAccessKey: (
                <div>
                    {
                        editAccess ? ( // partner with edit permission only
                            <CustomTooltip content={'Click to copy'}>
                                <CopyToClipboard text={item.awsAccessKey} onCopy={() => toast.success('Copied!')}>
                                    <div>{item.awsAccessKey}</div>
                                </CopyToClipboard>
                            </CustomTooltip>
                        ) : '--'
                    }
                </div>
            ),
            awsSecretKey: (
                <div>
                    {
                        editAccess ? ( // partner with edit permission only
                            <UilEye
                                size={'1.2vw'}
                                style={{ color: 'var(--color-primary)' }}
                                onClick={() =>
                                    setShowSecretKey({
                                        status: true,
                                        secretKey: item.awsSecretKey,
                                    })
                                }
                            />
                        ) : '--'
                    }
                </div>
            ),
            action: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '0.3vw',
                    }}
                >
                    {
                        editAccess ? ( // partner with edit permission only
                            <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setDeleteModal({ status: true, id: item.awsUserName })} />
                        ) : '--'
                    }
                </div>
            ),
        }));
        setData(processedData);
        setTotalCount(data?.data?.count);
    };

    const DeleteUser = () => {
        AccessManagerService.Delete(deleteModal.id, startLoader, handleDeleteUserSuccess, handleError, stopLoader);
    };

    const handleDeleteUserSuccess = () => {
        toast.success('User deleted!');
        getData();
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    return (
        <div className={classes.AllDevices}>
            <ModalComponent isOpen={showSecretKey.status} setOpen={() => setShowSecretKey({ status: false, secretKey: '' })}>
                <div>
                    <ShowSecretKeyModal secretKey={showSecretKey.secretKey} />
                </div>
            </ModalComponent>
            <DeleteModal
                deletefunction={DeleteUser}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
                text={'This will also delete IAM user from AWS'}
            ></DeleteModal>
            <ModalComponent isOpen={isOpen} setOpen={setOpen}>
                <div style={{ width: '15vw' }}>
                    <CreateUserModal setOpen={setOpen} setRefresh={setRefresh} customer={selectCustomer} />
                </div>
            </ModalComponent>

            <div className={classes.Header}>
                <div>
                    <Typography content="AWS access and secret" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={totalCount || 0} />
                        </span>
                    </div>
                    <div>
                        <Formik initialValues={{ customerId: '' }}>
                            {({ errors, touched, values, isValidating, ...props }) => (
                                <Form>
                                    {customers.length > 0 && (
                                        <div style={{ width: '10vw' }}>
                                            <Dropdown
                                                name="customerId"
                                                options={customers}
                                                onChange={(e) => {
                                                    setSelectCustomer(e);
                                                    updateQueryParams('awsCustomer', JSON.stringify(e));
                                                }}
                                                defaultValue={queryParamsData.awsCustomer ? queryParamsData.awsCustomer : selectCustomer}
                                            />
                                        </div>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
                {
                    editAccess && ( // partner with edit permission only
                        <div>
                            <button type="submit" className="btn-primary" style={{ marginTop: '0' }} onClick={() => setOpen(true)}>
                                Create IAM User
                            </button>
                        </div>
                    )
                }
            </div>
            <div>
                <Table
                    head={['S.No', 'AWS User Name', 'Access Key', 'Secret Key', 'Action']}
                    keys={['s.no', 'awsUserName', 'awsAccessKey', 'awsSecretKey', 'action']}
                    data={data}
                    Pagination={totalCount > 0 ? true : false}
                    page={page}
                    limit={LIMIT}
                    handlePageChange={handlePageChange}
                    totalCount={totalCount}
                />
            </div>
        </div>
    );
};

export default AWSAccessManager;
