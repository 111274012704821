import React, { useEffect, useState } from 'react';
import FilterBarClasses from '../../../styles/Filterbar.module.css';
import { DropdownComponent } from '../../../components/Inputs/Input';
import { regulationsService } from '../../../services/regulationsService';
import { useLoader } from '../../../hooks';
import { toast } from 'react-toastify';
import DatePicker from '../../../components/Inputs/DatePicker/DatePicker';
import moment from 'moment';
import classes from '../../../styles/Bids.module.css';
import { BiddingService } from '../../../services/BiddingService';
import Table from '../../../components/Table/Table';
import Typography from '../../../components/Typography/Typography';
import { UilCopy, UilInfoCircle, UilImport, UilBolt, UilTrash, UilCapture, UilSync, UilKeyboardShow, UilBan, UilNotes } from '@iconscout/react-unicons';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import { CreateBidPrimary } from './ModalComponent/CreateBid';
import { CreateD1Bid } from './ModalComponent/CreateD1Bid';
import { ResultId } from './ModalComponent/ResultId';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import { StatusData } from './ModalComponent/Status';
import { BidInfo } from './ModalComponent/BidInfo';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import { momentTimeFormater } from '../../../utils/dateHelper';
import { AdminService } from '../../../services/AdminService';
import DownloadButton from '../../../components/Buttons/DownloadButton';
import ExportBid from './ModalComponent/ExportBid';
import BulkBid from './ModalComponent/BulkBid';
import { MenuItem, Menu, MenuDivider } from '@szhsin/react-menu';
import { PanicModal } from './ModalComponent/Panic';
import _ from 'lodash';
import { OverallInfo } from './ModalComponent/OverallInfo';
import { PostBidToEnerginet } from './ModalComponent/PostBid';
import { BidNotes } from './ModalComponent/BidNotes';
import { AFRR_MARKET_TYPES } from '../../../constants';
import { PostAvailabilityPlan } from './ModalComponent/PostAvailabilityPlan';

const LIMIT = 15;

const filterOptions = [
    { label: 'All Bids', value: 'all' },
    { label: 'No Bids', value: 'noBids' },
];

const getAdminOptions = (admins = []) => {
    const options = [{ label: 'All', value: 'All' }];
    admins.forEach((c) => options.push({ label: c.name, value: c._id }));
    return options;
};

const getCustomerOptions = (customers = []) => {
    const options = [{ label: 'All', value: 'All' }];
    customers.forEach((c) => options.push({ label: c.name, value: c._id }));
    return options;
};

export const MainBidding = ({ queryParamsData, updateQueryParams }) => {
    const [startLoader, stopLoader] = useLoader();
    const [regulations, setRegulations] = useState([]);
    const [selectedRegulation, setSelectedRegulation] = useState({
        value: queryParamsData.regulation,
    });
    const [date, setDate] = useState(moment(queryParamsData.bidDate || undefined).toDate());
    const [admins, setAdmins] = useState([]);
    const [selectedAdmin, setSelectedAdmin] = useState({
        value: queryParamsData.admin,
    });
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState({
        value: queryParamsData.customer,
    });
    const [bidsData, setBidsData] = useState([]);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [createBid, setCreateBid] = useState({ status: false, meta: {} });
    const [createD1Bid, setCreateD1Bid] = useState({ status: false, meta: {} });
    const [resultId, setResultId] = useState({ status: false, meta: {} });
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        bidId: null,
        type: 'primary',
    });
    const [statusData, setStatusData] = useState({ status: false, meta: {} });
    const [infoData, setInfoData] = useState({ status: false, meta: {} });
    const [exportBid, setExportBid] = useState({ status: false });
    const [bulkBidModal, setBulkBidModal] = useState({ status: false });
    const [panicModal, setPanicModal] = useState({
        status: false,
        data: {},
    });

    const [overallInfoModal, setOverallInfoModal] = useState({
        status: false,
        data: {},
    });

    const [postBidModal, setPostBidModal] = useState({
        status: false,
        type: '',
    });

    const [notesModal, setNotesModal] = useState({
        status: false,
        data: {},
    });

    const [postCapacity, setPostCapacityModal] = useState({
        status: false,
    });

    const [selectedFilter, setSelectedFilter] = useState(queryParamsData?.filter ? { value: queryParamsData?.filter } : filterOptions[0]);
    const [tableHeaders, setTableHeaders] = useState([]);
    useEffect(() => {
        fetchRegulations();
        fetchAdmins();
    }, []);

    useEffect(() => {
        if (!selectedAdmin.value) return;
        if (selectedAdmin.value !== 'All') fetchAdminCustomers();
        else {
            const options = getCustomerOptions([]);
            setCustomers(options);
            setSelectedCustomer(options[0]);
        }
    }, [selectedAdmin.value]);

    useEffect(() => {
        if (selectedRegulation.value) {
            if (AFRR_MARKET_TYPES.includes(selectedRegulation.value)) {
                setTableHeaders([
                    'Customer Name',
                    'License',
                    'Instance',
                    'Avg Capacity',
                    'Customer (€)',
                    'Partner (€)',
                    'SC (€)',
                    'Enablers (€)',
                    'Revenue (€)',
                    'Status(Capacity/Energy)',
                    'Action',
                ]);
            } else {
                setTableHeaders([
                    'Customer Name',
                    'License',
                    'Instance',
                    'Avg Capacity',
                    'Customer (€)',
                    'Partner (€)',
                    'SC (€)',
                    'Enablers (€)',
                    'Revenue (€)',
                    'Status(Primary/Secondary)',
                    'Action',
                ]);
            }
            fetchData();
        }
    }, [skip, selectedCustomer.value, selectedRegulation.value, selectedAdmin.value]);

    const fetchAdmins = () => {
        AdminService.ReadAll(
            () => startLoader('get admin'),
            handleFetchSucess,
            handleError,
            () => stopLoader('get admin')
        );
    };

    const handleFetchSucess = ({ data }) => {
        if (Array.isArray(data.data)) {
            const options = getAdminOptions(data.data);
            setAdmins(options);
            if (!selectedAdmin.value) setSelectedAdmin(options[0]);
        }
    };

    const fetchAdminCustomers = () => {
        AdminService.GetMarketParticipatingCustomers(
            selectedAdmin.value,
            () => startLoader('get customer'),
            handleCustomersSuccess,
            handleError,
            () => stopLoader('get customer')
        );
    };

    const handleCustomersSuccess = ({ data }) => {
        if (Array.isArray(data.data)) {
            const options = getCustomerOptions(data.data);
            setCustomers(options);
            if (!selectedCustomer.value) setSelectedCustomer(options[0]);
        }
    };

    const fetchData = () => {
        if (selectedAdmin.value && selectedCustomer.value && selectedRegulation.value) {
            const params = {
                limit: LIMIT,
                skip: skip,
                market: selectedRegulation.value,
                bidDate: moment(date).format('YYYY-MM-DD'),
                adminId: selectedAdmin.value,
                customerId: selectedCustomer.value,
                filter: selectedFilter?.value,
            };

            BiddingService.GetBids(
                params,
                () => startLoader('get bids'),
                handleBidsSuccess,
                handleError,
                () => stopLoader('get bids')
            );
        }
    };

    const handleBidsSuccess = ({ data }) => {
        if (Array.isArray(data.data.data)) {
            const processedData = data.data.data.map((item) => {
                item.action = (
                    <div className={classes.ActionContainer}>
                        {!item.isBidPresent ? (
                            <CustomTooltip content={'Create Bid'}>
                                <div
                                    className={classes.Action}
                                    onClick={() => {
                                        if (item.isAgreement) {
                                            setCreateBid({
                                                status: true,
                                                meta: {
                                                    customerId: item.customerId,
                                                    group: {
                                                        label: item.instance,
                                                        value: item.instanceId,
                                                    },
                                                },
                                            });
                                        } else {
                                            toast.error('No Agreement Found');
                                        }
                                    }}
                                >
                                    <div>
                                        <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    </div>
                                </div>
                            </CustomTooltip>
                        ) : (
                            <>
                                <CustomTooltip content={'View'}>
                                    <div
                                        className={classes.Action}
                                        onClick={() => {
                                            setInfoData({ status: true, meta: { bid: item.bid } });
                                        }}
                                    >
                                        <UilInfoCircle size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    </div>
                                </CustomTooltip>
                                {item.bid?.bidType === 'normal' ? (
                                    <>
                                        <CustomTooltip content={'Download'}>
                                            <div
                                                className={classes.Action}
                                                onClick={() => {
                                                    handleBidDataDownload(item.bid);
                                                }}
                                            >
                                                <UilImport size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                            </div>
                                        </CustomTooltip>
                                        {selectedRegulation.value === 'FCR' && (
                                            <CustomTooltip content={'Bid'}>
                                                <div
                                                    className={classes.Action}
                                                    onClick={() => {
                                                        handlePostFCRBid(item.bid._id);
                                                    }}
                                                >
                                                    <UilBolt size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                                </div>
                                            </CustomTooltip>
                                        )}
                                        {['FCR-D-INC', 'FCR-D-DEC'].includes(selectedRegulation.value) && _.get(item, ['bid', 'secondary', 'isDeleted']) && (
                                            <CustomTooltip content={'Secondary Bid'}>
                                                <div
                                                    className={classes.Action}
                                                    onClick={() => {
                                                        setCreateD1Bid({
                                                            status: true,
                                                            meta: {
                                                                bid: item.bid,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <UilSync size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                                </div>
                                            </CustomTooltip>
                                        )}
                                        <CustomTooltip content={'Fetch Status'}>
                                            <div
                                                className={classes.Action}
                                                onClick={() => {
                                                    handleFetchStatus(item.bid._id);
                                                }}
                                            >
                                                <UilCapture size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                            </div>
                                        </CustomTooltip>
                                        {['FCR-D-INC', 'FCR-D-DEC', 'FFR'].includes(selectedRegulation.value) && (
                                            <CustomTooltip content={'Result ID'}>
                                                <div
                                                    className={classes.Action}
                                                    onClick={() => {
                                                        setResultId({
                                                            status: true,
                                                            meta: {
                                                                bid: item.bid,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <UilKeyboardShow size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                                </div>
                                            </CustomTooltip>
                                        )}

                                        <Menu
                                            menuButton={
                                                <div>
                                                    <CustomTooltip content={'Delete'}>
                                                        <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                                    </CustomTooltip>
                                                </div>
                                            }
                                            direction={'left'}
                                            arrow={true}
                                        >
                                            {_.get(item.bid, ['primary', 'capacity'], []).length ? (
                                                <>
                                                    <MenuItem
                                                        onClick={() => {
                                                            setDeleteModal({ status: true, bidId: item.bid._id, type: 'primary' });
                                                        }}
                                                        disabled={_.get(item.bid, ['primary', 'provider', 'bidStatus'], []).length ? true : false}
                                                    >
                                                        <div>Delete Primary Bid {_.get(item.bid, ['primary', 'provider', 'bidStatus'], []).length ? '(Result Fetched)' : ''}</div>
                                                    </MenuItem>
                                                    {_.get(item.bid, ['secondary', 'capacity'], []).length ? <MenuDivider /> : ''}
                                                </>
                                            ) : (
                                                ''
                                            )}

                                            {_.get(item.bid, ['secondary', 'capacity'], []).length ? (
                                                <MenuItem
                                                    onClick={() => {
                                                        setDeleteModal({ status: true, bidId: item.bid._id, type: 'secondary' });
                                                    }}
                                                    disabled={_.get(item.bid, ['secondary', 'provider', 'bidStatus'], []).length ? true : false}
                                                >
                                                    <div>Delete Secondary Bid {_.get(item.bid, ['secondary', 'provider', 'bidStatus'], []).length ? '(Result Fetched)' : ''}</div>
                                                </MenuItem>
                                            ) : (
                                                ''
                                            )}
                                        </Menu>
                                        {item.bid?.bidStatus?.length ? (
                                            <CustomTooltip content={'Panic'}>
                                                <div
                                                    className={classes.Action}
                                                    onClick={() => {
                                                        setPanicModal({ status: true, data: item.bid });
                                                    }}
                                                >
                                                    <div>
                                                        <UilBan size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                                    </div>
                                                </div>
                                            </CustomTooltip>
                                        ) : (
                                            ''
                                        )}
                                        <CustomTooltip content={'Bid Note'}>
                                            <div
                                                className={classes.Action}
                                                onClick={() => {
                                                    setNotesModal({ status: true, data: item.bid });
                                                }}
                                            >
                                                <div>
                                                    <UilNotes size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                                </div>
                                            </div>
                                        </CustomTooltip>
                                    </>
                                ) : (
                                    ''
                                )}
                            </>
                        )}
                    </div>
                );
                if (!item.isBidPresent) {
                    item.style = { background: '#FFE3E3', color: '#E25657' };
                }
                if (_.get(item, ['bid', 'notes'], []).length) {
                    item.style = { background: '#fef6e5', color: '#faa500' };
                }
                item.status = `${item.primaryStatus}/${item.secondaryStatus}`;

                return item;
            });
            setBidsData(processedData);
        }
        setTotalCount(data.data.totalCount);
    };

    const fetchRegulations = () => {
        regulationsService.getRegulationByBidDate(
            { date: moment(date).format('YYYY-MM-DD') },
            () => startLoader('load regulations'),
            handleRegulationSuccess,
            handleError,
            () => stopLoader('load regulations')
        );
    };

    const handleRegulationSuccess = ({ data }) => {
        if (Array.isArray(data.data) && data.data.length) {
            const processedReguations = data.data.map((r) => ({
                label: r.name,
                value: r.name,
            }));
            setRegulations(processedReguations);
            if (!selectedRegulation.value) setSelectedRegulation(processedReguations[0]);
        }
    };

    const handleDelete = () => {
        let market = selectedRegulation.value;
        if (market === 'FCR-D-DEC') market = 'FCR-D-MINUS';
        if (market === 'FCR-D-INC') market = 'FCR-D';

        BiddingService.Delete(
            deleteModal.bidId,
            { type: deleteModal.type },
            () => startLoader('delete bid'),
            handleDeleteSuccess,
            handleError,
            () => stopLoader('delete bid')
        );
    };

    const handleDeleteSuccess = (res) => {
        fetchData();
        toast.success('Bid Deleted');
    };

    const handleFetchStatus = (id) => {
        BiddingService.FecthBidStatus(
            id,
            () => startLoader('status bid'),
            handleStatusSuccess,
            handleError,
            () => stopLoader('status bid')
        );
    };

    const handleStatusSuccess = ({ data }) => {
        fetchData();
        setStatusData({ status: true, meta: { data: data.data } });
    };

    const handleError = (err) => {
        if (err && !!err?.response?.data?.message) {
            toast.error(err.response.data.message);
        }
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    const handleDownloadHourDataFCRD = (item) => {
        let totalDMinus2Price = 0;
        let totalDMinus1Price = 0;
        let totalPrice = 0;

        const data = Array(24)
            .fill(null)
            .map((i, index) => {
                const dMinus2price = _.get(item, ['primary', 'provider', 'bidStatus', index, 'price']);
                const dMinus1price = _.get(item, ['secondary', 'provider', 'bidStatus', index, 'price']);
                const hourPrice = _.get(item, ['economics', 'hourlyData', index, 'price'], 0);

                if (dMinus2price >= 0) totalDMinus2Price += dMinus2price;
                if (dMinus1price >= 0) totalDMinus1Price += dMinus1price;
                if (hourPrice >= 0) totalPrice += hourPrice;

                return {
                    hour: index,
                    capacityDMinus2: _.get(item, ['primary', 'capacity', index]) || '--',
                    priceDMinus2: _.get(item, ['primary', 'price', index]) || '--',
                    settlementDMinus2: dMinus2price >= 0 ? dMinus2price : '--',
                    capacityDMinus1: _.get(item, ['secondary', 'capacity', index]) || '--',
                    priceDMinus1: _.get(item, ['secondary', 'price', index]) || '--',
                    settlementDMinus1: dMinus1price >= 0 ? dMinus1price : '--',
                    hourcost: hourPrice >= 0 ? hourPrice : '--',
                };
            });

        data.push({
            hour: 'Total',
            capacityDMinus2: '',
            priceDMinus2: '',
            settlementDMinus2: totalDMinus2Price,
            capacityDMinus1: '',
            priceDMinus1: '',
            settlementDMinus1: totalDMinus1Price,
            hourcost: totalPrice,
        });

        DownloadAsExcel(data, `${selectedRegulation.value}_${momentTimeFormater(item?.date).format('YYYY-MM-DD')}`, [
            'Hour',
            'Capacity (MW) (D-2)',
            'Price (€) (D-2)',
            'Settlement (€) (D-2)',
            'Capacity (MW) (D-1)',
            'Pricem (€) (D-1)',
            'Selttlement (€) (D-1)',
            'Settlement (€) ( D-2 & D-1)',
        ]);
    };

    const handleDownloadHourData = (item) => {
        const data = item.capacity.map((i, index) => ({
            hour: index,
            capacity: i,
            price: _.get(item, ['price', index], '--'),
            hourcost: _.get(item, ['bidStatus', index, 'price'], '--'),
        }));
        DownloadAsExcel(data, `${selectedRegulation.value}_${momentTimeFormater(item?.date).format('YYYY-MM-DD')}`, ['Hour', 'Capacity', 'Price', 'Settlement']);
    };

    const handleBidDataDownload = (bid) => {
        if (['FCR-D-INC', 'FCR-D-DEC'].includes(selectedRegulation.value)) {
            handleDownloadHourDataFCRD(bid);
        } else {
            handleDownloadHourData(bid);
        }
    };

    const setCreateBidHandler = () => {
        setCreateBid({ status: false, meta: { customerId: null, group: {} } });
    };

    const setCreateD1BidHandler = () => {
        setCreateD1Bid({ status: false, meta: { bid: {} } });
    };

    const setResultIdHandler = () => {
        setResultId({ status: false, meta: { bid: {} } });
    };

    const setDeleteBidHandler = () => {
        setDeleteModal({ status: false, bidId: null, type: 'primary' });
    };

    const setStatausDataHandler = () => {
        setStatusData({ status: false, meta: { data: [] } });
    };

    const setInfoDataHandler = () => {
        setInfoData({ status: false, meta: { bid: {}, openReverseBid: false } });
    };

    const setExportBidHandler = () => {
        setExportBid({ status: false });
    };

    const handlePostFCRBid = (id) => {
        BiddingService.PostBidFcr(id, startLoader, handlePostFcrSuccess, handleError, stopLoader);
    };

    const handlePostFcrSuccess = () => {
        toast.success('Bids Posted Successfuly');
        fetchData();
    };

    return (
        <div>
            <div className={FilterBarClasses.ParentWrapper}>
                <div className={FilterBarClasses.Left}>
                    <div className={FilterBarClasses.Filter}>
                        <label>Admin</label>
                        <div style={{ width: '10vw' }}>
                            <DropdownComponent
                                defaultValue={selectedAdmin}
                                options={admins}
                                onChange={(e) => {
                                    setSelectedAdmin(e);
                                    updateQueryParams('admin', e.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className={FilterBarClasses.Filter}>
                        <label>Customer</label>
                        <div style={{ width: '10vw' }}>
                            <DropdownComponent
                                defaultValue={selectedCustomer}
                                options={customers}
                                onChange={(e) => {
                                    setSelectedCustomer(e);
                                    updateQueryParams('customer', e.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className={FilterBarClasses.Filter}>
                        <label>Market</label>
                        <div style={{ width: '10vw' }}>
                            <DropdownComponent
                                defaultValue={selectedRegulation}
                                options={regulations}
                                onChange={(e) => {
                                    setSelectedRegulation(e);
                                    updateQueryParams('regulation', e.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className={FilterBarClasses.Filter}>
                        <label>Filter</label>
                        <div style={{ width: '10vw' }}>
                            <DropdownComponent
                                defaultValue={selectedFilter}
                                options={filterOptions}
                                onChange={(e) => {
                                    setSelectedFilter(e);
                                    updateQueryParams('filter', e.value);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={FilterBarClasses.Right}>
                    <div className={FilterBarClasses.Filter}>
                        <label>Date</label>
                        <DatePicker
                            date={date}
                            setDate={(d) => {
                                setDate(d);
                                updateQueryParams('bidDate', d);
                            }}
                            showArrow={true}
                        />
                    </div>
                    <button
                        className="btn-primary"
                        style={{
                            marginTop: '0',
                            width: 'auto',
                        }}
                        onClick={fetchData}
                    >
                        Submit
                    </button>
                    <button
                        className="btn-primary"
                        style={{
                            marginTop: '0',
                            width: 'auto',
                        }}
                        onClick={() => setBulkBidModal({ status: true })}
                    >
                        Create Bulk Bid
                    </button>
                    {['FCR-D-INC', 'FCR-D-DEC'].includes(selectedRegulation.value) && (
                        <DownloadButton
                            size={'medium'}
                            onClick={() => {
                                setExportBid({ status: true });
                            }}
                        />
                    )}
                </div>
            </div>
            <div className={classes.Bids}>
                <div className={classes.Header}>
                    <div>
                        <Typography content="Master Bidding" />
                        <div className={classes.TableCount}>
                            Total Count :
                            <span>
                                <Typography size="14" content={totalCount} />
                            </span>
                        </div>
                    </div>
                    <div style={{ display: 'flex', gap: '0.5vw' }}>
                        <CustomTooltip content={'Overall Capacity'}>
                            <div
                                className={classes.Action}
                                onClick={() => {
                                    setOverallInfoModal({
                                        status: true,
                                        data: {
                                            market: selectedRegulation.value,
                                            bidDate: moment(date).format('YYYY-MM-DD'),
                                            adminId: selectedAdmin.value,
                                            customerId: selectedCustomer.value,
                                        },
                                    });
                                }}
                            >
                                <UilInfoCircle size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                            </div>
                        </CustomTooltip>
                        <div style={{ fontSize: '0.72vw' }}>
                            <CustomTooltip content={'Bid'}>
                                <Menu
                                    menuButton={
                                        <div>
                                            <UilBolt size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                        </div>
                                    }
                                    direction={'left'}
                                    arrow={true}
                                >
                                    <MenuItem
                                        onClick={() => {
                                            setPostBidModal({ status: true, type: 'primary' });
                                        }}
                                    >
                                        <div>Post Primary Bid</div>
                                    </MenuItem>
                                    <MenuDivider />

                                    <MenuItem
                                        onClick={() => {
                                            setPostBidModal({ status: true, type: 'secondary' });
                                        }}
                                    >
                                        <div>Post Secondary Bid</div>
                                    </MenuItem>
                                    <MenuDivider />

                                    <MenuItem
                                        onClick={() => {
                                            setPostCapacityModal({ status: true });
                                        }}
                                    >
                                        <div>Post Availability Plan</div>
                                    </MenuItem>
                                </Menu>
                            </CustomTooltip>
                        </div>
                    </div>
                </div>
                <Table
                    head={tableHeaders}
                    keys={['customerName', 'license', 'instance', 'averageCapacity', 'customer', 'partner', 'sc', 'enablers', 'revenue', 'status', 'action']}
                    data={bidsData}
                    page={page}
                    Pagination={true}
                    limit={LIMIT}
                    handlePageChange={handlePageChange}
                    totalCount={totalCount}
                />
            </div>
            <ModalComponent isOpen={bulkBidModal.status} setOpen={() => {}}>
                <BulkBid adminId={selectedAdmin.value} customerId={selectedCustomer.value} market={selectedRegulation.value} bidDate={date} setBulkBidModal={setBulkBidModal} />
            </ModalComponent>
            <ModalComponent isOpen={createBid.status} setOpen={setCreateBidHandler}>
                <CreateBidPrimary
                    bidDate={date}
                    customerId={createBid.meta.customerId}
                    fetchBids={fetchData}
                    group={createBid.meta.group}
                    market={selectedRegulation.value}
                    setOpen={setCreateBidHandler}
                />
            </ModalComponent>
            <ModalComponent isOpen={createD1Bid.status} setOpen={setCreateD1BidHandler}>
                <CreateD1Bid bid={createD1Bid.meta.bid} fetchBids={fetchData} market={selectedRegulation.value} setOpen={setCreateD1BidHandler} />
            </ModalComponent>
            <ModalComponent isOpen={resultId.status} setOpen={setResultIdHandler}>
                <ResultId bid={resultId.meta.bid} fetchBids={fetchData} market={selectedRegulation.value} setOpen={setResultIdHandler} />
            </ModalComponent>
            <ModalComponent isOpen={statusData.status} setOpen={setStatausDataHandler}>
                <StatusData data={statusData.meta.data} market={selectedRegulation.value} setOpen={setStatausDataHandler} />
            </ModalComponent>
            <ModalComponent isOpen={infoData.status} setOpen={setInfoDataHandler}>
                <BidInfo bid={infoData.meta.bid} fetchBids={fetchData} market={selectedRegulation.value} setOpen={setInfoDataHandler} />
            </ModalComponent>
            <ModalComponent isOpen={exportBid.status} setOpen={setExportBidHandler}>
                <ExportBid setOpen={setExportBidHandler} />
            </ModalComponent>
            <DeleteModal deletefunction={handleDelete} opendeleteModal={deleteModal.status} setOpenDeleteModal={setDeleteBidHandler}></DeleteModal>
            <ModalComponent
                isOpen={panicModal.status}
                setOpen={() => {
                    setPanicModal({ status: false, data: {} });
                }}
            >
                <PanicModal bid={panicModal.data} setOpen={setPanicModal} fetchBids={fetchData} />
            </ModalComponent>
            <ModalComponent
                isOpen={overallInfoModal.status}
                setOpen={() => {
                    setOverallInfoModal({ status: false, data: {} });
                }}
            >
                <OverallInfo params={overallInfoModal.data} setOpen={setOverallInfoModal} />
            </ModalComponent>
            <ModalComponent
                isOpen={postBidModal.status}
                setOpen={() => {
                    setPostBidModal({ status: false, type: '' });
                }}
            >
                <PostBidToEnerginet type={postBidModal.type} bidDate={date} setOpen={setPostBidModal} fetchBids={fetchData} />
            </ModalComponent>

            <ModalComponent
                isOpen={notesModal.status}
                setOpen={() => {
                    setNotesModal({ status: false, data: null });
                }}
            >
                <BidNotes data={notesModal.data} setOpen={setNotesModal} />
            </ModalComponent>

            <ModalComponent
                isOpen={postCapacity.status}
                setOpen={() => {
                    setPostCapacityModal({ status: false });
                }}
            >
                <PostAvailabilityPlan setOpen={setPostCapacityModal} />
            </ModalComponent>
        </div>
    );
};
