// Standard library imports
import React, { useState } from 'react';

// Internal module imports
import TestLogs from './TestLogsTable';
import TabsComponent from '../../../components/Tabs/Tabs';
import Control from './Control';
import ScheduleFCRV1 from './ScheduleFCRV1';
import ScheduleFCRV2 from './ScheduleFCRV2';
import ScheduleFRRV1 from './ScheduleFRRV1';
import ScheduleFRRV2 from './ScheduleFRRV2';

const getEdgeCodeVersion = (v = 'v0.0.0') => {
    const sanitizedVersion = v.replace(/v/gi, '');
    const versionSplit = sanitizedVersion.split('.');

    const major = Number(versionSplit[0] || 0);
    const minor = Number(versionSplit[1] || 0);
    const patch = Number(versionSplit[2] || 0);

    return Number(`${major}${String(minor).padStart(2, '0')}${String(patch).padStart(2, '0')}`);
}

const ScheduleTestIndex = ({ edgeData, scheduleModal, setScheduleModal, setRedirectTo, setTestLogs, setSelectedIndex, updateQueryParams, baseCommands }) => {

    const [edgeVersion] = useState(getEdgeCodeVersion(edgeData.edgeVersion));

    const scheduleTabs = [
        ...(
            edgeData.market?.includes('FCR')
                ? [
                    ...(edgeVersion < 40500 ? [{
                        name: 'FCR Schedule Test (V1)',
                        component: (
                            <ScheduleFCRV1
                                edgeData={edgeData}
                                setScheduleModal={setScheduleModal}
                                setRedirectTo={setRedirectTo}
                                setTestLogs={setTestLogs}
                                setSelectedIndex={setSelectedIndex}
                                updateQueryParams={updateQueryParams}
                                baseCommands={baseCommands}
                            />
                        ),
                    }] : []
                    ),
                    ...(edgeVersion >= 40500 ? [{
                        name: 'FCR Schedule Test (V2)',
                        component: (
                            <ScheduleFCRV2
                                edgeData={edgeData}
                                setScheduleModal={setScheduleModal}
                                setRedirectTo={setRedirectTo}
                                setTestLogs={setTestLogs}
                                setSelectedIndex={setSelectedIndex}
                                updateQueryParams={updateQueryParams}
                                baseCommands={baseCommands}
                            />
                        ),
                    }] : []
                    ),
                ] :
                []),
        ...(
            edgeData.market?.includes('FRR') ? [
                {
                    name: 'aFRR Signal Test',
                    component: (
                        <ScheduleFRRV1
                            edgeData={edgeData}
                            setScheduleModal={setScheduleModal}
                            setRedirectTo={setRedirectTo}
                            setTestLogs={setTestLogs}
                            setSelectedIndex={setSelectedIndex}
                            updateQueryParams={updateQueryParams}
                            baseCommands={baseCommands}
                        />
                    ),
                },
                {
                    name: 'aFRR Schedule Test',
                    component: (
                        <ScheduleFRRV2
                            edgeData={edgeData}
                            setScheduleModal={setScheduleModal}
                            setRedirectTo={setRedirectTo}
                            setTestLogs={setTestLogs}
                            setSelectedIndex={setSelectedIndex}
                            updateQueryParams={updateQueryParams}
                            baseCommands={baseCommands}
                        />
                    )
                },
            ] : []),
    ];

    const tabs = [
        ...scheduleTabs,
        ...(edgeVersion >= 40400 ? [{
            name: 'Equipment Testing',
            component: (
                <>
                    <Control
                        edgeData={edgeData}
                        baseCommands={baseCommands}
                        setScheduleModal={setScheduleModal}
                    />
                </>
            ),
        }] : []),
        {
            name: 'Predefined Test',
            component: (
                <>
                    <TestLogs market={edgeData.market} setScheduleModal={setScheduleModal} />
                </>
            ),
        },
    ];



    return (
        <div>
            <TabsComponent selectedIndex={scheduleModal?.tabIdx ? scheduleModal?.tabIdx : 0} tabs={tabs} />
        </div>
    );
};

export default ScheduleTestIndex;
