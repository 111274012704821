// Standard library imports
import React, { useEffect, useState } from 'react';

// Extenal library imports
import { Form, Formik, FieldArray } from 'formik';
import { toast } from 'react-toastify';
import { UilMinusCircle, UilPlusCircle } from '@iconscout/react-unicons';
import _ from 'lodash';

// Internal module imports
import { EdgeService } from '../../../services/EdgeService';
import { useLoader } from '../../../hooks';
import Dropdown from '../../../components/Inputs/Dropdown';
import { Input } from '../../../components/Inputs/Input';
import { EdgeScheduleTestFCRV2 } from '../../../validations/Edge/ScheduleTestValidator';
import SaveProfileTest from './SaveProfileTest';
import NewJSONEditor from '../../../components/JSONEditor/NewJSONEditor';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import moment from 'moment';
import TimePickerWithFormik from '../../../components/Inputs/TimePicker/TimePickerWithFormik';

// Css imports
import classes from '../../../styles/CreateDevice.module.css';
import edgeClasses from '../Edge.module.css';

const ScheduleFCRV2 = ({ edgeData, setScheduleModal, setRedirectTo, setTestLogs, setSelectedIndex, updateQueryParams, baseCommands }) => {
    const [startLoader, stopLoader] = useLoader();
    const [testOption, setTestOption] = useState([]);
    const [selectedTestOption, setSelectedTestOption] = useState({});
    const [saveTestModal, setSaveTestModal] = useState(false);
    const [jsonEditorMode, setJsonEditorMode] = useState(false);
    const [updateDefaultValueEditor, setUpdateDefaultValueEditor] = useState(false);
    const [stopTest, setStopTest] = useState(false);

    useEffect(() => {
        const params = {
            market: 'FCR_V2',
        };
        EdgeService.GetPredefinedTest(params, startLoader, handleGetPredefinedTestSuccess, handleError, stopLoader);
    }, []);

    const handleGetPredefinedTestSuccess = ({ data }) => {
        const temp = [];
        data?.data.map((item) => {
            temp.push({
                label: item.name,
                value: item.profile,
            });
        });
        setTestOption(temp);
    };

    const handleSubmit = (values) => {
        setStopTest(true);
        let profiles = values.profileTest;

        const payload = {
            edgeId: edgeData.deviceId,
            testDetails: 'FCR schedule test modal',
            profileName: selectedTestOption?.label || '',
            action: 'FCRscheduledTest',
            testProfile: profiles,
            testType: 'FCR_V2'
        };
        EdgeService.SendScheduleTestData(payload, startLoader, handleSendScheduleSuccess, handleError, stopLoader);
    };

    const handleSendScheduleSuccess = ({ data }) => {
        toast.success(data?.data?.status ? data?.data?.status : 'No Response From Edge.');
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    const sendStopCommand = () => {
        const payload = {
            edgeId: edgeData.deviceId,
            testDetails: "FCR stop modal",
            profileName: null,
            testProfile: [{ "timestamp": moment().utc().format() }],
            action: "emergencyStopTest",
            testType: 'FCR_V2'
        };
        EdgeService.SendScheduleTestData(payload, startLoader, handleSendScheduleSuccess, handleError, stopLoader);
    }

    return (
        <div style={{ minWidth: '45vw', maxHeight: '85vh', overflowY: 'scroll' }}>
            <div>
                <Formik
                    enableReinitialize
                    initialValues={{
                        isPower: false,
                        profileTest: baseCommands?.length
                            ? baseCommands
                            : [
                                {
                                    frequency: '',
                                    time: ''
                                },
                            ],
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={EdgeScheduleTestFCRV2}
                >
                    {({ errors, touched, values, isValidating, ...props }) => {
                        return (
                            <Form>
                                <ModalComponent isOpen={saveTestModal} setOpen={setSaveTestModal}>
                                    <SaveProfileTest
                                        profileTest={values.profileTest}
                                        setSaveTestModal={setSaveTestModal}
                                        isPower={values.isPower}
                                        market={'FCR_V2'}
                                    />
                                </ModalComponent>
                                <>
                                    <div className={edgeClasses.ScheduleTestInputContainer} style={{ alignItems: 'center' }}>
                                        <div className={classes.FieldControl}>
                                            <label>Select Predefined Test</label>
                                            <Dropdown
                                                name="test"
                                                options={testOption}
                                                onChange={(e) => {
                                                    props.setFieldValue('profileTest', e?.value);
                                                    setSelectedTestOption(e);
                                                    setUpdateDefaultValueEditor(true);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.FieldControl2} style={{ textAlign: 'center', marginTop: '2vh' }}>
                                        Or
                                    </div>
                                    <div>
                                        {jsonEditorMode ? (
                                            <>
                                                <div style={{ maxHeight: '30rem', overflowY: 'scroll' }}>
                                                    <div>
                                                        <NewJSONEditor
                                                            name="profile"
                                                            defaultValue={values.profileTest}
                                                            manualDefaultValueUpdate={updateDefaultValueEditor}
                                                            setManualDefaultValueUpdate={setUpdateDefaultValueEditor}
                                                            height="30rem"
                                                            onChange={(e) => {
                                                                props.setFieldValue('profileTest', e);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    {_.values(errors.profileTest).map((e, index) => (
                                                        <div>
                                                            {_.keys(e).map((k) => (
                                                                <div className="error-msg">
                                                                    Profile Index {index}.{k} {e[k]}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        ) : (
                                            <FieldArray name="profileTest">
                                                {({ push, remove, form }) => {
                                                    const { profileTest } = form.values;
                                                    return (
                                                        <div style={{ maxHeight: '30rem', overflowY: 'scroll' }}>
                                                            <div>
                                                                {profileTest?.map((item, index) => (
                                                                    <div key={index} className={edgeClasses.ScheduleTestInputContainer}>
                                                                        <>
                                                                            <div key={index + 'Frequency'} className={classes.FieldControl2}>
                                                                                <label>
                                                                                    Frequency
                                                                                    <span className="required">*</span>
                                                                                </label>
                                                                                <Input name={`profileTest[${index}].frequency`} type="number" />
                                                                            </div>

                                                                            <div key={index + 'Time'} className={classes.FieldControl2}>
                                                                                <label>
                                                                                    Time (MM:SS) <span className="required">*</span>
                                                                                </label>

                                                                                <TimePickerWithFormik
                                                                                    name={`profileTest[${index}].time`}
                                                                                    time={item?.time}
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className={classes.addInputActionWrapper}>
                                                                {profileTest.length > 1 && (
                                                                    <span onClick={() => remove(profileTest?.length - 1)}>
                                                                        <UilMinusCircle
                                                                            size={'1.5vw'}
                                                                            style={{
                                                                                color: 'var(--color-primary)',
                                                                                cursor: 'pointer',
                                                                                zIndex: '1',
                                                                            }}
                                                                        />
                                                                    </span>
                                                                )}
                                                                <span
                                                                    onClick={() =>
                                                                        push({
                                                                            frequency: '',
                                                                            seconds: '',
                                                                        })
                                                                    }
                                                                >
                                                                    <UilPlusCircle
                                                                        size={'1.5vw'}
                                                                        style={{
                                                                            color: 'var(--color-primary)',
                                                                            cursor: 'pointer',
                                                                            zIndex: '1',
                                                                        }}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                                }}
                                            </FieldArray>
                                        )}
                                    </div>
                                </>
                                <div className={edgeClasses.HistoryContainer}>
                                    <>
                                        <div className={edgeClasses.HistoryLink} onClick={() => setJsonEditorMode((mode) => !mode)}>
                                            <p>Switch to {jsonEditorMode ? 'Input Editor' : 'JSON Editor'}</p>
                                        </div>
                                        <div
                                            className={edgeClasses.HistoryLink}
                                            onClick={() => {
                                                setTestLogs({
                                                    status: true,
                                                    deviceId: edgeData.deviceId,
                                                    market: edgeData.market,
                                                    edgeVersion: edgeData.edgeVersion,
                                                    testType: 'FCR_V2'
                                                });
                                                updateQueryParams('tab', 3);
                                                updateQueryParams(
                                                    'testLogs',
                                                    JSON.stringify({
                                                        status: true,
                                                        deviceId: edgeData.deviceId,
                                                    })
                                                );
                                                setSelectedIndex(5);
                                                setScheduleModal({ status: false, data: '' });
                                            }}
                                        >
                                            <p>History</p>
                                        </div>
                                    </>
                                    <div className={classes.ButtonContainer}>
                                        <div>
                                            <button type="button" className="btn-secondary" onClick={() => setScheduleModal({ status: false, data: '' })}>
                                                Close
                                            </button>
                                        </div>
                                        <div>
                                            <button type="button" className="btn-primary" onClick={() => setSaveTestModal({ status: true, id: '' })}>
                                                Save Test
                                            </button>
                                        </div>
                                        {stopTest && (
                                            <div>
                                                <button type="button" className="btn-primary" onClick={() => sendStopCommand()}>
                                                    Stop Test
                                                </button>
                                            </div>
                                        )}
                                        <button type="submit" className="btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default ScheduleFCRV2;
