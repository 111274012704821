// Standard library imports
import React, { useEffect, useState } from 'react';
import _ from 'lodash';

// External library imports
import { toast } from 'react-toastify';
import { UilImport } from '@iconscout/react-unicons';

// Internal module imports
import Typography from '../../../components/Typography/Typography';
import Table from '../../../components/Table/Table';
import { useLoader } from '../../../hooks';
import { DropdownComponent as DropDownWithoutFormik } from '../../../components/Inputs/Input';
import { CamelCaseToTextCase } from '../../../utils/stringHelper';
import { InstanceService } from '../../../services/instanceService';

import classes from '../../../styles/AllDevices.module.css';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import instanceClasses from '../Instances/index.module.css';
import { momentTimeFormater } from '../../../utils/timeHelper';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';

const approvalOptions = [
    { label: 'Approved', value: 'approved' },
    { label: 'Unapproved', value: 'unapproved' },
    { label: 'All', value: 'All' },
];

const defaultOption = {
    label: 'All',
    value: 'All',
};

function Inventory({ customersData, updateQueryParams, queryParamsData = {} }) {
    const [startLoader, stopLoader] = useLoader();

    const [customerOptions, setCustomerOptions] = useState([defaultOption]);
    const [selectedCustomer, setSelectedCustomer] = useState(defaultOption);
    const [instances, setInstances] = useState([]);
    const [selectedApproval, setSelectedApproval] = useState(approvalOptions[0]);
    const [totalInventoryValue, setTotalInventoryValue] = useState(0);

    useEffect(() => {
        let temp = [defaultOption];
        customersData.forEach((element) => {
            temp.push({
                value: element._id,
                label: element.name,
            });
        });
        setCustomerOptions(temp);
    }, [JSON.stringify(customersData)]);

    useEffect(() => {
        getAllInstances();
    }, []);

    useEffect(() => {
        getAllInstances();
    }, [selectedApproval.value, selectedCustomer.value]);

    const formatTableData = (item, idx, startDate = '', endDate = '') => {
        return {
            's.no': idx,
            customerName: item.customerId.name,
            name: item?.name,
            instanceId: item._id,
            description: !item?.description ? (
                '--'
            ) : item.description.length > 40 ? (
                <CustomTooltip content={item.description}>{item.description.substring(0, 40) + '...'}</CustomTooltip>
            ) : (
                item.description
            ),
            descriptionFull: item?.description,
            licenseType: CamelCaseToTextCase(item.licenseId.name),
            market: item.market ? item.market : '--',
            monitoring: item?.monitoring,
            active: item?.active,
            inventoryValue: item.inventoryValue || 0,
            approvalStartDate: startDate ? startDate : '--',
            approvalEndDate: endDate ? endDate : '--',
        };
    };

    const handleInstanceSuccess = ({ data }) => {
        const tableData = [];
        let inventoryValue = 0;
        data?.data.forEach((item, idx) => {
            inventoryValue += item.inventoryValue || 0;
            const validity = item.approvalValidity || {};
            const startDate = validity.startDate ? momentTimeFormater(validity.startDate).format('YYYY-MM-DD') : '--';
            const endDate = validity.endDate ? momentTimeFormater(validity.endDate).format('YYYY-MM-DD') : '--';
            tableData.push(formatTableData(item, idx + 1, startDate, endDate));
        });
        setTotalInventoryValue(inventoryValue);
        setInstances(tableData);
    };

    const getAllInstances = () => {
        const params = {
            approval: selectedApproval.value,
        };
        InstanceService.ReadAllInstanceInventory(selectedCustomer.value, params, startLoader, handleInstanceSuccess, handleError, stopLoader);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handleDowload = () => {
        if (instances.length) {
            const data = instances.map((instance) => {
                return {
                    customerName: instance.customerName,
                    name: instance.name,
                    instanceId: instance.instanceId,
                    inventoryValue: instance.inventoryValue,
                    description: instance.descriptionFull,
                    licenseType: instance.licenseType,
                    approvalStartDate: instance.approvalStartDate,
                    approvalEndDate: instance.approvalEndDate,
                    market: instance.market,
                };
            });

            DownloadAsExcel(data, 'Inventory-Management', [
                'Customer Name',
                'Instance Name',
                'Instance Id',
                'Inventory Value',
                'Description',
                'License Type',
                'Approval/Live Date(CET)',
                'Expiry Date(CET)',
                'Market',
            ]);
        }
    };

    return (
        <div className={classes.AllDevices}>
            <div className={classes.Header}>
                <div>
                    <Typography content="Inventory" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={instances?.length || 0} />
                        </span>
                    </div>
                    <div className={classes.TableCount}>
                        Total Inventory Value :
                        <span>
                            <Typography size="14" content={totalInventoryValue || 0} />
                        </span>
                    </div>
                    <div className={classes.FieldControl} style={{ maxWidth: '15vw' }}>
                        <DropDownWithoutFormik name="customer" options={customerOptions} onChange={setSelectedCustomer} defaultValue={selectedCustomer} />
                    </div>
                </div>
                <div className={instanceClasses.SubHeaderRight}>
                    <div className={classes.FieldControl2} style={{ minWidth: '10vw' }}>
                        <DropDownWithoutFormik name="approval" options={approvalOptions} onChange={setSelectedApproval} defaultValue={selectedApproval} />
                    </div>
                    <UilImport
                        size="1.5vw"
                        style={{
                            color: 'var(--color-primary)',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleDowload()}
                    />
                </div>
            </div>
            <div>
                <Table
                    head={[
                        'S.No',
                        'Customer Name',
                        'Instance Name',
                        'Instance Id',
                        'Inventory Value',
                        'Description',
                        'License Type',
                        'Approval/Live Date(CET)',
                        'Expiry Date(CET)',
                        'Market',
                    ]}
                    keys={['s.no', 'customerName', 'name', 'instanceId', 'inventoryValue', 'description', 'licenseType', 'approvalStartDate', 'approvalEndDate', 'market']}
                    data={instances}
                />
            </div>
        </div>
    );
}

export default Inventory;
