// Standard library imports
import React, { useState, useEffect } from 'react';
// External library imports
import { toast } from 'react-toastify';
import { UilInfoCircle, UilListUl, UilPlay } from '@iconscout/react-unicons';
import { Form, Formik } from 'formik';

// Internal module imports
import classes from '../../styles/AllDevices.module.css';
import Typography from '../../components/Typography/Typography';
import Table from '../../components/Table/Table';
import { useLoader } from '../../hooks';
import DatePicker from '../../components/Inputs/DatePicker/DatePicker';
import { EdgeService } from '../../services/EdgeService';
import { momentTimeFormater } from '../../utils/timeHelper';
import ModalComponent from '../../components/ModalComponent/ModalComponent';
import NewJSONEditor from '../../components/JSONEditor/NewJSONEditor';
import RedirectToSignalViwerOrEdgeDashboard from './ModalComponent/Redirect';
import ScheduleTestIndex from './ModalComponent/ScheduleTestIndex';

const LIMIT = 15;

const calcTabIdx = (commands = [], market) => {
    let tabIdx = 0;
    if (commands?.length === 0 || !commands) return tabIdx;

    const FCRBasedMarket = market?.includes('FCR') ? true : false;
    const FRRBasedMarket = market?.includes('FRR') ? true : false;

    let frequency = commands?.filter(command => command.frequency >= 0).length ? true : false;
    let seconds = commands?.filter(command => command.seconds >= 0).length ? true : false;
    let setPoint = commands?.filter(command => command.setPoint >= 0).length ? true : false;
    let time = commands?.filter(command => command.time).length ? true : false;

    if (FCRBasedMarket && FRRBasedMarket) {
        if (frequency && seconds) {
            tabIdx = 0;
        } else if (frequency && time) {
            tabIdx = 1;
        } else if (setPoint && time) {
            tabIdx = 2;
        } else if (setPoint && seconds) {
            tabIdx = 3;
        } else if (setPoint) {
            tabIdx = 4;
        }
    } else if (FCRBasedMarket) {
        if (frequency && seconds) {
            tabIdx = 0;
        } else if (frequency && time) {
            tabIdx = 0;
        } else if (setPoint) {
            tabIdx = 1;
        }
    } else if (FRRBasedMarket) {
        if (setPoint && seconds) {
            tabIdx = 0;
        } else if (setPoint && time) {
            tabIdx = 1;
        } else if (setPoint) {
            tabIdx = 2;
        }
    }
    return tabIdx;
}

const EdgeTestLogs = ({ deviceId, market, edgeData, updateQueryParams, queryParamsData }) => {
    const [data, setData] = useState([]);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [startLoader, stopLoader] = useLoader();
    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : new Date());
    const [infoModal, setInfoModal] = useState({
        status: false,
        data: [],
    });
    const [scheduleModal, setScheduleModal] = useState({
        status: false,
        data: '',
        tabIdx: 0
    });
    const [redirectTo, setRedirectTo] = useState({
        status: false,
        data: {},
    });

    useEffect(() => {
        updateQueryParams('date', date);
        if (deviceId) {
            getData();
        }
    }, [date, skip]);

    const getData = () => {
        const params = {
            date: momentTimeFormater(date).format('YYYY-MM-DD'),
            deviceId: deviceId,
            limit: LIMIT,
            skip,
            testType: edgeData.testType
        };
        EdgeService.TestLogs(params, startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = ({ data }) => {
        const processedData = data.data?.data.map((item) => ({
            type: item?.type || 'Custom',
            timestamp: momentTimeFormater(item.timestamp).format('YYYY-MM-DD HH:mm'),
            response: item.response ? item?.response : '--',
            commandType: item.meta?.action === 'emergencyStopTest' ? 'Emergency Stop' : 'Control',
            info: (
                <div>
                    <UilInfoCircle
                        size={'1.2vw'}
                        style={{ color: 'var(--color-primary)' }}
                        onClick={() => {
                            setInfoModal({
                                data: item.test,
                                status: true,
                            });
                        }}
                    />
                </div>
            ),
            base: (
                <div>
                    <UilListUl
                        size={'1.2vw'}
                        style={{ color: 'var(--color-primary)' }}
                        onClick={() => {
                            setInfoModal({
                                data: item.baseCommands || [],
                                status: true,
                            });
                        }}
                    />
                </div>
            ),
            action: (
                <div>
                    {
                        item.meta?.action !== 'emergencyStopTest' ?
                            (<UilPlay
                                size={'1.2vw'}
                                style={{ color: 'var(--color-primary)' }}
                                onClick={() => {
                                    setScheduleModal({
                                        status: true,
                                        tabIdx: calcTabIdx(item.baseCommands, market),
                                        data: { deviceId: deviceId, market: market, edgeVersion: edgeData.edgeVersion },
                                        baseCommands: item.baseCommands || [],
                                    });
                                }}
                            />) : '--'
                    }
                </div>
            ),
        }));
        setData(processedData);
        setTotalCount(data.data.count);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    return (
        <div>
            <div className={classes.AllDevices}>
                <div className={classes.Header}>
                    <div>
                        <Typography content="Edge Test Logs" />
                        <div className={classes.TableCount}>
                            Total Count :
                            <span>
                                <Typography size="14" content={totalCount || 0} />
                            </span>
                        </div>
                    </div>
                    <div>
                        <div className={classes.FieldControl2}>
                            <label>Date</label>
                            <div>
                                <DatePicker date={date} setDate={setDate} showArrow={true} />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Table
                        head={['Type', 'Timestamp (CET)', 'Response', 'Command Type', 'Command Sent', 'Base Commands', 'Action']}
                        keys={['type', 'timestamp', 'response', 'commandType', 'info', 'base', 'action']}
                        data={data}
                        Pagination={true}
                        page={page}
                        limit={LIMIT}
                        handlePageChange={handlePageChange}
                        totalCount={totalCount}
                    />
                </div>
            </div>

            <ModalComponent isOpen={infoModal.status} setOpen={() => setInfoModal({ data: [], status: false })}>
                <div style={{ width: '30vw' }}>
                    <div style={{ marginBottom: '1vh' }}>
                        <Typography content="Test Profile" />
                    </div>
                    <Formik initialValues={{}}>
                        {({ errors, touched, values, isValidating, ...props }) => {
                            return (
                                <Form>
                                    <NewJSONEditor name="test" defaultValue={infoModal?.data || {}} height="30rem" />
                                </Form>
                            );
                        }}
                    </Formik>
                    <div className={classes.ButtonContainer}>
                        <div>
                            <button type="submit" className="btn-secondary" onClick={() => setInfoModal({ data: {}, status: false })}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </ModalComponent>

            <ModalComponent
                isOpen={redirectTo.status}
                setOpen={() => {
                    setRedirectTo({ status: false, data: {} });
                }}
            >
                <RedirectToSignalViwerOrEdgeDashboard
                    data={redirectTo.data}
                    setRedirectTo={setRedirectTo}
                    redirectToSignalviewer={() => { }}
                    selectedCustomer={() => { }}
                    setDashboard={() => { }}
                    setSelectedIndex={() => { }}
                />
            </ModalComponent>
            <ModalComponent isOpen={scheduleModal.status} setOpen={() => { }}>
                <div>
                    <ScheduleTestIndex
                        edgeData={scheduleModal.data}
                        scheduleModal={scheduleModal}
                        setScheduleModal={setScheduleModal}
                        setRedirectTo={setRedirectTo}
                        setTestLogs={() => { }}
                        setSelectedIndex={() => { }}
                        updateQueryParams={() => { }}
                        baseCommands={scheduleModal.baseCommands}
                    />
                </div>
            </ModalComponent>
        </div>
    );
};

export default EdgeTestLogs;
