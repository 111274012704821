import { HttpClient } from '../utils/httpClient';

const PATH = {
    create: '/admin/iot-device',
    read_all: '/admin/iot-device',
    update: '/admin/iot-device',
    delete: '/admin/iot-device',
    update_credentials: '/admin/iot-device/credentials',
    rotate_credentials: '/admin/iot-device/mqtt-credential',
    update_mqtt_credentials: '/admin/iot-device/mqtt-credentials',
    command: '/admin/iot-device/command',
    edgeError: '/admin/iot-device/error',
    stats: '/admin/iot-device/stats',
    statsExport: '/admin/iot-device/stats-export',
    schedule: '/admin/iot-device/schedule',
    scheduleAfrr: '/admin/iot-device/schedule-afrr',
    scheduleTest: '/admin/iot-device/schedule-test',
    control: '/admin/iot-device/control',
    getPredefinedTest: '/admin/iot-device/predefined-test',
    saveTest: '/admin/iot-device/save-test',
    create_alert: '/admin/iot-device/create-alert',
    get_alerts: '/admin/iot-device/get-alert',
    delete_alert: '/admin/iot-device/delete-alert',
    edgeByCustomerId: '/admin/iot-device/customer',
    updateNotificationStates: '/admin/iot-device/notification',
    edgeByDeviceId: '/admin/iot-device/device',
    getReportingData: '/admin/iot-device/reporting',
    getInstanceData: '/admin/iot-device/instance',
    getFrequencyData: '/admin/iot-device/frequency',
    getResourceStatsData: '/admin/iot-device/stats',
    getBidStatus: '/admin/iot-device/bid-status',
    getBidHourlyPrice: '/admin/iot-device/bid-hourly-price',
    getUploadedFiles: '/admin/iot-device/log-files',
    getEdgeConfigHistory: '/admin/iot-device/edge-config-history',
    getUploadedFileSignedURL: '/admin/iot-device/log-file',
    testLogs: '/admin/iot-device/test-logs',
    edgeProfiles: '/admin/iot-device/edge-profile',
    deletePredefinedTest: '/admin/iot-device/predefined-test',
    uploadFiles: '/admin/iot-device/upload-files',
    getUploadedFilesHistory: '/admin/iot-device/upload-files-history',
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

const ReadAll = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read_all, { params }).then(callback).catch(error).finally(next);
};

const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

const UpdateCredentials = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update_credentials}/${id}`, payload).then(callback).catch(error).finally(next);
};

const RotateCredentials = (id, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.rotate_credentials}/${id}`).then(callback).catch(error).finally(next);
};

const UpdateMqttCredentials = (start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.update_mqtt_credentials}`).then(callback).catch(error).finally(next);
};

const Delete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`).then(callback).catch(error).finally(next);
};

const SendCommand = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.command, payload).then(callback).catch(error).finally(next);
};

const GetEdgeError = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.edgeError, { params }).then(callback).catch(error).finally(next);
};

const GetEdgeStats = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.stats, { params }).then(callback).catch(error).finally(next);
};

const GetEdgeStatsForExport = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.statsExport, { params }).then(callback).catch(error).finally(next);
};

const SendScheduleData = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.schedule, payload).then(callback).catch(error).finally(next);
};

const SendScheduleTestData = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.scheduleTest, payload).then(callback).catch(error).finally(next);
};

const SendControlData = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.control, payload).then(callback).catch(error).finally(next);
};

const SendScheduleAfrrData = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.scheduleAfrr, payload).then(callback).catch(error).finally(next);
};

const GetPredefinedTest = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.getPredefinedTest, { params }).then(callback).catch(error).finally(next);
};

const SaveTest = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.saveTest, payload).then(callback).catch(error).finally(next);
};

const CreateAlert = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create_alert, payload).then(callback).catch(error).finally(next);
};

const GetAlerts = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.get_alerts}/${id}`).then(callback).catch(error).finally(next);
};

const DeleteAlert = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete_alert}/${id}`).then(callback).catch(error).finally(next);
};

const GetEdgeByCustomerId = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.edgeByCustomerId}/${id}`).then(callback).catch(error).finally(next);
};

const UpdateNotificationStates = (payload, start, callback, error, next) => {
    start();
    return HttpClient.put(PATH.updateNotificationStates, payload).then(callback).catch(error).finally(next);
};

const GetEdgeByDeviceId = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.edgeByDeviceId}/${id}`).then(callback).catch(error).finally(next);
};

const GetReportingData = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getReportingData}/${id}`).then(callback).catch(error).finally(next);
};
const GetInstanceGraphData = (id, params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getInstanceData}/${id}`, { params }).then(callback).catch(error).finally(next);
};
const GetFrequencyGraphData = (id, params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getFrequencyData}/${id}`, { params }).then(callback).catch(error).finally(next);
};
const GetResourceStatsData = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getResourceStatsData}/${id}`).then(callback).catch(error).finally(next);
};
const GetBidStatus = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getBidStatus}`, { params }).then(callback).catch(error).finally(next);
};
const GetBidHourlyPrice = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getBidHourlyPrice}`, { params }).then(callback).catch(error).finally(next);
};
const GetUploadedFiles = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getUploadedFiles}`, { params }).then(callback).catch(error).finally(next);
};

const GetEdgeConfigHistory = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getEdgeConfigHistory}/${id}`).then(callback).catch(error).finally(next);
};

const GetUploadedFilesHistory = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getUploadedFilesHistory}/${id}`).then(callback).catch(error).finally(next);
};

const GetUploadedFileSignedURL = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getUploadedFileSignedURL}`, { params }).then(callback).catch(error).finally(next);
};

const TestLogs = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.testLogs}`, { params }).then(callback).catch(error).finally(next);
};

const EdgeProfiles = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.edgeProfiles).then(callback).catch(error).finally(next);
};

const DeletePredefinedTest = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.deletePredefinedTest}/${id}`).then(callback).catch(error).finally(next);
};

const UploadFiles = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.uploadFiles, payload).then(callback).catch(error).finally(next);
};

const RemoveEdgeUpdloadFile = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.uploadFiles}/${id}`).then(callback).catch(error).finally(next);
};

export const EdgeService = {
    Create,
    ReadAll,
    Update,
    Delete,
    UpdateCredentials,
    RotateCredentials,
    UpdateMqttCredentials,
    SendCommand,
    GetEdgeError,
    GetEdgeStats,
    GetEdgeStatsForExport,
    SendScheduleData,
    SendScheduleAfrrData,
    SendScheduleTestData,
    SendControlData,
    GetPredefinedTest,
    SaveTest,
    CreateAlert,
    GetAlerts,
    DeleteAlert,
    GetEdgeByCustomerId,
    UpdateNotificationStates,
    GetEdgeByDeviceId,
    GetReportingData,
    GetInstanceGraphData,
    GetFrequencyGraphData,
    GetResourceStatsData,
    GetBidStatus,
    GetBidHourlyPrice,
    GetUploadedFiles,
    GetUploadedFileSignedURL,
    TestLogs,
    EdgeProfiles,
    DeletePredefinedTest,
    UploadFiles,
    GetEdgeConfigHistory,
    RemoveEdgeUpdloadFile,
    GetUploadedFilesHistory
};
