// External library import
import * as yup from 'yup';

// Internal module imports
import { ERRORS } from '../../utils/validationHelper';

yup.addMethod(yup.number, 'multipleOfTen', function (message) {
    return this.test('multipleOfTen', message, function (value) {
        const { path, createError } = this;
        return (
            value % 10 === 0 ||
            createError({
                path,
                message: message ?? `${value} is not a multiple of 10`,
            })
        );
    });
});

export const EdgeScheduleTest = (field) => {
    return yup.object().shape({
        isPower: yup.boolean().optional(),
        profileTest: yup.array().of(
            yup.object().shape({
                [field]: field === 'setPoint' ? yup.number().min(0, "Setpoint must be a greater than 0").required(ERRORS.required) : yup.number().required(ERRORS.required),
                seconds:
                    field === 'setPoint'
                        ? yup.number().min(1, 'Must be greater than 0').required(ERRORS.required).multipleOfTen('Number must be a multiple of 10')
                        : yup.number().min(1, 'Must be greater than 0').required(ERRORS.required),
                power: yup.number().test('Power', ERRORS.required, (value, c) => {
                    let isPower = c?.from?.[1]?.value?.isPower;
                    if (isPower) {
                        if (value !== undefined) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                }),
            })
        ),
    });
};

export const EdgeScheduleTestFRRV1 = () => {
    return yup.object().shape({
        profileTest: yup.array().of(
            yup.object().shape({
                setPoint: yup.number().min(0, "Setpoint must be a greater than 0").required(ERRORS.required),
                seconds: yup.number().min(1, 'Must be greater than 0').required(ERRORS.required).multipleOfTen('Number must be a multiple of 10')
            })
        ),
    });
};
export const EdgeScheduleTestFCRV2 = () => {
    return yup.object().shape({
        profileTest: yup.array().of(
            yup.object().shape({
                frequency: yup.number().required(ERRORS.required),
                time: yup.string().required(ERRORS.required)
            })
        ),
    });
};

export const EdgeScheduleTestFRRV2 = () => {
    return yup.object().shape({
        profileTest: yup.array().of(
            yup.object().shape({
                setPoint: yup.number().min(0, "Setpoint must be a greater than 0").required(ERRORS.required),
                time: yup.string().required(ERRORS.required)
            })
        ),
    });
};

export const EdgeScheduleTestSave = yup.object().shape({
    testName: yup.string().required(ERRORS.required),
});
