// external library imports
import ExcelJS from 'exceljs';
// Internal module imports
import { DownloadFileFromBuffer } from './downloadHelper';

export const constructEnerginetFrequency = async (data, fileName) => {
    const buffer = data.data;
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(buffer);
    const excelFile = await workbook.xlsx.writeBuffer();
    DownloadFileFromBuffer(excelFile, fileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
};
