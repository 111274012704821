import React, { useEffect, useState } from 'react';
import classes from '../../../styles/Overview.module.css';
import activationClasses from './ActivationProfiler.module.css';
import AddProfile from './AddProfile';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import { useLoader } from '../../../hooks';
import ChartComponent from '../../../components/ApexCharts/Chart';
import moment from 'moment';
import DatePicker from '../../../components/Inputs/DatePicker/DatePicker';
import CustomTimePicker from '../../../components/Inputs/TimePicker/TimePicker';
import { timeOnlyWithms } from '../../../utils/dateHelper';
import { usToEuCurrencyFormat } from '../../../utils/currencyHelper';
import { toast } from 'react-toastify';
import downloadIcon from '../../../assets/download.svg';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import { truncateNumber } from '../../../utils/numberHelper';
import Typography from '../../../components/Typography/Typography';
import { signalsService } from '../../../services/signalsService';
import { ToggleButtonWithState, DropdownComponent } from '../../../components/Inputs/Input';
import { activationProfileService } from '../../../services/ActivationProfileService';
import { getDuration, momentTimeFormater } from '../../../utils/timeHelper';
import { CamelCaseToTextCase } from '../../../utils/stringHelper';
import TabsComponent from '../../../components/Tabs/Tabs';
import Summary from './Summary';
import Events from './Events';
import { flatten } from 'flat';
import { UilImport, UilInfoCircle } from '@iconscout/react-unicons';
import { Menu, MenuItem } from '@szhsin/react-menu';
import IntMeta from './intMeta';
import Power from './Power';

const ActivationProfiler = ({ queryParamsData, updateQueryParams }) => {
    const [editProfile, setEditProfile] = useState({});
    const [profile, setProfile] = useState({});
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [startLoader, stopLoader] = useLoader();
    const [isAutoRefresh, setIsAutorefresh] = useState(false);
    const curCetDateTime = moment().tz('Europe/Berlin');

    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : new Date(curCetDateTime.format()));
    const [endTime, setEndTime] = useState(queryParamsData.endTime ? queryParamsData.endTime : curCetDateTime.format('HH:mm:ss'));
    const [startTime, setStartTime] = useState(queryParamsData.startTime ? queryParamsData.startTime : curCetDateTime.subtract(15, 'minutes').format('HH:mm:ss'));
    const [frequencyAnnotations, setFrequencyAnnotations] = useState(null);
    const [yaxisVisible, setYaxisVisible] = useState({});
    const [isError, setIsError] = useState(false);
    const [submitEvent, setSubmitEvent] = useState({
        summary: 0,
        event: 0,
        power: 0,
    });
    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.subTab ? queryParamsData.subTab : 0);
    const frequencyMarketProperties = {
        fcr: {
            lineMin: 49.98,
            lineMax: 50.02,
            lowVisible: 49.8,
            highVisible: 50.2,
        },
        fcrD: {
            lineMin: 50.1,
            lowVisible: 49.8,
            highVisible: 50.7,
        },
    };
    const [customers, setCustomers] = useState([
        {
            label: 'All',
            value: 'all',
        },
    ]);
    const [selectedCustomer, setSelectedCustomer] = useState(
        queryParamsData.customer
            ? JSON.parse(queryParamsData.customer)
            : {
                  label: 'All',
                  value: 'all',
              }
    );
    const [profiles, setProfiles] = useState([
        {
            label: 'All',
            value: 'all',
        },
    ]);
    const [rawData, setRawData] = useState({});
    const [intMeta, setIntMeta] = useState([]);
    const [isParamsData, setIsParamsData] = useState(queryParamsData?.profile ? true : false);

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);

    useEffect(() => {
        if (selectedCustomer?.value) {
            getProfiles();
        }
    }, [selectedCustomer]);

    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };
    const handleMarketChange = (value) => {
        if (value === 'FCR') {
            setYaxisVisible({
                lowVisible: frequencyMarketProperties.fcr.lowVisible,
                highVisible: frequencyMarketProperties.fcr.highVisible,
            });
            setFrequencyAnnotations({
                yaxis: [
                    {
                        y: frequencyMarketProperties.fcr.lineMin,
                        borderColor: '#219e4d',
                        label: {
                            borderColor: '#219e4d',
                            style: {
                                color: '#fff',
                                background: '#219e4d',
                                fontSize: '0.8vw',
                            },
                            text: String(frequencyMarketProperties.fcr.lineMin),
                        },
                    },
                    {
                        y: frequencyMarketProperties.fcr.lineMax,
                        borderColor: '#775DD0',
                        label: {
                            borderColor: '#775DD0',
                            style: {
                                color: '#fff',
                                background: '#775DD0',
                                fontSize: '0.8vw',
                            },
                            text: String(frequencyMarketProperties.fcr.lineMax),
                        },
                    },
                ],
            });
        } else if (value === 'FCR-D-INC' || value === 'FCR-D') {
            setYaxisVisible({
                lowVisible: frequencyMarketProperties.fcrD.lowVisible,
                highVisible: frequencyMarketProperties.fcrD.highVisible,
            });
            setFrequencyAnnotations({
                yaxis: [
                    {
                        y: frequencyMarketProperties.fcrD.lineMin,
                        borderColor: '#219e4d',
                        label: {
                            borderColor: '#219e4d',
                            style: {
                                color: '#fff',
                                background: '#219e4d',
                                fontSize: '0.8vw',
                            },
                            text: String(frequencyMarketProperties.fcrD.lineMin),
                        },
                    },
                ],
            });
        } else {
            setYaxisVisible({});
            setFrequencyAnnotations(null);
        }
    };
    const getDownloadData = (prefillData) => {
        const params = {
            start: startTime,
            end: endTime,
            date: new Date(moment(date).tz('Europe/Berlin').format()),
            prefillData: prefillData,
            dataDecimation: false,
            edgeId: profile?.value,
        };

        signalsService.getActivationProfile(params, startLoader, handleDownloadSuccess, handleError, stopLoader);
    };

    const handleDownloadSuccess = ({ data }) => {
        handleExcelDownload(data);
    };

    const handleExcelDownload = (data) => {
        const downloadData = data?.data?.downloadable;
        let columnIndex = 0;
        let columnLength = 0;
        const flattenedData = downloadData
            .map((item) => flatten(item, { delimiter: '_' }))
            .map((item, index) => {
                const timestampCET = item.timestampCET;
                delete item.timestampCET;
                const objectLength = Object.keys(item).length;
                if (objectLength > columnLength) {
                    columnLength = objectLength;
                    columnIndex = index;
                }
                return {
                    timestampCET,
                    ...item,
                };
            });

        const columnNames = ['TIMESTAMP (CET)'];
        for (let key in flattenedData[columnIndex]) {
            if (key !== 'timestampCET') {
                columnNames.push(key.toUpperCase());
            }
        }

        DownloadAsExcel(flattenedData, `Frequency - ${profile?.signalName}`, columnNames, columnIndex);
    };
    const addZeroInFrequency = (freq) => {
        let newFrequency = freq;
        for (let step = 0; step < 5; step++) {
            if (newFrequency.length < 5) {
                newFrequency = newFrequency + 0;
            } else {
                break;
            }
        }
        return newFrequency;
    };

    useEffect(() => {
        if (selectedIndex === 3) {
            fetchIntMeta();
        }
    }, [selectedIndex, profile?.instanceId]);

    const fetchIntMeta = () => {
        const params = {
            instanceId: profile.instanceId,
            date: moment(date).format('YYYY-MM-DD'),
            start: startTime,
            end: endTime,
        };
        activationProfileService.getAllIntMeta(
            params,
            () => startLoader('getAllIntMeta'),
            handleIntMetaSuccess,
            handleError,
            () => stopLoader('getAllIntMeta')
        );
    };

    const handleIntMetaSuccess = ({ data }) => {
        if (data?.data) {
            setIntMeta(data.data);
        } else {
            setIntMeta([]);
        }
    };

    useEffect(() => {
        getOptionFilters();
    }, []);

    const getOptionFilters = () => {
        activationProfileService.GetOptionsFilters(startLoader, handleFiltersSuccess, handleError, stopLoader);
    };

    const handleFiltersSuccess = ({ data }) => {
        const customerDropDown = [
            {
                label: 'All',
                value: 'all',
            },
        ];
        if (data?.data) {
            const customers = data.data;
            for (let customer in customers) {
                const curCustomer = customers[customer];
                customerDropDown.push({
                    label: curCustomer.name,
                    value: customer,
                });
            }
            setRawData(customers);
        }
        setCustomers(customerDropDown);
    };

    const handleCustomerUpdate = (e = null) => {
        e && setSelectedCustomer(e);
    };

    const getProfiles = () => {
        activationProfileService.GetSelectedProfile({ customerId: selectedCustomer.value }, startLoader, handleProfileSuccess, handleError, stopLoader);
    };

    const handleProfileSuccess = ({ data }) => {
        const profileData = [];
        data.data.map((item) => {
            if (item.instance?.[0]) {
                profileData.push({
                    label: `${item.instance?.[0]} ${item?.profile?.market}`,
                    value: item._id,
                    signalName: item?.signal?.[0]?.name || '',
                    ...item.profile,
                    frequency: item.frequency,
                });
            }
        });
        setProfiles(profileData);
        setProfile(isParamsData ? JSON.parse(queryParamsData.profile) : profileData[0]);
        setIsParamsData(false);
    };

    let tabs = [
        {
            name: 'Power',
            component: (
                <Power
                    profile={profile}
                    date={date}
                    setDate={setDate}
                    startTime={startTime}
                    endTime={endTime}
                    setStartTime={setStartTime}
                    setEndTime={setEndTime}
                    onSubmit={submitEvent.power}
                    setIsAutorefresh={setIsAutorefresh}
                    isAutoRefresh={isAutoRefresh}
                    setIsError={setIsError}
                />
            ),
            tabId: 2,
        },
        {
            name: 'Summary',
            component: (
                <Summary
                    date={date}
                    edgeId={profile?.value}
                    onSubmit={submitEvent.summary}
                    updateQueryParams={updateQueryParams}
                    setSelectedIndex={setSelectedIndex}
                    setStartTime={setStartTime}
                    setEndTime={setEndTime}
                    market={profile?.market}
                />
            ),
            tabId: 2,
        },
        {
            name: 'Events',
            component: <Events profile={profile} date={date} onSubmit={submitEvent.event} queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />,
            tabId: 2,
        },
        {
            name: 'INT Meta',
            component: <IntMeta intMeta={intMeta} profile={profile} />,
            tabId: 2,
        },
    ];

    return (
        <div>
            <div className={activationClasses.Filter}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className={activationClasses.FilterContainer}>
                        <div>
                            <div className={classes.FieldControl}>
                                <label>Customer</label>
                                <DropdownComponent
                                    name="customerId"
                                    options={customers}
                                    onChange={(e) => {
                                        if (e.value !== selectedCustomer?.value) {
                                            handleCustomerUpdate(e);
                                        }
                                        updateQueryParams('customer', JSON.stringify(e));
                                    }}
                                    defaultValue={selectedCustomer}
                                />
                            </div>
                        </div>
                        <div>
                            <div className={classes.FieldControl}>
                                <label>Edge Profiles</label>
                                <DropdownComponent
                                    name="profileId"
                                    options={profiles}
                                    onChange={(e) => {
                                        if (e.value !== profile?.value) {
                                            setProfile(e);
                                        }
                                        updateQueryParams('profile', encodeURIComponent(JSON.stringify(e)));
                                    }}
                                    defaultValue={profile}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'end' }}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <div style={{ display: 'flex', gap: '0.521vw' }}>
                                <div className={activationClasses.FieldInput}>
                                    <label>Date</label>
                                    <DatePicker date={date} setDate={setDate} showArrow={true} />
                                </div>

                                {[0, 3].includes(selectedIndex) && (
                                    <div className={activationClasses.FieldInput}>
                                        <label>Start Time</label>
                                        <CustomTimePicker
                                            startTime={startTime}
                                            setStartTime={(e) => {
                                                setStartTime(e);
                                                updateQueryParams('startTime', e);
                                            }}
                                            type="start"
                                            style={isError ? { borderColor: 'red' } : {}}
                                            maxDetail={'second'}
                                        />
                                    </div>
                                )}

                                {[0, 3].includes(selectedIndex) && (
                                    <div className={activationClasses.FieldInput}>
                                        <label>End Time</label>
                                        <CustomTimePicker
                                            endTime={endTime}
                                            setEndTime={(e) => {
                                                setEndTime(e);
                                                updateQueryParams('endTime', e);
                                            }}
                                            type="end"
                                            style={isError ? { borderColor: 'red' } : {}}
                                            disabled={isAutoRefresh}
                                            maxDetail={'second'}
                                        />
                                    </div>
                                )}
                            </div>
                            <div style={{ display: 'flex', alignItem: 'baseline' }}>
                                <button
                                    type="submit"
                                    className="btn-primary"
                                    style={{
                                        marginTop: '1.5vw',
                                        width: 'auto',
                                        marginLeft: '0.5vw',
                                    }}
                                    onClick={() => {
                                        selectedIndex === 3 && fetchIntMeta();
                                        if (selectedIndex === 0) {
                                            setSubmitEvent((prev) => ({
                                                ...prev,
                                                power: prev.power + 1,
                                            }));
                                        }
                                        if (selectedIndex === 1) {
                                            setSubmitEvent((prev) => ({
                                                ...prev,
                                                summary: prev.summary + 1,
                                            }));
                                        }
                                        if (selectedIndex === 2) {
                                            setSubmitEvent((prev) => ({
                                                ...prev,
                                                event: prev.event + 1,
                                            }));
                                        }
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalComponent isOpen={open} setOpen={setOpen}>
                <AddProfile setOpen={setOpen} setRefresh={setRefresh} editProfile={editProfile} />
            </ModalComponent>
            <div className={activationClasses.DeviceDashboard}>
                <div className={classes.Overview} style={{ width: '70vw' }}>
                    <div className={classes.Header}>
                        <div>
                            <div style={{ display: 'flex', gap: '0.5vw', alignItems: 'center' }}>
                                <Typography content={profile?.name} />
                            </div>
                        </div>

                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Menu
                                    menuButton={
                                        <div>
                                            <UilImport
                                                size="1.5vw"
                                                style={{
                                                    cursor: 'pointer',
                                                    marginLeft: '0.4vw',
                                                    marginTop: '0.4vw',
                                                }}
                                            />
                                        </div>
                                    }
                                    direction={'bottom'}
                                    arrow={true}
                                    menuClassName={activationClasses.Menu}
                                >
                                    <MenuItem onClick={() => getDownloadData(false)} className={activationClasses.MenuOptions}>
                                        <div>Raw Download</div>
                                    </MenuItem>
                                    <MenuItem onClick={() => getDownloadData(true)} className={activationClasses.MenuOptions}>
                                        <div>Advanced Download</div>
                                    </MenuItem>
                                </Menu>
                            </div>
                        </div>
                    </div>
                    <div className={classes.OverviewBorder}></div>
                    <div>
                        <div className={'ventilation'}>
                            <TabsComponent
                                selectedIndex={selectedIndex}
                                tabs={tabs}
                                onChangeTab={(e) => {
                                    setSelectedIndex(e);
                                    updateQueryParams('subTab', e);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivationProfiler;
