import * as yup from 'yup';
import { ERRORS, phoneRegExp } from '../../utils/validationHelper';

export const createAdmin = (regualtions) => {
    const biddingMarkets = {};
    regualtions.forEach((market) => {
        biddingMarkets[market] = yup.object().shape({
            enabled: yup.boolean().optional(),
            permissions: yup.object().shape({
                edit: yup
                    .boolean()
                    .optional()
                    .test('bidMarket', `${market} bid edit permission can be enabled only when ${market} bidding market is enabled`, (value, context) => {
                        const marketEnabledContext = context.from[1]?.value?.enabled;
                        if (value && !marketEnabledContext) return false;
                        return true;
                    })
            }),
        })
    })

    return yup.object().shape({
        name: yup.string().required(ERRORS.required),
        email: yup.string().email().required(ERRORS.email),
        password: yup.string().required(ERRORS.required),
        countryCode: yup.string().required(ERRORS.required),
        contact: yup.string().matches(phoneRegExp, 'invalid contact number').required(ERRORS.required),
        accessBlocked: yup.boolean().required(ERRORS.required),
        address: yup.object({
            invoiceInfo: yup.array().of(
                yup.object().shape({
                    name: yup.string().optional(),
                    email: yup.string().email().optional(),
                })
            )
        }),
        EICCode: yup.string().optional(),
        config: yup.object({
            biddingMarkets: yup.object().shape({
                ...biddingMarkets
            }),
            menuItems: yup.object().shape({
                edge: yup.object().shape({
                    enabled: yup.boolean().required(),
                    permissions: yup.object().shape({
                        edit: yup
                            .boolean()
                            .required()
                            .test('edge', `Edge sudo permission must be disabled when edge menu is disabled`, (value, context) => {
                                const edgeEnabledContext = context.from[1]?.value?.enabled;
                                if (value && !edgeEnabledContext) return false;
                                return true;
                            })
                    }),
                }),
                bidding: yup.object().shape({
                    enabled: yup.boolean().required(),
                }),
                systemtool: yup.object().shape({
                    enabled: yup.boolean().required(),
                }),
                eConomics: yup.object().shape({
                    enabled: yup.boolean().required(),
                }),
                documents: yup.object().shape({
                    enabled: yup.boolean().required(),
                }),
            }),
        }),
    });
};

export const updateAdmin = (regualtions) => {
    const biddingMarkets = {};
    regualtions.forEach((market) => {
        biddingMarkets[market] = yup.object().shape({
            enabled: yup.boolean().optional(),
            permissions: yup.object().shape({
                edit: yup
                    .boolean()
                    .optional()
                    .test('bidMarket', `${market} bid edit permission can be enabled only when ${market} bidding market is enabled`, (value, context) => {
                        const marketEnabledContext = context.from[1]?.value?.enabled;
                        if (value && !marketEnabledContext) return false;
                        return true;
                    })
            }),
        })
    })

    return yup.object().shape({
        name: yup.string().required(ERRORS.required),
        email: yup.string().email().required(ERRORS.email),
        password: yup.string().optional(),
        countryCode: yup.string().required(ERRORS.required),
        contact: yup.string().matches(phoneRegExp, 'invalid contact number').required(ERRORS.required),
        accessBlocked: yup.boolean().required(ERRORS.required),
        address: yup.object({
            invoiceInfo: yup.array().of(
                yup.object().shape({
                    name: yup.string().optional(),
                    email: yup.string().email().optional(),
                })
            )
        }),
        EICCode: yup.string().optional(),
        config: yup.object({
            biddingMarkets: yup.object().shape({
                ...biddingMarkets
            }),
            menuItems: yup.object().shape({
                edge: yup.object().shape({
                    enabled: yup.boolean().required(),
                    permissions: yup.object().shape({
                        edit: yup
                            .boolean()
                            .required()
                            .test('edge', `Edge sudo permission must be disabled when edge menu is disabled`, (value, context) => {
                                const edgeEnabledContext = context.from[1]?.value?.enabled;
                                if (value && !edgeEnabledContext) return false;
                                return true;
                            })
                    }),
                }),
                bidding: yup.object().shape({
                    enabled: yup.boolean().required(),
                }),
                systemtool: yup.object().shape({
                    enabled: yup.boolean().required(),
                }),
                eConomics: yup.object().shape({
                    enabled: yup.boolean().required(),
                }),
                documents: yup.object().shape({
                    enabled: yup.boolean().required(),
                }),
            }),
        }),
    });
};
